import React, { ReactNode, useEffect } from 'react';
import { Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import imgSrc from '../../logo.png';

interface AuthorisedLayoutProps {
    children: ReactNode;
}

const AuthorisedLayout: React.FC<AuthorisedLayoutProps> = ({ children }) => {
    const auth = useAuth();

    const location = useLocation();

    useEffect(() => {}, [location.pathname]);

    return (
        <>
            <Row className="mb-4">
                <Navbar collapseOnSelect expand={'lg'} className="justify-content-between">
                    <Container>
                        <Navbar.Brand>
                            <img src={imgSrc} width="100" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse>
                            <Nav className="me-auto">
                                <Nav.Link href="/dashboard">
                                    {location.pathname.includes('/dashboard') ? (
                                        <b className="text-primary">Home</b>
                                    ) : (
                                        'Home'
                                    )}
                                </Nav.Link>
                                <Nav.Link href="/members">
                                    {location.pathname.includes('/member') ? (
                                        <b className="text-primary">Members</b>
                                    ) : (
                                        'Members'
                                    )}
                                </Nav.Link>
                                <Nav.Link href="/accreditations">
                                    {location.pathname.includes('/accreditation') ? (
                                        <b className="text-primary">Accreditations</b>
                                    ) : (
                                        'Accreditations'
                                    )}
                                </Nav.Link>
                                <Nav.Link href="/events">
                                    {location.pathname.includes('/event') ? (
                                        <b className="text-primary">Events</b>
                                    ) : (
                                        'Events'
                                    )}
                                </Nav.Link>
                                <Nav.Link href="/segments">
                                    {location.pathname.includes('/segment') ? (
                                        <b className="text-primary">Segments</b>
                                    ) : (
                                        'Segments'
                                    )}
                                </Nav.Link>
                                <Nav.Link href="/broadcasts">
                                    {location.pathname.includes('/broadcast') ? (
                                        <b className="text-primary">Broadcasts</b>
                                    ) : (
                                        'Broadcasts'
                                    )}
                                </Nav.Link>
                            </Nav>
                            <Button variant="secondary" onClick={() => auth.signoutRedirect()}>
                                Sign Out
                            </Button>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Row>
            <Row>
                <Col>{children}</Col>
            </Row>
        </>
    );
};

export default AuthorisedLayout;
