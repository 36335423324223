export let accreditationItemGQL = `
    accreditationId
    accreditationTypeId
`;

export let accreditationGQL = `
    accreditationId
    accreditationTypeId
    accreditationType {
        name
    }
    paymentCompleteDate
    awardedDate
    startDate
    endDate
`;

export let accreditationTypeGQL = `
    accreditationTypeId
    name
    price
    description
    requirementsJson
    active
    supervisor
    sortKey
`;

export const accreditationApplicationItemGQL = `
  accreditationApplicationId
  member {
    name
  }
  updatedDate
  accreditationType {
    name
  }
  applicationStatus
  jsonData
  hasMitigation
`;

export const accreditationApplicationGQL = `
  accreditationApplicationId
  member {
    memberId
    firstname
    lastname
    email
    addresses {
      addressId
      addressLine1
      addressLine2
      town
      city
      country
      postcode
      default
    }
    professions {
        name
        professionId
    }
    qualifications
    telephones {
        number
        telephoneId
    }
  }
  jsonData
  reviews {
      applicationReviewId
      reviewerRole
      reviewStatus
      updatedDate
      reviewer {
          firstname
          lastname
          accreditations {
            startDate
          }
          memberId
      }
      jsonData
  }
  accreditationType {
      accreditationTypeId
      name
      price
      requirementsJson
  }
  updatedDate
  hasMitigation
`