import { useQuery } from '@apollo/client';
import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { GET_REGIONS } from '../../../graphql/member';
import { RegionSelectProps } from './RegionSelectProps';
import { useRegions } from './useRegions';

const RegionSelect: React.FC<RegionSelectProps> = ({ name, value, onChange }) => {
    const { data } = useQuery(GET_REGIONS);
    const { models } = useRegions({ data });

    return models.regions ? (
        <Form.Select
            as="select"
            name={name}
            value={value}
            onChange={(e) =>
                onChange(models.regions?.filter((x) => x.regionId == e.target.value)[0])
            }
            required
        >
            <option value="">Select region</option>
            {models.regions?.map((option, index) => (
                <option key={index} value={option.regionId}>
                    {option.name}
                </option>
            ))}
        </Form.Select>
    ) : (
        <Spinner size="sm" />
    );
};

export default RegionSelect;
