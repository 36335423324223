import { useEffect, useState } from 'react';
import { AccreditationType } from '../../../graphql/__generated__/graphql';

export const useAccreditationTypes = ({ data }: any) => {
    const [accreditationTypes, setAccreditationTypes] = useState<AccreditationType[]>();

    useEffect(() => {
        if (data?.accreditationTypes.nodes && !accreditationTypes) handleLoadAccreditationTypes();
    });

    const handleLoadAccreditationTypes = () => {
        console.log('handling!', data);

        try {
            setAccreditationTypes(
                data.accreditationTypes.nodes.map((x: AccreditationType) => {
                    return {
                        accreditationTypeId: x.accreditationTypeId,
                        name: x.name,
                    };
                }) as AccreditationType[]
            );
        } catch (error) {
            console.error('Error processing accreditation types:', error);
        }
    };

    return {
        models: {
            accreditationTypes,
        },
    };
};
