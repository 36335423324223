import React, { useEffect } from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    Modal,
    Row,
    Spinner,
    Stack,
    Table,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAccreditationApplications } from './useAccreditationApplications';
import { AccreditationApplicationItem } from '../../graphql/__generated__/graphql';
import { formatApplicationStatus, formatToLocalDateTime } from '../../utils/utils';
import { useNotificationContext } from '../../contexts/NotificationContext';

const Applications: React.FC = () => {
    const { models, operations } = useAccreditationApplications();
    const { loading, accreditationApplications, keywords, orderBy, orderByDesc } = models;

    const notificationContext = useNotificationContext();

    const {
        setKeywords,
        setOrderBy,
        setOrderByDesc,
        saveStateToLocalStorage,
        bulkApprove,
        bulkReturn,
    } = operations;
    // const { setExpired, setKeywords, nextPage, previousPage, handleCreateAccreditation } = operations;

    const [applications, setApplications] = React.useState<AccreditationApplicationItem[]>([]);
    const [selectedApplications, setSelectedApplications] = React.useState<
        AccreditationApplicationItem[]
    >([]);

    const [showModalType, setShowModalType] = React.useState<'approve' | 'return' | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && accreditationApplications) setApplications(accreditationApplications);
    }, [loading, accreditationApplications]);

    const renderSortButton = (column: string, label: string) => {
        return (
            <th>
                {label}{' '}
                {orderBy === column ? (
                    orderByDesc ? (
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setOrderByDesc(false)}
                            className="ms-2 p-1 py-0"
                        >
                            <strong>
                                <i className="bi bi-arrow-up"></i>
                            </strong>
                        </Button>
                    ) : (
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setOrderByDesc(true)}
                            className="ms-2 p-1 py-0"
                        >
                            <strong>
                                <i className="bi bi-arrow-down"></i>
                            </strong>
                        </Button>
                    )
                ) : (
                    <Button
                        size="sm"
                        variant="link-primary"
                        onClick={() => {
                            setOrderBy(column);
                            setOrderByDesc(false);
                        }}
                        className="ms-2"
                    >
                        <i style={{ opacity: 0.9 }} className="bi bi-arrow-down-up"></i>
                    </Button>
                )}
            </th>
        );
    };

    const updateSelectedApplications = (item: AccreditationApplicationItem) => {
        if (selectedApplications.includes(item)) {
            return setSelectedApplications((prevState) => prevState.filter((a) => a !== item));
        }

        return setSelectedApplications((prevState) => prevState.concat([item]));
    };

    const confirmBulkAction = () => {
        if (showModalType === 'approve') {
            bulkApprove(selectedApplications)
                .then(() => {
                    notificationContext.success(
                        'Successfully approved ' + selectedApplications.length + ' application(s)'
                    );
                    setSelectedApplications([]);
                    setShowModalType(null);
                })
                .catch((err) => {
                    notificationContext.error('Error while bulk approving: ' + err);
                });
        } else if (showModalType === 'return') {
            bulkReturn(selectedApplications)
                .then(() => {
                    notificationContext.success(
                        'Successfully returned ' +
                            selectedApplications.length +
                            ' application(s) to the applicant(s)'
                    );
                    setSelectedApplications([]);
                    setShowModalType(null);
                })
                .catch((err) => {
                    notificationContext.error('Error while bulk returning to applicant(s): ' + err);
                });
        } else {
            console.error('Not sure how we got here... ');
        }
    };

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>
                <Row>
                    <Col style={{ textAlign: 'left' }}>
                        <h3>Applications</h3>
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col style={{ textAlign: 'left' }}>
                        {selectedApplications.length > 0 &&
                            selectedApplications.length + ' applications selected'}
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            disabled={!selectedApplications.length}
                            variant={'success'}
                            onClick={() => setShowModalType('approve')}
                        >
                            Approve selected
                        </Button>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            disabled={!selectedApplications.length}
                            variant={'danger'}
                            onClick={() => setShowModalType('return')}
                        >
                            Return selected
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Table>
                                <thead>
                                    <tr>
                                        {renderSortButton('memberName', 'Applicant Name')}
                                        {renderSortButton('updatedDate', 'Updated Date')}
                                        {renderSortButton(
                                            'accreditationType',
                                            'Accreditation Type'
                                        )}
                                        {renderSortButton(
                                            'applicationStatus',
                                            'Application Status'
                                        )}
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                <tbody className={'bg-transparent'}>
                                    {!applications.length && (
                                        <tr>
                                            <td colSpan={6}>
                                                <h5>No applications to review</h5>
                                            </td>
                                        </tr>
                                    )}
                                    {applications.map((application) => {
                                        const selected = selectedApplications.includes(application);
                                        return (
                                            <tr
                                                key={application.accreditationApplicationId}
                                                className={selected ? 'bg-success-subtle' : ''}
                                                onClick={(e) =>
                                                    updateSelectedApplications(application)
                                                }
                                            >
                                                <td className={'bg-transparent'}>
                                                    {application.member?.name}
                                                </td>
                                                <td className={'bg-transparent'}>
                                                    {formatToLocalDateTime(application.updatedDate)}
                                                </td>
                                                <td className={'bg-transparent'}>
                                                    {application.accreditationType?.name}
                                                </td>
                                                <td className={'bg-transparent'}>
                                                    {formatApplicationStatus(
                                                        application.applicationStatus
                                                    )}
                                                </td>
                                                <td className={'bg-transparent'}>
                                                    <Button
                                                        size="sm"
                                                        variant="secondary"
                                                        onClick={() => {
                                                            saveStateToLocalStorage();
                                                            navigate(
                                                                '/accreditations/applications/' +
                                                                    application.accreditationApplicationId
                                                            );
                                                        }}
                                                    >
                                                        Review{' '}
                                                        <i className="bi bi-chevron-right"></i>
                                                    </Button>
                                                </td>
                                                <td className={'bg-transparent'}>
                                                    <Form.Check
                                                        name={'flagged'}
                                                        value={
                                                            application.accreditationApplicationId
                                                        }
                                                        checked={selected}
                                                        onChange={() =>
                                                            updateSelectedApplications(application)
                                                        }
                                                        onClick={(e) => e.stopPropagation()}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>
            </CardBody>
            <Modal onHide={() => setShowModalType(null)} show={showModalType !== null} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {showModalType === 'return' ? 'Return' : 'Approve'} all selected
                        applications
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        Confirm {showModalType === 'return' ? 'return' : 'approval'} of all selected
                        applications
                    </h4>
                    {selectedApplications.map((sa) => (
                        <li>
                            {sa?.member?.name} - {sa?.accreditationType?.name}
                        </li>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction={'horizontal'}>
                        <Button variant={'secondary'} onClick={() => setShowModalType(null)}>
                            Cancel
                        </Button>
                        <Button
                            variant={showModalType === 'return' ? 'danger' : 'primary'}
                            onClick={() => confirmBulkAction()}
                        >
                            {showModalType === 'return' ? 'Return all' : 'Approve all'}
                        </Button>
                    </Stack>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};

export default Applications;
