import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Col,
    FormControl,
    Modal,
    Row,
    Spinner,
    Stack,
    Table,
} from 'react-bootstrap';
import CreateAccreditationModal from '../Modals/CreateAccreditationModal';
import React from 'react';
import { useAccreditations } from './useAccreditations';
import { useNavigate } from 'react-router-dom';
import { AccreditationType } from '../../graphql/__generated__/graphql';
import { useNotificationContext } from '../../contexts/NotificationContext';

const Accreditations: React.FC = () => {
    const { models, operations } = useAccreditations();
    const { loading, accreditations, expired, keywords } = models;
    const {
        setKeywords,
        nextPage,
        previousPage,
        handleCreateAccreditation,
        handleDeleteAccreditation,
    } = operations;

    const navigate = useNavigate();
    const notificationContext = useNotificationContext();

    const [deleteAccreditationType, setDeleteAccreditationType] =
        React.useState<AccreditationType | null>(null);

    const confirmDelete = (accreditationTypeId: string) => {
        handleDeleteAccreditation(accreditationTypeId)
            .then(() => {
                setDeleteAccreditationType(null);
                notificationContext.success('Successfully deleted accreditation');
            })
            .catch((errorMessage) => {
                notificationContext.error(errorMessage);
            });
    };

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>
                <Row>
                    <Col style={{ textAlign: 'left' }}>
                        <h3>Accreditations</h3>
                    </Col>
                    <Col xs={'auto'}>
                        <FormControl
                            placeholder={'search...'}
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className="mb-2"
                        />
                    </Col>
                    <Col xs={'auto'}>
                        <CreateAccreditationModal onCreate={handleCreateAccreditation} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Table style={{ textAlign: 'left' }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Active</th>
                                        <th>Priority</th>
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {accreditations?.map((accreditation) => {
                                        return (
                                            <tr key={accreditation.accreditationTypeId}>
                                                <td className="text-left">{accreditation.name}</td>
                                                <td className="text-left">
                                                    {accreditation.active ? 'Y' : 'N'}
                                                </td>
                                                <td className="text-left">
                                                    {accreditation.sortKey}
                                                </td>
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            navigate(
                                                                '/accreditation/' +
                                                                    accreditation.accreditationTypeId
                                                            )
                                                        }
                                                        title={'See accreditation details'}
                                                    >
                                                        Details&nbsp;
                                                        <i className="bi bi-chevron-right" />
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        variant={'outline-danger'}
                                                        onClick={() =>
                                                            setDeleteAccreditationType(
                                                                accreditation
                                                            )
                                                        }
                                                        title={'Delete accreditation type'}
                                                    >
                                                        Delete&nbsp;
                                                        <i className={'bi bi-trash3-fill'} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>

                <ButtonGroup className="m-2">
                    <Button disabled={!previousPage} onClick={previousPage}>
                        Back
                    </Button>
                    <Button disabled={!nextPage} onClick={nextPage}>
                        Next
                    </Button>
                </ButtonGroup>

                <Modal
                    onHide={() => setDeleteAccreditationType(null)}
                    show={deleteAccreditationType !== null}
                >
                    <Modal.Header>
                        Confirm delete accreditation {deleteAccreditationType?.name}
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Confirm deletion of {deleteAccreditationType?.name}?</h4>
                        <p>
                            If there are any active applications for this accreditation type then
                            the request will fail.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Stack direction={'horizontal'}>
                            <Button
                                variant={'outline-primary'}
                                onClick={() => setDeleteAccreditationType(null)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant={'danger'}
                                onClick={() =>
                                    confirmDelete(deleteAccreditationType?.accreditationTypeId)
                                }
                            >
                                Confirm delete
                            </Button>
                        </Stack>
                    </Modal.Footer>
                </Modal>
            </CardBody>
        </Card>
    );
};

export default Accreditations;
