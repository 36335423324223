import { useQuery } from '@apollo/client';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { GET_PROFESSIONALREGISTRATIONS } from '../../../graphql/member';
import MultiSelectList from '../MultiSelectList';
import { ProfessionalRegistrationSelectProps } from './ProfessionalRegistrationSelectProps';
import { useProfessionalRegistrations } from './useProfessionalRegistrations';

const ProfessionalRegistrationSelect: React.FC<ProfessionalRegistrationSelectProps> = (
    props: ProfessionalRegistrationSelectProps
) => {
    const { name, value, onChange } = props;
    const { data, error, loading } = useQuery(GET_PROFESSIONALREGISTRATIONS);
    const { models, operations } = useProfessionalRegistrations({ data });
    const { professionalRegistrations } = models;

    return professionalRegistrations ? (
        <MultiSelectList
            required
            name={name}
            availableOptions={
                professionalRegistrations?.map((professionalRegistration) => {
                    return {
                        id: professionalRegistration.professionalRegistrationId,
                        label: professionalRegistration.name,
                    };
                }) as any
            }
            value={
                value.map((value) => {
                    return { id: value.professionalRegistrationId, label: value.name };
                }) as any
            }
            onChange={(value) =>
                onChange(
                    professionalRegistrations.filter((x) =>
                        value.map((x) => x.id).includes(x.professionalRegistrationId)
                    )
                )
            }
        />
    ) : (
        <Spinner size="sm" />
    );
};

export default ProfessionalRegistrationSelect;
