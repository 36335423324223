import React, { useState } from 'react';
import { Button, ButtonGroup, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { IEditableCPDProps } from './IEditableCPDProps';
import { useEditableCPD } from './useEditableCPD';

export const EditableCPD: React.FC<IEditableCPDProps> = ({ name, value, onUpdate }) => {
    const [hover, setHover] = useState(false);

    const { models, operations } = useEditableCPD({ value });
    const { show, cpd } = models;
    const { setCPD, handleOpen, handleClose } = operations;

    return (
        <>
            <div
                className="mt-2"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => handleOpen()}
                style={{
                    cursor: 'pointer',
                    opacity: hover ? 0.3 : 1,
                }}
                title="click to update document cpd"
                data-testid="member-editablename"
            >
                <span>
                    {value}
                    {hover && (
                        <span style={{ position: 'absolute', marginLeft: '1em' }}>
                            <i className="bi bi-pencil"></i>
                        </span>
                    )}
                </span>
            </div>
            <Modal show={show} onHide={handleClose} data-testid="member-editablename-modal">
                <Modal.Header>
                    <span>
                        Updating CPD for <b>{name}</b>
                    </span>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Row>
                        <Form.Group
                            as={Col}
                            md={9}
                            lg={5}
                            className="mb-3"
                            data-testid="document-cpd"
                        >
                            <FloatingLabel label="CPD">
                                <Form.Control
                                    required
                                    type="number"
                                    name="cpd"
                                    value={cpd}
                                    onChange={(e) => setCPD(parseInt(e.target.value))}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <ButtonGroup>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        {cpd && (
                            <Button
                                variant="primary"
                                onClick={() => onUpdate(cpd).then(() => handleClose())}
                            >
                                Update
                            </Button>
                        )}
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditableCPD;
