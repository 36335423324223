import React, { useState } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import AdminLinkButton from '../AdminLinkButton';

const Dashboard: React.FC = () => {
    const [helper, setHelper] = useState<React.ReactNode>(
        <>
            <i className="bi bi-info-circle me-2"></i>
            <small>To view more details, hover over an administration area above.</small>
        </>
    );

    return (
        <Card bg="light" border="light">
            <Container
                style={{ height: '50vh' }}
                className="d-flex flex-column justify-content-evenly"
            >
                <Row>
                    <Col>
                        <h1>EMDR UK</h1>
                        <h5>Coalesce Administration Dashboard</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Stack direction="horizontal" className="justify-content-center" gap={4}>
                            <AdminLinkButton
                                title="Members"
                                icon={<i className="bi bi-people"></i>}
                                href="/members"
                                onDescribe={(icon: React.ReactNode) => {
                                    setHelper(
                                        <>
                                            {icon}
                                            <small>
                                                {' '}
                                                Member's profile information, including membership,
                                                accreditation, event participation and documents can
                                                be viewed and updated on the member's behalf.
                                            </small>{' '}
                                        </>
                                    );
                                }}
                            />
                            <AdminLinkButton
                                title="Accreditations"
                                icon={<i className="bi bi-trophy"></i>}
                                href="/accreditations"
                                onDescribe={(icon: React.ReactNode) => {
                                    setHelper(
                                        <>
                                            {icon}
                                            <small>
                                                Create and manage the accreditations that members
                                                can apply for through their portal.
                                            </small>{' '}
                                        </>
                                    );
                                }}
                            />
                            <AdminLinkButton
                                title="Events"
                                icon={<i className="bi bi-calendar"></i>}
                                href="/events"
                                onDescribe={(icon: React.ReactNode) => {
                                    setHelper(
                                        <>
                                            {icon}
                                            <small>
                                                View and manage events by adding videos and
                                                certificates to be issued to members who participate
                                                in those events.
                                            </small>{' '}
                                        </>
                                    );
                                }}
                            />
                            <AdminLinkButton
                                title="Segments"
                                icon={<i className="bi bi-person-rolodex"></i>}
                                href="/segments"
                                onDescribe={(icon: React.ReactNode) => {
                                    setHelper(
                                        <>
                                            {icon}
                                            <small>
                                                Segments are groups of members that meet specific
                                                criteria. Create and manage segments to use as the
                                                recipients of a broadcast.
                                            </small>{' '}
                                        </>
                                    );
                                }}
                            />
                            <AdminLinkButton
                                title="Broadcasts"
                                icon={<i className="bi bi-broadcast"></i>}
                                href="/broadcasts"
                                onDescribe={(icon: React.ReactNode) => {
                                    setHelper(
                                        <>
                                            {icon}
                                            <small>
                                                Broadcasts are communications made with members.
                                                Create new broadcasts and view communication
                                                statistics
                                            </small>{' '}
                                        </>
                                    );
                                }}
                            />
                        </Stack>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        {helper && <span style={{ maxWidth: '650px' }}>{helper}</span>}
                    </Col>
                </Row>
            </Container>
        </Card>
    );
};

export default Dashboard;
