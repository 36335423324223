import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CREATE_ACCREDITATION_TYPE,
    DELETE_ACCREDITATION_TYPE,
} from '../../graphql/accreditation/mutations';
import { GET_ACCREDITATION_TYPES } from '../../graphql/accreditation/queries';
import {
    AccreditationType,
    AccreditationTypeFilterInput,
    AccreditationTypesConnection,
    CreateAccreditationTypeCommandInput,
    DeleteAccreditationTypeCommandInput,
    StringOperationFilterInput,
} from '../../graphql/__generated__/graphql';
import _ from 'lodash';

export const useAccreditations = () => {
    const navigate = useNavigate();

    const [accreditationConnection, setAccreditationConnection] =
        useState<AccreditationTypesConnection>();

    const [accreditations, setAccreditations] = useState<AccreditationType[]>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [cursor, setCursor] = useState<string>();
    const [keywords, setKeywords] = useState<string>();
    const [expired, setExpired] = useState<string>();

    const [prev, setPrev] = useState(false);

    const variables = {
        take: pageSize,
        after: prev ? undefined : (cursor ?? undefined),
        before: !prev ? undefined : (cursor ?? undefined),
        where: {
            or: [
                {
                    name: keywords
                        ? ({ contains: keywords } as StringOperationFilterInput)
                        : undefined,
                },
                //{ lastname: keywords ? { contains: keywords } as StringOperationFilterInput : undefined },
                //{ email: keywords ? { contains: keywords } as StringOperationFilterInput : undefined }
            ],
        } as AccreditationTypeFilterInput,
    };

    const { data, error, loading } = useQuery(GET_ACCREDITATION_TYPES, {
        variables,
        fetchPolicy: 'network-only',
    });
    const [createAccreditationType] = useMutation(CREATE_ACCREDITATION_TYPE);
    const [deleteAccreditationType] = useMutation(DELETE_ACCREDITATION_TYPE);

    const handleDeleteAccreditation = (accreditationTypeId: string) => {
        const deleteCommand: DeleteAccreditationTypeCommandInput = {
            accreditationTypeId,
        };
        return new Promise((resolve, reject) => {
            deleteAccreditationType({
                variables: {
                    accreditationType: deleteCommand,
                },
            })
                .then(() => {
                    setAccreditations((prevState) => {
                        return prevState.filter(
                            (at) => at.accreditationTypeId !== accreditationTypeId
                        );
                    });
                    resolve(true);
                })
                .catch((err) => {
                    const errorMessage = err.networkError?.result?.errors[0]?.extensions?.message;
                    reject(errorMessage || 'An error occurred');
                });
        });
    };

    const handleCreateAccreditation = (name: string) => {
        return new Promise((resolve, reject) => {
            //if (error)
            //    reject(error);

            resolve(
                createAccreditationType({
                    variables: {
                        accreditation: {
                            name,
                        } as CreateAccreditationTypeCommandInput,
                    },
                }).then((response) => {
                    try {
                        const accreditationTypeId =
                            response.data.accreditationTypeCreate.accreditationTypeId;
                        navigate('/accreditation/' + accreditationTypeId + '?showRaw=true');
                    } catch (e) {
                        alert('Something went wrong. Please try again.');
                    }
                })
            );
        });
    };

    const nextPage = accreditationConnection?.pageInfo.hasNextPage
        ? () => {
              setPrev(false);
              setCursor(accreditationConnection?.pageInfo.endCursor ?? undefined);
          }
        : undefined;

    const previousPage = accreditationConnection?.pageInfo.hasPreviousPage
        ? () => {
              setPrev(true);
              setCursor(accreditationConnection?.pageInfo.startCursor ?? undefined);
          }
        : undefined;

    useEffect(() => {
        if (!loading && !error) {
            let accreditationsResult = data.accreditationTypes as AccreditationTypesConnection;
            if (accreditationConnection !== accreditationsResult) {
                setAccreditationConnection(accreditationsResult);
                setAccreditations(
                    _.sortBy(accreditationsResult?.nodes as AccreditationType[], [
                        (i) => !i.active,
                        (i) => 1 / i.sortKey,
                        'name',
                    ])
                );
            }
        }
    });

    return {
        models: {
            loading,
            page,
            keywords,
            accreditations,
            expired,
        },
        operations: {
            setKeywords,
            setExpired,
            nextPage,
            previousPage,
            handleCreateAccreditation,
            handleDeleteAccreditation,
        },
    };
};
