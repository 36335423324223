import { cloneDeep } from 'lodash';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

import { MemberProfile } from '../graphql/__generated__/graphql';

import { GET_PROFILE } from '../graphql/profile/queries';
import { useAuth } from 'react-oidc-context';
import { IAdminProfile } from '../interfaces/IAdminProfile';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from storage if it exists
    const authData = sessionStorage.getItem('oidc.user:https://localhost:7035:Coalese');

    const s = JSON.parse(authData ?? '{}');

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: s.access_token ? `Bearer ${s.access_token}` : '',
        },
    };
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
});

export interface IUserContext {
    loggedIn: boolean;
    isLoading: boolean;
    logIn: (username: string, password: string) => void;
    signOut: () => void;
    profile: IAdminProfile | undefined;
    updateProfileProperties: (properties: { property: string; value: any }[]) => IAdminProfile;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

const getProfile = async (): Promise<MemberProfile> => {
    try {
        const { data } = await client.query({
            query: GET_PROFILE,
        });

        return data.profile;
    } catch (error) {
        console.log(error);
        return {} as MemberProfile;
    }
};

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }
    return context;
}

export const UserContextProvider: React.FC<PropsWithChildren> = ({ ...props }) => {
    const auth = useAuth();

    const [profile, setProfile] = useState<IAdminProfile>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (auth.isAuthenticated && isLoading) {
            getProfile().then((m) => {
                setProfile({ ...m } as IAdminProfile);
                setIsLoading(false);
            });
        }
    });

    const logIn = (username: string, password: string) => {
        //TODO : authentication logic
        // setProfile(mockProfile)
    };

    const signOut = () => {
        auth.signoutRedirect();
    };

    const loggedIn = auth.isAuthenticated;

    const updateProfileProperties = (properties: { property: string; value: any }[]) => {
        let updatedProfile: any = cloneDeep(profile);

        properties.forEach((p) => {
            updatedProfile[p.property] = p.value;
        });

        //  setProfile(updatedProfile);
        return updatedProfile;
    };

    return (
        <UserContext.Provider
            value={{
                loggedIn,
                logIn,
                signOut,
                profile,
                updateProfileProperties,
                isLoading,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};
