import { IAppRequirement } from '../../interfaces/IApplicationRequirements';
import { Card, CardBody, Col, FloatingLabel, Form, FormControl, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';

interface Props {
    requirement: IAppRequirement;
    handleChange: (req: IAppRequirement) => void;
    hideDescription?: boolean;
}

const ApplicationRequirementPicker: React.FC<Props> = ({
    requirement,
    handleChange,
    hideDescription = false,
}) => {
    const [appRequirement, setAppRequirement] = React.useState<IAppRequirement>(requirement);

    const handleReqChange = (fieldName: string, fieldValue: any) => {
        setAppRequirement((prevState) => {
            return {
                ...prevState,
                [fieldName]: fieldValue,
            };
        });
    };

    useEffect(() => {
        handleChange(appRequirement);
    }, [appRequirement]);

    return (
        <Card className={'mb-1'}>
            <CardBody>
                <Card.Title>{appRequirement.title}</Card.Title>
                <Row>
                    {requirement.type === 'checkbox' && (
                        <>
                            <Col xs={hideDescription ? 4 : 2}>
                                <Form.Check
                                    className={'mt-2'}
                                    name={'required'}
                                    checked={appRequirement.required}
                                    value={'required'}
                                    onChange={(e) => handleReqChange('required', e.target.checked)}
                                    label={'Applicant confirmation required?'}
                                />
                            </Col>
                            <Col xs={hideDescription ? 6 : 4}>
                                <FloatingLabel label={'Checkbox label'}>
                                    <FormControl
                                        className={'h-auto'}
                                        name={'label'}
                                        as={'textarea'}
                                        rows={3}
                                        value={appRequirement.label || ''}
                                        onChange={(e) => handleReqChange('label', e.target.value)}
                                    />
                                </FloatingLabel>
                            </Col>
                        </>
                    )}
                    {requirement.type === 'number' && (
                        <>
                            <Col xs={2}>
                                <FloatingLabel label={'Min value'}>
                                    <FormControl
                                        name={'min'}
                                        type={'number'}
                                        value={appRequirement.min || ''}
                                        onChange={(e) => handleReqChange('min', e.target.value)}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={2}>
                                <FloatingLabel label={'Max value (optional)'}>
                                    <FormControl
                                        name={'max'}
                                        type={'number'}
                                        value={appRequirement.max || ''}
                                        onChange={(e) => handleReqChange('max', e.target.value)}
                                    />
                                </FloatingLabel>
                            </Col>
                        </>
                    )}
                    {!hideDescription && (
                        <Col>
                            <FloatingLabel label={'Description'}>
                                <FormControl
                                    className={'h-auto'}
                                    name={'description'}
                                    as={'textarea'}
                                    rows={3}
                                    value={appRequirement.description || ''}
                                    onChange={(e) => handleReqChange('description', e.target.value)}
                                />
                            </FloatingLabel>
                        </Col>
                    )}
                </Row>
            </CardBody>
        </Card>
    );
};

export default ApplicationRequirementPicker;
