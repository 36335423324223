import { useState } from 'react';
import { CreateSegmentModalProps } from './CreateSegmentModalProps';

export const useCreateSegment = ({ ...props }) => {
    var { onCreate } = { ...props } as CreateSegmentModalProps;

    const [show, setShow] = useState(false);

    const [name, setName] = useState<string>('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        onCreate(name).then(() => {
            handleClose();
        });
    };

    return {
        models: {
            name,
            show,
        },
        operations: {
            handleShow,
            handleSave,
            handleClose,
            setName,
        },
    };
};
