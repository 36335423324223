import { useEffect, useState } from 'react';

export const useEditableCPD = ({ value }: { value: number }) => {
    const [show, setShow] = useState(false);
    const [cpd, setCPD] = useState<number>();

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    useEffect(() => {
        if (value && !cpd) {
            setCPD(value);
        }
    });

    return {
        models: { show, cpd },
        operations: { setCPD, handleOpen, handleClose },
    };
};
