import React, { useEffect, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { CreateNoteModalProps } from './CreateNoteModalProps';
import { useCreateNote } from './useCreateNote';

const CreateNoteModal: React.FC<CreateNoteModalProps> = ({ ...props }) => {
    const { models, operations } = useCreateNote({ ...props });
    const { note, error, show, isValid } = models;
    const { handleShow, handleClose, handleSave, handleChange } = operations;

    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (show === true) inputRef.current?.focus();
    }, [show]);

    return (
        <>
            <Button size="sm" variant="outline-primary" className="mb-2 me-2" onClick={handleShow}>
                <i className="bi bi-plus me-1"></i>
                Add Note
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control
                            ref={inputRef}
                            type="textarea"
                            as={'textarea'}
                            rows={4}
                            required
                            placeholder="Enter note details"
                            value={note}
                            onChange={handleChange}
                        />
                        <Form.Text>
                            <b> Members cannot see notes:</b> they are for administrative purposes
                            only.
                        </Form.Text>
                    </Form.Group>

                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={!isValid}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateNoteModal;
