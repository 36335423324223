import {useMutation, useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {
    AccreditationApplication,
    ApplicationStatus,
    CreateAccreditationCommandInput,
    UpdateAccreditationApplicationCommandInput,
} from '../../graphql/__generated__/graphql';
import {CREATE_ACCREDITATION, UPDATE_ACCREDITATION_APPLICATION} from '../../graphql/accreditation/mutations';
import {GET_ACCREDITATION_APPLICATION} from '../../graphql/accreditation/queries';

export const useAccreditationApplication = ({ ...props }) => {
    const { applicationId } = props;
    const [application, setApplication] = useState<AccreditationApplication>();

    const { data, error, loading } = useQuery(GET_ACCREDITATION_APPLICATION, {
        variables: { id: applicationId },
    });

    useEffect(() => {
        if (!loading && !error) {
            setApplication(data.accreditationApplication);
        }
    }, [data]);

    const [applicationUpdate] = useMutation(UPDATE_ACCREDITATION_APPLICATION);
    const [createAccreditation] = useMutation(CREATE_ACCREDITATION);

    const _handleUpdateApplication = async (command: UpdateAccreditationApplicationCommandInput) => {
        const response = await applicationUpdate({
            variables: {
                application: command,
            },
        });
        try {
            setApplication(response.data.accreditationApplicationUpdate);
        } catch (e) {
            alert('Something went wrong!');
        }
    }

    const handleApproveAccreditation = async () => {
        handleUpdateApplicationStatus(ApplicationStatus.Approved).then(async () => {
            const createAccreditationCommand: CreateAccreditationCommandInput = {
                accreditationApplicationId: applicationId,
                accreditationTypeId: application?.accreditationType?.accreditationTypeId,
                memberId: application?.member?.memberId
            }

            await createAccreditation({
                variables: {
                    accreditation: createAccreditationCommand,
                }
            })
        });
    }

    const handleUpdateApplicationStatus = (status: ApplicationStatus) => {
        return new Promise((resolve, reject) => {
            const updateCommand: UpdateAccreditationApplicationCommandInput = {
                accreditationApplicationId: applicationId,
                applicationStatus: status,
            };

            resolve(_handleUpdateApplication(updateCommand));
        });
    };

    const handleUpdateApplicationMitigation = (hasMitigation: boolean) => {
        return new Promise((resolve, reject) => {
            const updateCommand = {
                accreditationApplicationId: applicationId,
                hasMitigation
            };

            resolve(_handleUpdateApplication(updateCommand));
        });
    }

    return {
        models: {
            application,
            loading,
        },
        operations: {
            handleApproveAccreditation,
            handleUpdateApplicationMitigation,
            handleUpdateApplicationStatus,
        },
    };
};
