import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { UpdateMembershipModalProps } from './UpdateMembershipModalProps';
import { formatToISODate } from '../../../utils/utils';

export const useUpdateMembership = ({ ...props }) => {
    const { membership, onUpdate } = { ...props } as UpdateMembershipModalProps;

    const [membershipTypeId, setMembershipTypeId] = useState<string>(
        membership?.membershipType.membershipTypeId
    );

    const [endDate, setEndDate] = useState<string>(
        formatToISODate(
            membership
                ? new Date(membership.endDate).toLocaleDateString('en-gb') //existing date
                : new Date().toLocaleDateString('en-gb').slice(0, 10)
        )
    ); //todays date

    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        setLoading(true);
        let updated = cloneDeep(membership);
        if (updated) {
            updated.endDate = new Date(endDate);
            updated.membershipTypeId = membershipTypeId;

            onUpdate(updated).then(() => {
                handleClose();
                setLoading(false);
            });
        }
    };

    return {
        models: {
            membershipTypeId,
            endDate,
            show,
            loading,
        },
        operations: {
            handleShow,
            handleSave,
            handleClose,
            setMembershipTypeId,
            setEndDate,
        },
    };
};
