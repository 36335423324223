import { Accordion, Col, FloatingLabel, FormControl, Row, Tab, Tabs } from 'react-bootstrap';
import React, { useEffect } from 'react';
import {
    IAppRequirement,
    IDocumentRequirementsGroup,
    IEvaluationRequirement,
    IEvaluationRequirementsGroup,
} from '../../interfaces/IApplicationRequirements';
import ApplicationRequirementPicker from '../Form/ApplicationRequirementPicker';
import EvaluationRequirementPicker from '../Form/EvaluationRequirementPicker';
import DocumentRequirementPicker from '../Form/DocumentRequirementPicker';

interface Props {
    requirementsJson: string;
    onChange: (updatedJson: string) => void;
}

interface SimpleConfig {
    [field: string]: any;
}

const Requirements: React.FC<Props> = ({ requirementsJson, onChange }) => {
    const requirements = JSON.parse(requirementsJson);

    const [experience, setExperience] = React.useState<IAppRequirement[]>([]);
    const [documents, setDocuments] = React.useState<IDocumentRequirementsGroup[]>([]);
    const [evaluation, setEvaluation] = React.useState<IEvaluationRequirementsGroup[]>([]);
    const [config, setConfig] = React.useState<SimpleConfig>({});

    const handleConfigChange = (name: string, value: any) => {
        const normalisedValue = normaliseValue(value);

        setConfig((prevState) => {
            return {
                ...prevState,
                [name]: normalisedValue,
            };
        });
    };

    const handleHelpChange = (name: string, value: string) => {
        setConfig((prevState) => {
            return {
                ...prevState,
                help: {
                    ...prevState.help,
                    [name]: value,
                }
            }
        })
    }

    const normaliseValue = (value: any) => {
        if (value === 'true' || value === 'false') {
            return value === 'true';
        }

        const hi = Number.parseInt(value);
        if (!isNaN(hi)) {
            return hi;
        }

        return value as string;
    };

    const handleExperienceChange = (updatedRequirement: IAppRequirement) => {
        setExperience((prevState) => {
            return prevState.map((item) => {
                return item.name === updatedRequirement.name ? updatedRequirement : item;
            });
        });
    };

    const handleDocumentChange = (
        updatedRequirement: IAppRequirement,
        documentsGroup: IDocumentRequirementsGroup
    ) => {
        setDocuments((prevState) => {
            return prevState.map((item) => {
                if (item.name === documentsGroup.name) {
                    return {
                        ...item,
                        requirements: item.requirements.map((req) => {
                            return req.name === updatedRequirement.name ? updatedRequirement : req;
                        }),
                    };
                }
                return item;
            });
        });
    };

    const handleDocumentGroupChange = (
        field: string,
        newValue: any,
        updatedDocumentsGroup: IDocumentRequirementsGroup
    ) => {
        setDocuments((prevState) => {
            return prevState.map((item) => {
                if (item.name === updatedDocumentsGroup.name) {
                    return {
                        ...updatedDocumentsGroup,
                        [field]: newValue,
                    };
                }

                return item;
            });
        });
    };

    const handleEvaluationChange = (
        updatedRequirement: IEvaluationRequirement,
        evaluationGroup: IEvaluationRequirementsGroup
    ) => {
        setEvaluation((prevState) => {
            return prevState.map((item) => {
                if (item.name === evaluationGroup.name) return item;

                return {
                    ...item,
                    requirements: item.requirements.map((req) => {
                        return req.name === updatedRequirement.name ? updatedRequirement : req;
                    }),
                };
            });
        });
    };

    useEffect(() => {
        setExperience(requirements.experience);
        setDocuments(requirements.documents || []);
        setEvaluation(requirements.evaluation);
        setConfig(requirements.config);
    }, []);

    useEffect(() => {
        onChange(
            JSON.stringify({
                ...requirements,
                experience,
                documents,
                evaluation,
                config,
            })
        );
    }, [experience, documents, evaluation, config]);

    return (
        <Tabs defaultActiveKey="experience" id="requirements-tabs" justify>
            <Tab eventKey="experience" title={'Applicant Experience'}>
                {experience?.map((requirement) => {
                    return (
                        <ApplicationRequirementPicker
                            key={requirement.name}
                            requirement={requirement}
                            handleChange={handleExperienceChange}
                        />
                    );
                })}
            </Tab>
            <Tab eventKey="documents" title={'Applicant Documents'}>
                <Accordion>
                    {documents?.map((documentsGroup, index) => {
                        return (
                            <Accordion.Item
                                key={documentsGroup.name}
                                eventKey={documentsGroup.name}
                            >
                                <Accordion.Header>{documentsGroup.title}</Accordion.Header>
                                <Accordion.Body>
                                    <Row className={'mb-3 border-bottom pb-3'}>
                                        <Col xs={4}>
                                            <FloatingLabel label={'Documents group title'}>
                                                <FormControl
                                                    className={'h-auto'}
                                                    name={'title'}
                                                    type={'text'}
                                                    value={documentsGroup.title || ''}
                                                    onChange={(e) =>
                                                        handleDocumentGroupChange(
                                                            'title',
                                                            e.target.value,
                                                            documentsGroup
                                                        )
                                                    }
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel label={'Documents group description'}>
                                                <FormControl
                                                    className={'h-auto'}
                                                    name={'description'}
                                                    as={'textarea'}
                                                    rows={3}
                                                    value={documentsGroup.description || ''}
                                                    onChange={(e) =>
                                                        handleDocumentGroupChange(
                                                            'description',
                                                            e.target.value,
                                                            documentsGroup
                                                        )
                                                    }
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    {documentsGroup?.requirements.map((requirement) => {
                                        if (requirement.type === 'document') {
                                            return (
                                                <DocumentRequirementPicker
                                                    key={requirement.name}
                                                    requirement={requirement}
                                                    handleChange={(req) =>
                                                        handleDocumentChange(req, documentsGroup)
                                                    }
                                                />
                                            );
                                        }

                                        return (
                                            <ApplicationRequirementPicker
                                                key={requirement.name}
                                                requirement={requirement}
                                                handleChange={(req) =>
                                                    handleDocumentChange(req, documentsGroup)
                                                }
                                                hideDescription={requirement.type === 'checkbox'}
                                            />
                                        );
                                    })}
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                </Accordion>
            </Tab>
            <Tab eventKey="evaluation" title={'Supervisor Evaluation'}>
                <Accordion>
                    {evaluation?.map((evaluationGroup) => {
                        return (
                            <Accordion.Item
                                key={evaluationGroup.name}
                                eventKey={evaluationGroup.name}
                            >
                                <Accordion.Header>{evaluationGroup.title}</Accordion.Header>
                                <Accordion.Body>
                                    {evaluationGroup?.requirements.map((requirement) => {
                                        return (
                                            <EvaluationRequirementPicker
                                                key={requirement.name}
                                                requirement={requirement}
                                                handleChange={(req) =>
                                                    handleEvaluationChange(req, evaluationGroup)
                                                }
                                            />
                                        );
                                    })}
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                </Accordion>
            </Tab>
            {config && config.help && (
                <Tab eventKey={'help'} title={'Help Info'}>
                    {Object.keys(config.help).map((key) => {
                        const value = config.help[key];
                        return (
                            <FloatingLabel label={key}>
                                <FormControl
                                    name={key}
                                    key={key}
                                    value={value}
                                    onChange={(e) => handleHelpChange(key, e.target.value)}
                                />
                            </FloatingLabel>
                        );
                    })}
                </Tab>
            )}
            {config && (
                <Tab eventKey={'config'} title={'Other Config'}>
                    {Object.keys(config).map((key) => {
                        if (key === 'help') {
                            return <></>;
                        }
                        const value = config[key];
                        return (
                            <FloatingLabel label={key}>
                                <FormControl
                                    name={key}
                                    key={key}
                                    value={value}
                                    onChange={(e) => handleConfigChange(key, e.target.value)}
                                />
                            </FloatingLabel>
                        );
                    })}
                </Tab>
            )}
        </Tabs>
    );
};

export default Requirements;
