import React from 'react';
import { Card } from 'react-bootstrap';
import { MemberDto } from '../../graphql/__generated__/graphql';
import MemberSectionContainer from '../MemberSectionContainer';
import UpdateMemberDetailsModal from '../Modals/UpdateMemberDetailsModal';
import { MemberDetailLabel } from './MemberDetailLabel';
import { useMember } from './useMember';

const MemberDetailsSection: React.FC<{ member: MemberDto }> = ({ member }) => {
    const { models, operations } = useMember(member.memberId);

    const { handleUpdateMember } = operations;

    return (
        <MemberSectionContainer
            title="Details"
            action={<UpdateMemberDetailsModal member={member} onUpdate={handleUpdateMember} />}
        >
            <Card>
                <Card.Body>
                    <div>
                        <MemberDetailLabel title="EMDR Training Level" />
                        <p>
                            {member.emdrTrainingLevel?.name ?? '-'}
                            <small className="ms-2">
                                (Achieved:{' '}
                                {new Date(member.emdrTrainingLevelDateAchieved).toLocaleDateString(
                                    'en-GB'
                                )}
                                )
                            </small>
                        </p>
                    </div>
                    <div>
                        <MemberDetailLabel title="Child Training Level" />
                        <p>
                            {member.childTrainingLevel && member.childTrainingLevel.length > 0
                                ? member.childTrainingLevel
                                : '-'}
                            {member.childTrainingLevelDateAchieved && (
                                <small className="ms-2">
                                    (Achieved:{' '}
                                    {new Date(
                                        member.childTrainingLevelDateAchieved
                                    ).toLocaleDateString('en-GB')}
                                    )
                                </small>
                            )}
                        </p>
                    </div>
                    <div>
                        <MemberDetailLabel title="Languages" />
                        <p>
                            {member.languages && member.languages.length > 0
                                ? member.languages
                                : '-'}
                        </p>
                    </div>
                    <div>
                        <MemberDetailLabel title="Region" />
                        <p>{member.region?.name ?? '-'}</p>
                    </div>
                    <div>
                        <MemberDetailLabel title="Profession" />
                        {member.professions && member.professions.length > 0 ? (
                            member.professions.map((pr) => {
                                return <p key={pr.professionId}>{pr.name}</p>;
                            })
                        ) : (
                            <p>-</p>
                        )}
                    </div>
                    <div>
                        <MemberDetailLabel title="Professional Registration" />
                        {member.professionalRegistrations &&
                        member.professionalRegistrations.length > 0 ? (
                            member.professionalRegistrations.map((pr) => {
                                return <p key={pr.professionalRegistrationId}>{pr.name}</p>;
                            })
                        ) : (
                            <p>-</p>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </MemberSectionContainer>
    );
};

export default MemberDetailsSection;
