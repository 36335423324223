import { gql } from '@apollo/client';

export const noteGQL = `
    noteId
    value
    createdDate
    memberId
`;

export const GET_NOTES_BY_MEMBER_ID = gql`
  query GetNotesByMemberId($id:String! $first:Int $after:String $where:NoteFilterInput) {
    notesByMemberId(id:$id first:$first after:$after where:$where){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        nodes{
            ${noteGQL}
        }
    }
}
`;
