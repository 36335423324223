import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CREATE_ACCREDITATION } from '../../graphql/accreditation/mutations';
import { UPDATE_MEMBER } from '../../graphql/member/mutations';
import { GET_MEMBER } from '../../graphql/member/queries';
import {
    CreateAccreditationCommandInput,
    MemberDto,
    UpdateMemberCommandInput,
} from '../../graphql/__generated__/graphql';

export const useMember = ({ ...props }) => {
    const { memberId } = props;
    const [member, setMember] = useState<MemberDto>();

    const [accreditationTypeId, setAccreditationTypeId] = useState<string>();

    const [inProgress, setInProgress] = useState(true);

    const { data, error, loading, refetch } = useQuery(GET_MEMBER, {
        variables: { id: memberId },
    });

    const [updateMember] = useMutation(UPDATE_MEMBER);
    const [createAccreditation] = useMutation(CREATE_ACCREDITATION);

    const handleReloadMember = (id = null) => {
        refetch({ id: id ?? memberId }).then((resp) => {
            setInProgress(false);
        });
    };

    const handleUpdateMember = (updatedMember: MemberDto) => {
        return new Promise((resolve, reject) => {
            var uMember = {
                memberId: updatedMember.memberId,
                emdrTrainingLevel: updatedMember.emdrTrainingLevel?.emdrTrainingLevelId,
                emdrTrainingLevelDateAchieved: updatedMember.emdrTrainingLevelDateAchieved,
                childTrainingLevel: updatedMember.childTrainingLevel,
                childTrainingLevelDateAchieved:
                    updatedMember.childTrainingLevelDateAchieved ?? null,
                languages: updatedMember.languages,
                region: updatedMember.region?.regionId,
                professions: updatedMember.professions?.map((p) => p.professionId),
                professionalRegistrations: updatedMember.professionalRegistrations?.map(
                    (pr) => pr.professionalRegistrationId
                ),
            } as UpdateMemberCommandInput;

            resolve(
                updateMember({
                    variables: {
                        member: uMember,
                    },
                })
                    .then(() => {
                        handleReloadMember(updatedMember.memberId);
                    })
                    .catch((error) => {})
            );
        });
    };

    const handleCreateAccreditation = () => {
        setInProgress(true);

        return new Promise((resolve, reject) => {
            if (error) reject(error);

            let atId = accreditationTypeId;
            setAccreditationTypeId(undefined);

            resolve(
                createAccreditation({
                    variables: {
                        accreditation: {
                            memberId: memberId,
                            accreditationTypeId: atId,
                        } as CreateAccreditationCommandInput,
                    },
                }).then(() => {
                    handleReloadMember();
                    console.log(data);
                })
            );
        });
    };

    useEffect(() => {
        if (!loading && !error) {
            setMember(data.member);
            console.log(data);
            setInProgress(false);
        }
    }, [data]);

    return {
        models: {
            member,
            accreditationTypeId,
            inProgress,
        },
        operations: {
            setAccreditationTypeId,
            handleUpdateMember,
            handleCreateAccreditation,
            handleReloadMember,
        },
    };
};
