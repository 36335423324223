import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Col,
    FormControl,
    FormSelect,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMembers } from './useMembers';

const Members: React.FC = () => {
    const { models, operations } = useMembers();
    const {
        loading,
        members,
        show,
        keywords,
        skip,
        currentPage,
        totalPages,
        orderBy,
        orderByDesc,
    } = models;
    const {
        setShow,
        setKeywords,
        nextPage,
        previousPage,
        firstPage,
        lastPage,
        saveStateToLocalStorage,
        setOrderBy,
        setOrderByDesc,
    } = operations;

    const navigate = useNavigate();

    const renderSortButton = (column: string, label: string) => {
        return (
            <th>
                {label}{' '}
                {orderBy === column ? (
                    orderByDesc ? (
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setOrderByDesc(false)}
                            className="ms-2 p-1 py-0"
                        >
                            <strong>
                                <i className="bi bi-arrow-up"></i>
                            </strong>
                        </Button>
                    ) : (
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setOrderByDesc(true)}
                            className="ms-2 p-1 py-0"
                        >
                            <strong>
                                <i className="bi bi-arrow-down"></i>
                            </strong>
                        </Button>
                    )
                ) : (
                    <Button
                        size="sm"
                        variant="link-primary"
                        onClick={() => {
                            setOrderBy(column);
                            setOrderByDesc(false);
                        }}
                        className="ms-2"
                    >
                        <i style={{ opacity: 0.9 }} className="bi bi-arrow-down-up"></i>
                    </Button>
                )}
            </th>
        );
    };

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>
                <Row>
                    <Col style={{ textAlign: 'left' }}>
                        <h3>Members</h3>
                    </Col>
                    <Col xs={'auto'}>
                        <FormControl
                            placeholder={'search...'}
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className="mb-2"
                        />
                    </Col>
                    <Col xs={'auto'}>
                        <FormSelect value={show} onChange={(e) => setShow(e.target.value)}>
                            <option value="">All Members</option>
                            <option value="active">Active Members</option>
                            <option value="expired">Expired Members</option>
                        </FormSelect>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            variant="outline-info"
                            onClick={() => {
                                setShow('');
                                setKeywords('');
                                saveStateToLocalStorage(true);
                            }}
                        >
                            Clear
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Table>
                                <thead>
                                    <tr>
                                        {renderSortButton('firstname', 'First Name')}
                                        {renderSortButton('lastname', 'Last Name')}
                                        {renderSortButton('email', 'Email')}
                                        {renderSortButton('membershipNumber', 'Membership Number')}
                                        {renderSortButton('membershipStatus', 'Membership Status')}
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {members &&
                                        members.map((member) => (
                                            <tr key={member.memberId}>
                                                <td>{member.firstname}</td>
                                                <td>{member.lastname}</td>
                                                <td>{member.email}</td>
                                                <td>{member.membershipNumber}</td>
                                                <td>
                                                    {member.memberships &&
                                                    member.memberships.some(
                                                        (x) => new Date(x.endDate) > new Date()
                                                    )
                                                        ? 'Active'
                                                        : 'Expired'}
                                                </td>
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-primary"
                                                        onClick={() => {
                                                            saveStateToLocalStorage();
                                                            navigate('/member/' + member.memberId);
                                                        }}
                                                    >
                                                        <i className="bi bi-chevron-right"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>
                <div>
                    <Button
                        variant="outline-primary"
                        disabled={skip === 0 || totalPages === 0}
                        onClick={firstPage}
                    >
                        <i className="bi bi-chevron-bar-left"></i>
                    </Button>
                    <ButtonGroup className="m-2">
                        <Button
                            disabled={!previousPage || skip === 0 || totalPages === 0}
                            onClick={previousPage}
                        >
                            Back
                        </Button>
                        <Button
                            disabled={
                                !nextPage ||
                                members?.length === 0 ||
                                totalPages === 0 ||
                                currentPage > totalPages
                            }
                            onClick={nextPage}
                        >
                            Next
                        </Button>
                    </ButtonGroup>
                    <Button
                        variant="outline-primary"
                        disabled={totalPages === 0 || currentPage > totalPages}
                        onClick={lastPage}
                    >
                        <i className="bi bi-chevron-bar-right"></i>
                    </Button>
                    <span className="mt-3" style={{ float: 'right' }}>
                        <b>{currentPage}</b> / {totalPages + 1}
                    </span>
                </div>
            </CardBody>
        </Card>
    );
};

export default Members;
