import { Card, CardBody, Col, FloatingLabel, FormControl, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { IEvaluationRequirement } from '../../interfaces/IApplicationRequirements';

interface Props {
    requirement: IEvaluationRequirement;
    handleChange: (req: IEvaluationRequirement) => void;
}

const EvaluationRequirementPicker: React.FC<Props> = ({ requirement, handleChange }) => {
    const [evalRequirement, setEvalRequirement] =
        React.useState<IEvaluationRequirement>(requirement);

    const handleReqChange = (fieldName: string, fieldValue: any) => {
        setEvalRequirement((prevState) => {
            return {
                ...prevState,
                [fieldName]: fieldValue,
            };
        });
    };

    useEffect(() => {
        evalRequirement && handleChange(evalRequirement);
    }, [evalRequirement]);

    // @ts-ignore
    return (
        <Card className={'mb-1'}>
            <CardBody>
                <Card.Title>{evalRequirement.title}</Card.Title>
                <Row className={'mb-2'}>
                    {evalRequirement.type !== 'number' && (
                        <>
                            <Col xs={2}>
                                <FloatingLabel label={'Min length'}>
                                    <FormControl
                                        name={'minLength'}
                                        value={evalRequirement.minLength}
                                        onChange={(e) =>
                                            handleReqChange('minLength', e.target.value)
                                        }
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={2}>
                                <FloatingLabel label={'Max length (optional)'}>
                                    <FormControl
                                        name={'maxLength'}
                                        value={evalRequirement.maxLength}
                                        onChange={(e) =>
                                            handleReqChange('maxLength', e.target.value)
                                        }
                                    />
                                </FloatingLabel>
                            </Col>
                        </>
                    )}
                    <Col>
                        <FloatingLabel label={'Subtitle'}>
                            <FormControl
                                className={'h-auto'}
                                name={'subtitle'}
                                as={'textarea'}
                                rows={evalRequirement.type === 'number' ? 1 : 3}
                                value={evalRequirement.subtitle}
                                onChange={(e) => handleReqChange('subtitle', e.target.value)}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                {evalRequirement.type !== 'number' && (
                    <Row>
                        <Col>
                            <FloatingLabel label={'Sub-elements (optional)'}>
                                <FormControl
                                    className={'h-auto'}
                                    name={'elements'}
                                    as={'textarea'}
                                    rows={10}
                                    value={evalRequirement.elements?.join('\n') || ''}
                                    onChange={(e) =>
                                        handleReqChange('elements', e.target.value.split('\n'))
                                    }
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                )}
            </CardBody>
        </Card>
    );
};

export default EvaluationRequirementPicker;
