import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import useDocuments from '../../hooks/useDocuments';
import MemberSectionContainer from '../MemberSectionContainer';
import UploadDocumentModal from '../Modals/UploadDocumentModal';
import EditableCPD from './EditableCPD/EditableCPD';
import { MemberDocumentsSectionProps } from './MemberDocumentSectionProps';

const MemberDocumentsSection: React.FC<MemberDocumentsSectionProps> = ({ member, onUpdate }) => {
    const documents = useDocuments();

    const [loading, setLoading] = useState(true);

    const [showAll, setShowAll] = useState(false);

    const handleUpdate = () => {
        setLoading(true);
        onUpdate();
    };

    useEffect(() => {
        if (member && member.documents) setLoading(false);
    }, [member]);

    return (
        <MemberSectionContainer
            title="Documents"
            action={<UploadDocumentModal memberId={member.memberId} onUpdate={onUpdate} />}
        >
            <Card
                style={
                    showAll
                        ? undefined
                        : { maxHeight: '380px', overflowY: 'scroll', scrollbarWidth: 'none' }
                }
                className="mb-2"
            >
                <Card.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th> <th>Description</th>
                                <th>CPD</th>
                                <th>Expires</th>
                                <th />
                            </tr>
                        </thead>

                        {loading ? (
                            <tbody>
                                <tr>
                                    <td colSpan={6} className="text-center">
                                        <small>
                                            <Spinner />
                                            <p>Getting member documents</p>
                                        </small>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {member &&
                                    member.documents?.map((document) => {
                                        const documentModel = {
                                            memberId: document?.memberId,
                                            documentId: document?.documentId,
                                            cpd: document?.cpd ?? 0,
                                        };

                                        return (
                                            <tr
                                                key={document?.documentId}
                                                style={{ verticalAlign: 'middle' }}
                                            >
                                                <td>{document?.name}</td>
                                                <td>
                                                    <small
                                                        className="d-inline-block text-truncate"
                                                        style={{ maxWidth: '200px' }}
                                                        title={document?.description ?? ''}
                                                    >
                                                        {document?.description}
                                                    </small>
                                                </td>
                                                <td className="text-center">
                                                    <EditableCPD
                                                        name={document?.name ?? ''}
                                                        value={document?.cpd ?? 0}
                                                        onUpdate={(cpd) =>
                                                            documents
                                                                .approve({ ...documentModel, cpd })
                                                                .then(handleUpdate)
                                                        }
                                                    />
                                                </td>
                                                {/*<td>*/}
                                                {/*    {(document?.cpd ?? 0) > 0 &&*/}
                                                {/*        <ButtonGroup size="sm">*/}
                                                {/*            <Button*/}
                                                {/*                variant={document?.cpdReceived ? "success" : "outline-success"}*/}
                                                {/*                disabled={document?.cpdReceived === true}*/}
                                                {/*                onClick={() => documents.approve(documentModel).then(handleUpdate)}*/}
                                                {/*            >*/}
                                                {/*                <i className="bi bi-check-lg"></i>*/}
                                                {/*            </Button>*/}
                                                {/*            <Button*/}
                                                {/*                variant={document?.cpdRejected ? "danger" : "outline-danger"}*/}
                                                {/*                disabled={document?.cpdRejected === true}*/}
                                                {/*                onClick={() => documents.reject(documentModel).then(handleUpdate)}*/}
                                                {/*            >*/}
                                                {/*                <i className="bi bi-x-lg"></i>*/}
                                                {/*            </Button>*/}
                                                {/*        </ButtonGroup>*/}
                                                {/*    }*/}
                                                {/*</td>*/}
                                                <td>
                                                    {document?.expiries &&
                                                        new Date(
                                                            document.expiries
                                                        ).toLocaleDateString('en-GB')}
                                                </td>
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            documents.downloadDocument(
                                                                document?.documentId
                                                            )
                                                        }
                                                    >
                                                        <i className="bi bi-download"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                <tr className="text-muted">
                                    <td colSpan={2} />
                                    <td className="text-center">
                                        <b>
                                            {member.documents?.reduce(
                                                (sum, x) => sum + (x?.cpd || 0),
                                                0
                                            )}
                                        </b>
                                    </td>
                                    <td colSpan={3}>
                                        <small>Total CPD</small>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </Card.Body>
            </Card>

            <Row>
                <Col>
                    <Button
                        size="sm"
                        variant="link"
                        className="mb-2 me-2 text-primary"
                        onClick={() => setShowAll(!showAll)}
                    >
                        {showAll ? 'Show less' : 'Show more'}
                    </Button>
                </Col>
            </Row>
        </MemberSectionContainer>
    );
};

export default MemberDocumentsSection;
