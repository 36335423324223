import { useEffect, useState } from 'react';
import { UpdateCertificateModalProps } from './UpdateCertificateModalProps';
import * as DOMPurify from 'isomorphic-dompurify';
import { useMutation, useQuery } from '@apollo/client';
import { GET_EVENT_CERTIFICATE_TEMPLATE } from '../../../graphql/event/queries';
import { UPDATE_EVENT_CERTIFICATE_TEMPLATE } from '../../../graphql/event/mutations';
import { UpdateEventCertificateTemplateCommandInput } from '../../../graphql/__generated__/graphql';
import useDocuments from '../../../hooks/useDocuments';

export const useUpdateCertificate = ({ ...props }) => {

    const documentsHook = useDocuments();

    const { onUpdate, eventId } = { ...props } as UpdateCertificateModalProps

    const { data, error, loading } = useQuery(GET_EVENT_CERTIFICATE_TEMPLATE, {
        variables: { id: eventId },
    });

    const [updateEventCertificateTemplate] = useMutation(UPDATE_EVENT_CERTIFICATE_TEMPLATE);

    const [show, setShow] = useState(false);
    const [htmlContent, setHtmlContent] = useState<string>('');
    const [key, setKey] = useState<string>('preview');
    const [inProgress, setInProgress] = useState(false);

    const handleClose = () => {
        onUpdate();
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleSave = () => {
        setInProgress(true);

        updateEventCertificateTemplate({
            variables: {
                eventCertificateTemplate: {
                    certificateTemplateHtml: htmlContent,
                    eventId,
                } as UpdateEventCertificateTemplateCommandInput,
            },
        }).then((response) => {
            handleClose();
            setInProgress(false);
            onUpdate();
        });
    };

    const handlePreview = () => {
        setInProgress(true);

        documentsHook.previewFile(props.eventId, createMarkup().__html);

        setTimeout(() => {
            setInProgress(false);
        }, 3000)
    }

    const handleHtmlChange = (value: string) => {
        setHtmlContent(value);
    };

    const createMarkup = () => {
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
        return {
            __html: sanitizedHtml
                .replaceAll('[EVENT_NAME]', props.mockData.EVENT_NAME)
                .replaceAll('[MEMBER_NAME]', props.mockData.MEMBER_NAME)
                .replaceAll('[MEMBERSHIP_NUMBER]', '000000000')
                .replaceAll('[ISSUE_DATE]', new Date().toLocaleDateString('en-gb')),
        };
    };

    useEffect(() => {
        if (!loading && !error) {
            setHtmlContent(data.eventCertificateTemplate);
        }
    }, [data, loading]);

    return {
        models: {
            show,
            key,
            htmlContent,
            inProgress,
        },
        operations: {
            handleShow, handleSave, handleClose, handlePreview, handleHtmlChange, setKey, createMarkup
        }
    };
};
