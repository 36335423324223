import {
  Button,
  Card,
  CardBody,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  FormGroup,
  FormSelect,
  Row,
  Stack,
} from 'react-bootstrap';
import React, {ChangeEvent} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAccreditationApplication} from './useAccreditationApplication';
import {LoadingSpinner} from '../LoadingSpinner';
import {ApplicationStatus} from '../../graphql/__generated__/graphql';
import usePdfApplicationGenerator from "../../hooks/usePdfApplicationGenerator";

const AccreditationApplication: React.FC = () => {
  const navigate = useNavigate();

  const { applicationId } = useParams();
  const { models, operations } = useAccreditationApplication({ applicationId });
  const { application, loading } = models;
  const {generatePdf} = usePdfApplicationGenerator();
  const { handleUpdateApplicationMitigation, handleUpdateApplicationStatus, handleApproveAccreditation } = operations;

  const [comments, setComments] = React.useState<string>('');
  const [downloading, setDownloading] = React.useState(false);
  const [responseType, setResponseType] = React.useState<ApplicationStatus>(ApplicationStatus.NeedsWork);
  const [accreditationStartDate, setAccreditationStartDate] = React.useState<string>();

  if (loading) {
    return <LoadingSpinner/>;
  }

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      Date.parse(e.target.value);
      setAccreditationStartDate(e.target.value);
    } catch (e) {
      // ignore
    }
  };

  const downloadApplicationPdf = async () => {
    if (downloading || !application) {
      return;
    }
    setDownloading(true);
    generatePdf(application).finally(() => setDownloading(false));
  }

  return (
    <>
      <Card bg="light" border={'light'} className={'w-100'}>
        <CardBody>
          <Row>
            <Col xs="2">
              <Button
                variant="outline-primary"
                onClick={() => navigate('/accreditations')}
              >
                <i className="bi bi-chevron-left"></i>
              </Button>
            </Col>

            <Col xs={8}>
              <h1>
                {application?.member?.firstname} {application?.member?.lastname}'s {application?.accreditationType?.name}{' '}
                Application
              </h1>
            </Col>
            <Col xs={2}>
              <Button variant={'primary'} disabled={downloading}
                      onClick={downloadApplicationPdf}>{downloading ? 'Downloading...' : 'Download PDF'}</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={'auto'}>
              <Form.Group as={Stack} direction="horizontal" gap={2}>
                <FormSelect
                    value={application?.hasMitigation === true ? 'true' : 'false'}
                    onChange={(e) => {
                      handleUpdateApplicationMitigation(e.target.value === 'true');
                    }}
                >
                  <option value="true">Applicant has been granted mitigating circumstances</option>
                  <option value="false">Applicant is not entitled to mitigations</option>
                </FormSelect>
                <Form.Check // prettier-ignore
                    type="switch"
                    checked={application?.hasMitigation}
                    onChange={() => handleUpdateApplicationMitigation(!application?.hasMitigation)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Respond to Application</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={'auto'}>
              <Form.Group as={Stack} direction="horizontal" gap={2}>
                <FormSelect
                    value={responseType}
                    onChange={(e) => {
                      setResponseType(responseType === ApplicationStatus.Approved ? ApplicationStatus.NeedsWork : ApplicationStatus.Approved);
                    }}
                >
                  <option value={ApplicationStatus.Approved}>Approve</option>
                  <option value={ApplicationStatus.NeedsWork}>Return to applicant</option>
                </FormSelect>
                <Form.Check // prettier-ignore
                    type="switch"
                    checked={responseType === ApplicationStatus.Approved}
                    onChange={() => setResponseType(responseType === ApplicationStatus.Approved ? ApplicationStatus.NeedsWork : ApplicationStatus.Approved)}
                />
              </Form.Group>
            </Col>
          </Row>
          {responseType === ApplicationStatus.NeedsWork &&
              <>
                <Row>
                  <FormGroup>
                    <FloatingLabel label={'Comments'}>
                      <FormControl
                        name={'comments'}
                        as={'textarea'}
                        rows={5}
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                      />
                    </FloatingLabel>
                  </FormGroup>
                </Row>
                <Row>
                  <Col xs={'auto'}>
                    <Button
                        variant="secondary"
                        onClick={() =>
                            handleUpdateApplicationStatus(ApplicationStatus.NeedsWork)
                        }
                    >
                      Return to Applicant
                    </Button>
                  </Col>
                </Row>
          </>
          }
          {responseType === ApplicationStatus.Approved &&
          <>
            <Row>
              <FormGroup>
                <Form.Label className={'fw-bold'}>
                  Accreditation start date
                  <Form.Control
                      name="startDate"
                      value={accreditationStartDate}
                      type="date"
                      required
                      style={{ minWidth: '60px' }}
                      onChange={handleDateChange}
                      onBlur={(e) =>
                          setAccreditationStartDate(e.target.value)
                      }
                  />
                </Form.Label>
              </FormGroup>
            </Row>
            <Row>
              <Col xs={'auto'}>
                <Button
                    variant="primary"
                    onClick={() =>
                        handleApproveAccreditation()
                    }
                >
                  Approve
                </Button>
              </Col>
            </Row>
          </>
          }
        </CardBody>
      </Card>
    </>
  );
};

export default AccreditationApplication;
