import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CREATE_SEGMENT } from '../graphql/segment/mutations';
import { GET_SEGMENTS } from '../graphql/segment/queries';
import {
    CreateSegmentCommandInput,
    Segment,
    SegmentsConnection,
    SegmentUpdateModelFilterInput,
    StringOperationFilterInput,
} from '../graphql/__generated__/graphql';

export const useSegments = () => {
    const navigate = useNavigate();

    const [segmentConnection, setSegmentConnection] = useState<SegmentsConnection>();

    const [segments, setSegments] = useState<Segment[]>();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [cursor, setCursor] = useState<string>();
    const [keywords, setKeywords] = useState<string>();

    const [prev, setPrev] = useState(false);

    const variables = {
        take: pageSize,
        after: prev === true ? undefined : (cursor ?? undefined),
        before: prev === false ? undefined : (cursor ?? undefined),
        where: {
            name: keywords ? ({ contains: keywords } as StringOperationFilterInput) : undefined,
        } as SegmentUpdateModelFilterInput,
    };

    const { data, error, loading } = useQuery(GET_SEGMENTS, {
        variables,
        fetchPolicy: 'network-only',
    });

    const [createSegment] = useMutation(CREATE_SEGMENT);

    const handleCreateSegment = (name: string) => {
        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                createSegment({
                    variables: {
                        segment: {
                            name,
                        } as CreateSegmentCommandInput,
                    },
                }).then((response) => {
                    try {
                        const segmentId = response.data.segmentCreate.segmentId;
                        navigate('/segment/' + segmentId);
                    } catch (e) {
                        alert('Something went wrong. Please try again.');
                    }
                })
            );
        });
    };

    const nextPage = segmentConnection?.pageInfo.hasNextPage
        ? () => {
              setPrev(false);
              setCursor(segmentConnection?.pageInfo.endCursor ?? undefined);
          }
        : undefined;

    const previousPage = segmentConnection?.pageInfo.hasPreviousPage
        ? () => {
              setPrev(true);
              setCursor(segmentConnection?.pageInfo.startCursor ?? undefined);
          }
        : undefined;

    useEffect(() => {
        if (!loading && !error) {
            let segmentsResult = data.segments as SegmentsConnection;
            if (segmentConnection !== segmentsResult) {
                setSegmentConnection(segmentsResult);
                setSegments(segmentsResult?.nodes as Segment[]);
            }
        }
    });

    return {
        models: {
            loading,
            page,
            keywords,
            segments,
        },
        operations: {
            setKeywords,
            nextPage,
            previousPage,
            handleCreateSegment,
        },
    };
};
