import { gql } from '@apollo/client';

export const GET_MEMBERSHIP = gql`
    query GetMembership($id: String!) {
        membership(id: $id) {
            membershipId
            memberId
            membershipType {
                membershipTypeId
                name
                description
                initialPrice
                yearlyPrice
            }
            startDate
            endDate
            membershipNumber
        }
    }
`;

export const GET_MEMBERSHIPTYPES = gql`
    {
        membershipTypes {
            membershipTypeId
            name
            description
        }
    }
`;
