import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_BROADCASTS } from '../../graphql/broadcast/queries';
import {
    Broadcast,
    BroadcastFilterInput,
    BroadcastsConnection,
    StringOperationFilterInput,
} from '../../graphql/__generated__/graphql';

export const useBroadcasts = () => {
    const [broadcastsConnection, setBroadcastsConnection] = useState<BroadcastsConnection>();
    const [broadcasts, setBroadcasts] = useState<Broadcast[]>();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [cursor, setCursor] = useState<string>();
    const [keywords, setKeywords] = useState<string>();
    const [expired, setExpired] = useState<string>();

    const [prev, setPrev] = useState(false);

    const variables = {
        take: pageSize,
        after: prev === true ? undefined : (cursor ?? undefined),
        before: prev === false ? undefined : (cursor ?? undefined),
        where: {
            or: [
                {
                    subject: keywords
                        ? ({ contains: keywords } as StringOperationFilterInput)
                        : undefined,
                },
                {
                    type: keywords
                        ? ({ contains: keywords } as StringOperationFilterInput)
                        : undefined,
                },
            ],
        } as BroadcastFilterInput,
    };

    const { data, error, loading, refetch } = useQuery(GET_BROADCASTS, { variables });

    const reload = () => refetch({ variables });

    const nextPage = broadcastsConnection?.pageInfo.hasNextPage
        ? () => {
              setPrev(false);
              setCursor(broadcastsConnection?.pageInfo.endCursor ?? undefined);
          }
        : undefined;

    const previousPage = broadcastsConnection?.pageInfo.hasPreviousPage
        ? () => {
              setPrev(true);
              setCursor(broadcastsConnection?.pageInfo.startCursor ?? undefined);
          }
        : undefined;

    useEffect(() => {
        if (!loading && !error) {
            let broadcastsResult = data.broadcasts as BroadcastsConnection;

            if (broadcastsConnection !== broadcastsResult) {
                console.log('Broadcasts loaded', broadcastsResult);

                setBroadcastsConnection(broadcastsResult);
                setBroadcasts(broadcastsResult?.nodes as Broadcast[]);
            }
        }
    });

    return {
        models: {
            loading,
            page,
            keywords,
            broadcasts,
            expired,
        },
        operations: {
            setKeywords,
            setExpired,
            nextPage,
            previousPage,
            reload,
        },
    };
};
