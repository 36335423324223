import { gql } from '@apollo/client';
import { membershipGQL } from '../gql/membershipGQL';

export const CREATE_MEMBERSHIP = gql`
  mutation membershipCreateMutation($membership:CreateMembershipCommandInput) {
    membershipCreate(membership: $membership) {
       ${membershipGQL}
    }
  }
`;

export const UPDATE_MEMBERSHIP = gql`
  mutation membershipCreateMutation($membership:UpdateMembershipCommandInput) {
    membershipUpdate(membership: $membership) {       
       ${membershipGQL}
    }
  }
`;

export const TEST_PAYMENT = gql`
    mutation membershipPaymentMutation($notification: PaymentTakenNotificationInput) {
        membershipPayment(notification: $notification)
    }
`;
