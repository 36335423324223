import React from 'react';
import { Button, ButtonGroup, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { IEditableLinksProps } from './IEventLinksModalProps';
import { useEventLinks } from './useEventLinks';

export const EditableLinks: React.FC<IEditableLinksProps> = ({ name, value, onUpdate }) => {
    const { models, operations } = useEventLinks({ value, onUpdate });
    const { show, loading, conferenceUrl, surveyUrl } = models;
    const { setConferenceUrl, setSurveyUrl, handleOpen, handleClose, handleSave } = operations;

    return (
        <>
            <Button
                variant={value[0] ? 'info' : 'outline-danger'}
                size="sm"
                onClick={() => handleOpen()}
                title="Event Links"
            >
                <i className="bi bi-link-45deg"></i>
            </Button>

            <Modal show={show} onHide={handleClose} data-testid="member-editablename-modal">
                {/*<Modal.Header>*/}
                {/*    <span>Updating Links for <b>{name}</b></span>*/}
                {/*</Modal.Header>*/}
                <Modal.Header closeButton>
                    <Modal.Title>Links</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form.Group>
                            <Form.Label>Conference Url</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    required
                                    name="conference"
                                    value={conferenceUrl}
                                    onChange={(e) => setConferenceUrl(e.target.value)}
                                />
                            </InputGroup>
                            <Form.Text>
                                <p>
                                    This is the link that participants will use to join the event
                                    when it starts
                                </p>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Survey Url</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    required
                                    name="survey"
                                    value={surveyUrl}
                                    onChange={(e) => setSurveyUrl(e.target.value)}
                                />
                            </InputGroup>
                            <Form.Text>
                                <p>
                                    This is the link that participants will use to complete the
                                    survey
                                </p>
                            </Form.Text>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <ButtonGroup>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSave} disabled={loading}>
                            Update
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditableLinks;
