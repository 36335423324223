import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { DELETE_NOTE, UPDATE_NOTE } from '../../../graphql/note/mutations';
import {
    DeleteNoteCommandInput,
    UpdateNoteCommandInput,
} from '../../../graphql/__generated__/graphql';
import useDocuments from '../../../hooks/useDocuments';
import { UpdateNoteModalProps } from './UpdateNoteModalProps';

export const useUpdateNote = ({ ...props }) => {
    const { error } = useDocuments();

    const [note, setNote] = useState<string>(props.note.value);

    const [isValid, setIsValid] = useState<boolean>(false);

    const [updateNote] = useMutation(UPDATE_NOTE);
    const [deleteNote] = useMutation(DELETE_NOTE);

    const { onClose } = { ...props } as UpdateNoteModalProps;

    const [show, setShow] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleClose = () => {
        setIsValid(false);
        onClose();
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleSave = (deleteNote: Boolean) => {
        if (deleteNote === true) handleDelete().then(() => handleClose());
        else handleUpdate().then(() => handleClose());
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let newNote = event.target.value;

        setNote(newNote);
        setIsValid(newNote.length > 0);
    };

    const handleConfirmDelete = () => {
        setConfirmDelete(!confirmDelete);
    };

    const handleUpdate = () => {
        return new Promise(async (resolve) => {
            if (note.length > 0) {
                await updateNote({
                    variables: {
                        note: {
                            noteId: props.note.noteId,
                            value: note,
                        } as UpdateNoteCommandInput,
                    },
                });

                resolve(true);
            }
        });
    };

    const handleDelete = () => {
        return new Promise(async (resolve) => {
            if (note.length > 0) {
                await deleteNote({
                    variables: {
                        note: {
                            noteId: props.note.noteId,
                        } as DeleteNoteCommandInput,
                    },
                });

                resolve(true);
            }
        });
    };

    useEffect(() => {
        if (show) setNote(props.note.value);
    }, [show]);

    return {
        models: {
            note,
            show,
            error,
            isValid,
            confirmDelete,
        },
        operations: {
            handleShow,
            handleSave,
            handleClose,
            setNote,
            handleChange,
            handleConfirmDelete,
        },
    };
};
