import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { MemberDto } from '../../graphql/__generated__/graphql';
import MemberSectionContainer from '../MemberSectionContainer';

const MemberContactSection: React.FC<{ member: MemberDto }> = ({ member }) => {
    const ensureProtocol = (url: string) => {
        if (/^https?:\/\//i.test(url)) {
            return url;
        } else {
            return `https://${url}`;
        }
    };

    return (
        <MemberSectionContainer title="Contact">
            {member.organisation && (
                <Card className="mb-2">
                    <Card.Body>
                        <Row>
                            <Col xs="auto">
                                <i className="bi bi-briefcase me-2"></i>
                            </Col>
                            <Col>
                                <div>
                                    <h5>{member.organisation}</h5>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}

            {member.website && (
                <Card className="mb-2">
                    <Card.Body>
                        <Row>
                            <Col xs="auto">
                                <i className="bi bi-link-45deg me-2"></i>
                            </Col>
                            <Col>
                                <div>
                                    <a
                                        href={ensureProtocol(member.website)}
                                        target="_blank"
                                        className="m-0"
                                        rel="noreferrer"
                                    >
                                        {member.website}
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}

            {member.email && (
                <Card className="mb-2">
                    <Card.Body>
                        <Row>
                            <Col xs="auto">
                                <i className="bi bi-envelope me-2"></i>
                            </Col>
                            <Col>
                                <div>
                                    <a
                                        href={'mailto:' + member.email}
                                        target="_blank"
                                        className="m-0"
                                        rel="noreferrer"
                                    >
                                        {member.email}
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}

            {member.telephones?.map((telephone, index) => (
                <Card key={index} className="mb-2">
                    <Card.Body>
                        {telephone && (
                            <Row key={telephone.telephoneId}>
                                <Col xs="auto">
                                    <i className="bi bi-telephone"></i>
                                </Col>
                                <Col>
                                    <div>
                                        <a
                                            href={'tel:' + telephone.number}
                                            target="_blank"
                                            className="m-0"
                                            rel="noreferrer"
                                        >
                                            {telephone.number}
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Card.Body>
                </Card>
            ))}

            {member.addresses?.map((address, index) => (
                <Card key={index}>
                    <Card.Body>
                        {address && (
                            <Row>
                                <Col xs="auto">
                                    <i className="bi bi-house"></i>
                                </Col>
                                <Col>
                                    <div>
                                        <p className="m-0">{address.addressLine1}</p>
                                        <p className="m-0">{address.addressLine2}</p>
                                        <p className="m-0">{address.city}</p>
                                        <p className="m-0">{address.postcode}</p>
                                        <p className="m-0">{address.country}</p>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Card.Body>
                </Card>
            ))}
        </MemberSectionContainer>
    );
};

export default MemberContactSection;
