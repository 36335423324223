/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type Accreditation = {
  __typename?: 'Accreditation';
  accreditationApplication?: Maybe<AccreditationApplication>;
  accreditationApplicationId?: Maybe<Scalars['UUID']['output']>;
  accreditationId: Scalars['UUID']['output'];
  accreditationType?: Maybe<AccreditationType>;
  accreditationTypeId: Scalars['UUID']['output'];
  awardedDate?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  member?: Maybe<Member>;
  memberId: Scalars['UUID']['output'];
  paymentCompleteDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type AccreditationApplication = {
  __typename?: 'AccreditationApplication';
  accreditationApplicationId: Scalars['UUID']['output'];
  accreditationType?: Maybe<AccreditationType>;
  accreditationTypeId: Scalars['UUID']['output'];
  applicationStatus: ApplicationStatus;
  billingRequestJson?: Maybe<Scalars['String']['output']>;
  hasMitigation: Scalars['Boolean']['output'];
  jsonData?: Maybe<Scalars['String']['output']>;
  lockedBy: LockOwner;
  member?: Maybe<Member>;
  memberId: Scalars['UUID']['output'];
  reviews?: Maybe<Array<Maybe<ApplicationReview>>>;
  updatedDate: Scalars['DateTime']['output'];
};

export type AccreditationApplicationFilterInput = {
  accreditationApplicationId?: InputMaybe<UuidOperationFilterInput>;
  accreditationType?: InputMaybe<AccreditationTypeFilterInput>;
  accreditationTypeId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<AccreditationApplicationFilterInput>>;
  applicationStatus?: InputMaybe<ApplicationStatusOperationFilterInput>;
  billingRequestJson?: InputMaybe<StringOperationFilterInput>;
  hasMitigation?: InputMaybe<BooleanOperationFilterInput>;
  jsonData?: InputMaybe<StringOperationFilterInput>;
  lockedBy?: InputMaybe<LockOwnerOperationFilterInput>;
  member?: InputMaybe<MemberFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AccreditationApplicationFilterInput>>;
  reviews?: InputMaybe<ListFilterInputTypeOfApplicationReviewFilterInput>;
  updatedDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type AccreditationApplicationItem = {
  __typename?: 'AccreditationApplicationItem';
  accreditationApplicationId: Scalars['UUID']['output'];
  accreditationType: AccreditationType;
  applicationStatus: ApplicationStatus;
  hasMitigation: Scalars['Boolean']['output'];
  isPaid: Scalars['Boolean']['output'];
  jsonData: Scalars['String']['output'];
  lockedBy: LockOwner;
  member: MemberItem;
  reviews: Array<ApplicationReviewSummaryItem>;
  updatedDate: Scalars['DateTime']['output'];
};

export type AccreditationFilterInput = {
  accreditationApplication?: InputMaybe<AccreditationApplicationFilterInput>;
  accreditationApplicationId?: InputMaybe<UuidOperationFilterInput>;
  accreditationId?: InputMaybe<UuidOperationFilterInput>;
  accreditationType?: InputMaybe<AccreditationTypeFilterInput>;
  accreditationTypeId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<AccreditationFilterInput>>;
  awardedDate?: InputMaybe<DateTimeOperationFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  member?: InputMaybe<MemberFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AccreditationFilterInput>>;
  paymentCompleteDate?: InputMaybe<DateTimeOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type AccreditationType = {
  __typename?: 'AccreditationType';
  accreditationTypeId: Scalars['UUID']['output'];
  active: Scalars['Boolean']['output'];
  createdDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  requirementsJson?: Maybe<Scalars['String']['output']>;
  sortKey: Scalars['Int']['output'];
  supervisor: Scalars['Boolean']['output'];
};

export type AccreditationTypeFilterInput = {
  accreditationTypeId?: InputMaybe<UuidOperationFilterInput>;
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<AccreditationTypeFilterInput>>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccreditationTypeFilterInput>>;
  price?: InputMaybe<FloatOperationFilterInput>;
  requirementsJson?: InputMaybe<StringOperationFilterInput>;
  sortKey?: InputMaybe<IntOperationFilterInput>;
  supervisor?: InputMaybe<BooleanOperationFilterInput>;
};

/** A connection to a list of items. */
export type AccreditationTypesConnection = {
  __typename?: 'AccreditationTypesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AccreditationTypesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AccreditationType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccreditationTypesEdge = {
  __typename?: 'AccreditationTypesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AccreditationType;
};

export type Address = {
  __typename?: 'Address';
  addressId: Scalars['UUID']['output'];
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  memberId: Scalars['UUID']['output'];
  postcode: Scalars['String']['output'];
  town?: Maybe<Scalars['String']['output']>;
};

export type AddressFilterInput = {
  addressId?: InputMaybe<UuidOperationFilterInput>;
  addressLine1?: InputMaybe<StringOperationFilterInput>;
  addressLine2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AddressFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  postcode?: InputMaybe<StringOperationFilterInput>;
  town?: InputMaybe<StringOperationFilterInput>;
};

export type ApplicationReview = {
  __typename?: 'ApplicationReview';
  accreditationApplication?: Maybe<AccreditationApplication>;
  accreditationApplicationId: Scalars['UUID']['output'];
  applicationReviewId: Scalars['UUID']['output'];
  isSubmitted: Scalars['Boolean']['output'];
  jsonData?: Maybe<Scalars['String']['output']>;
  requestedDate: Scalars['DateTime']['output'];
  reviewStatus: ReviewStatus;
  reviewer?: Maybe<Member>;
  reviewerId: Scalars['UUID']['output'];
  reviewerRole: ReviewerRole;
  updatedDate: Scalars['DateTime']['output'];
};

export type ApplicationReviewFilterInput = {
  accreditationApplication?: InputMaybe<AccreditationApplicationFilterInput>;
  accreditationApplicationId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<ApplicationReviewFilterInput>>;
  applicationReviewId?: InputMaybe<UuidOperationFilterInput>;
  jsonData?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ApplicationReviewFilterInput>>;
  requestedDate?: InputMaybe<DateTimeOperationFilterInput>;
  reviewStatus?: InputMaybe<ReviewStatusOperationFilterInput>;
  reviewer?: InputMaybe<MemberFilterInput>;
  reviewerId?: InputMaybe<UuidOperationFilterInput>;
  reviewerRole?: InputMaybe<ReviewerRoleOperationFilterInput>;
  updatedDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ApplicationReviewSummaryItem = {
  __typename?: 'ApplicationReviewSummaryItem';
  accreditationApplicationId: Scalars['UUID']['output'];
  accreditationType?: Maybe<AccreditationType>;
  applicant?: Maybe<MemberItem>;
  applicationReviewId: Scalars['UUID']['output'];
  requestedDate: Scalars['DateTime']['output'];
  reviewStatus: ReviewStatus;
  reviewer?: Maybe<MemberItem>;
  reviewerRole: ReviewerRole;
  updatedDate: Scalars['DateTime']['output'];
};

export enum ApplicationStatus {
  Approved = 'APPROVED',
  InProgress = 'IN_PROGRESS',
  NeedsWork = 'NEEDS_WORK',
  ReadyForReview = 'READY_FOR_REVIEW',
  ReadyToSubmit = 'READY_TO_SUBMIT',
  Rejected = 'REJECTED'
}

export type ApplicationStatusOperationFilterInput = {
  eq?: InputMaybe<ApplicationStatus>;
  in?: InputMaybe<Array<ApplicationStatus>>;
  neq?: InputMaybe<ApplicationStatus>;
  nin?: InputMaybe<Array<ApplicationStatus>>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Broadcast = {
  __typename?: 'Broadcast';
  body: Scalars['String']['output'];
  broadcastId: Scalars['UUID']['output'];
  createdDate: Scalars['DateTime']['output'];
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['UUID']['output']>;
  recipients: Array<BroadcastRecipient>;
  segment?: Maybe<Segment>;
  segmentId?: Maybe<Scalars['UUID']['output']>;
  sentDate?: Maybe<Scalars['DateTime']['output']>;
  subject: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type BroadcastFilterInput = {
  and?: InputMaybe<Array<BroadcastFilterInput>>;
  body?: InputMaybe<StringOperationFilterInput>;
  broadcastId?: InputMaybe<UuidOperationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<BroadcastFilterInput>>;
  recipients?: InputMaybe<ListFilterInputTypeOfBroadcastRecipientFilterInput>;
  segment?: InputMaybe<SegmentFilterInput>;
  segmentId?: InputMaybe<UuidOperationFilterInput>;
  sentDate?: InputMaybe<DateTimeOperationFilterInput>;
  subject?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
};

export type BroadcastRecipient = {
  __typename?: 'BroadcastRecipient';
  broadcast: Broadcast;
  broadcastId: Scalars['UUID']['output'];
  broadcastRecipientId: Scalars['UUID']['output'];
  emailAddress: Scalars['String']['output'];
  failed: Scalars['Boolean']['output'];
  member: Member;
  memberId?: Maybe<Scalars['UUID']['output']>;
  readCount: Scalars['Int']['output'];
  sent: Scalars['Boolean']['output'];
};

export type BroadcastRecipientFilterInput = {
  and?: InputMaybe<Array<BroadcastRecipientFilterInput>>;
  broadcast?: InputMaybe<BroadcastFilterInput>;
  broadcastId?: InputMaybe<UuidOperationFilterInput>;
  broadcastRecipientId?: InputMaybe<UuidOperationFilterInput>;
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  failed?: InputMaybe<BooleanOperationFilterInput>;
  member?: InputMaybe<MemberFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<BroadcastRecipientFilterInput>>;
  readCount?: InputMaybe<IntOperationFilterInput>;
  sent?: InputMaybe<BooleanOperationFilterInput>;
};

export type BroadcastStatistic = {
  __typename?: 'BroadcastStatistic';
  date: Scalars['String']['output'];
  stats: Array<Statistics>;
};

/** A connection to a list of items. */
export type BroadcastsConnection = {
  __typename?: 'BroadcastsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BroadcastsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Broadcast>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BroadcastsEdge = {
  __typename?: 'BroadcastsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Broadcast;
};

export type BulkUpdateAccreditationApplicationStatusCommandInput = {
  accreditationApplicationIds: Array<Scalars['UUID']['input']>;
  applicationStatus: ApplicationStatus;
};

export type CountryItem = {
  __typename?: 'CountryItem';
  countryId: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type CreateAccreditationCommandInput = {
  accreditationApplicationId?: InputMaybe<Scalars['UUID']['input']>;
  accreditationTypeId: Scalars['UUID']['input'];
  duration?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['UUID']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateAccreditationTypeCommandInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAddressCommandInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  default: Scalars['Boolean']['input'];
  postcode: Scalars['String']['input'];
  town?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBroadcastCommandInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  broadcastId?: InputMaybe<Scalars['UUID']['input']>;
  draft: Scalars['Boolean']['input'];
  eventId?: InputMaybe<Scalars['String']['input']>;
  memberIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEventCommandInput = {
  eventId?: InputMaybe<Scalars['UUID']['input']>;
  memberIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['Long']['input'];
};

export type CreateEventParticipantCommandInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  purchaseResult?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export type CreateMemberCommandInput = {
  advertisingOptIn: Scalars['Boolean']['input'];
  childTrainingLevel?: InputMaybe<Scalars['String']['input']>;
  childTrainingLevelDateAchieved?: InputMaybe<Scalars['DateTime']['input']>;
  declarationAgreed: Scalars['Boolean']['input'];
  defaultAddress?: InputMaybe<CreateAddressCommandInput>;
  defaultTelephone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emdrTrainingLevel?: InputMaybe<MemberEmdrTrainingLevelInput>;
  emdrTrainingLevelDateAchieved?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  membershipTypeId: Scalars['UUID']['input'];
  newsOptIn: Scalars['Boolean']['input'];
  professionalRegistrations?: InputMaybe<Array<InputMaybe<MemberProfessionalRegistrationInput>>>;
  professions?: InputMaybe<Array<InputMaybe<MemberProfessionInput>>>;
  qualifications?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<MemberRegionInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userAccount?: InputMaybe<UserAccountInput>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMembershipCommandInput = {
  memberId: Scalars['UUID']['input'];
  membershipTypeId: Scalars['UUID']['input'];
};

export type CreateNoteCommandInput = {
  memberId: Scalars['UUID']['input'];
  noteId?: InputMaybe<Scalars['UUID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSegmentCommandInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteAccreditationTypeCommandInput = {
  accreditationTypeId: Scalars['UUID']['input'];
};

export type DeleteEventParticipantCommandInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteNoteCommandInput = {
  noteId?: InputMaybe<Scalars['UUID']['input']>;
};

export type DeleteSegmentCommandInput = {
  segmentId?: InputMaybe<Scalars['String']['input']>;
};

export type Document = {
  __typename?: 'Document';
  cpd?: Maybe<Scalars['Int']['output']>;
  cpdReceived?: Maybe<Scalars['Boolean']['output']>;
  cpdRejected?: Maybe<Scalars['Boolean']['output']>;
  cpdRequested?: Maybe<Scalars['Boolean']['output']>;
  createdBy: Scalars['UUID']['output'];
  createdDate: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documentId: Scalars['UUID']['output'];
  eventId?: Maybe<Scalars['UUID']['output']>;
  expiries?: Maybe<Scalars['DateTime']['output']>;
  filename: Scalars['String']['output'];
  isCACPD?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedBy?: Maybe<Scalars['UUID']['output']>;
  lastModifiedDate?: Maybe<Scalars['DateTime']['output']>;
  member: Member;
  memberId?: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
};

export type DocumentFilterInput = {
  and?: InputMaybe<Array<DocumentFilterInput>>;
  cpd?: InputMaybe<IntOperationFilterInput>;
  cpdReceived?: InputMaybe<BooleanOperationFilterInput>;
  cpdRejected?: InputMaybe<BooleanOperationFilterInput>;
  cpdRequested?: InputMaybe<BooleanOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  documentId?: InputMaybe<UuidOperationFilterInput>;
  eventId?: InputMaybe<UuidOperationFilterInput>;
  expiries?: InputMaybe<DateTimeOperationFilterInput>;
  filename?: InputMaybe<StringOperationFilterInput>;
  isCACPD?: InputMaybe<BooleanOperationFilterInput>;
  lastModifiedBy?: InputMaybe<UuidOperationFilterInput>;
  lastModifiedDate?: InputMaybe<DateTimeOperationFilterInput>;
  member?: InputMaybe<MemberFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DocumentFilterInput>>;
};

export type EmdrTrainingLevel = {
  __typename?: 'EMDRTrainingLevel';
  emdrTrainingLevelId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  sortKey: Scalars['Int']['output'];
};

export type EmdrTrainingLevelFilterInput = {
  and?: InputMaybe<Array<EmdrTrainingLevelFilterInput>>;
  emdrTrainingLevelId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EmdrTrainingLevelFilterInput>>;
  sortKey?: InputMaybe<IntOperationFilterInput>;
};

export type EmdrTrainingLevelItem = {
  __typename?: 'EMDRTrainingLevelItem';
  emdrTrainingLevelId: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Event = {
  __typename?: 'Event';
  cancelled: Scalars['Boolean']['output'];
  capacity?: Maybe<Scalars['Int']['output']>;
  certificateIssued: Scalars['Boolean']['output'];
  certificateTemplateHtml?: Maybe<Scalars['String']['output']>;
  childAndAdolescent: Scalars['Boolean']['output'];
  cpd: Scalars['Int']['output'];
  createdDate: Scalars['DateTime']['output'];
  documents?: Maybe<Array<Maybe<Document>>>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  eventHref?: Maybe<Scalars['String']['output']>;
  eventId: Scalars['UUID']['output'];
  eventPostHref?: Maybe<Scalars['String']['output']>;
  eventSurveyHref?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  memberPrice: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nonMemberPrice: Scalars['Float']['output'];
  participants?: Maybe<Array<Maybe<EventParticipant>>>;
  postId: Scalars['Long']['output'];
  startDate: Scalars['DateTime']['output'];
  videos?: Maybe<Array<Maybe<Video>>>;
};

export type EventFilterInput = {
  and?: InputMaybe<Array<EventFilterInput>>;
  cancelled?: InputMaybe<BooleanOperationFilterInput>;
  capacity?: InputMaybe<IntOperationFilterInput>;
  certificateIssued?: InputMaybe<BooleanOperationFilterInput>;
  certificateTemplateHtml?: InputMaybe<StringOperationFilterInput>;
  childAndAdolescent?: InputMaybe<BooleanOperationFilterInput>;
  cpd?: InputMaybe<IntOperationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  eventHref?: InputMaybe<StringOperationFilterInput>;
  eventId?: InputMaybe<UuidOperationFilterInput>;
  eventPostHref?: InputMaybe<StringOperationFilterInput>;
  eventSurveyHref?: InputMaybe<StringOperationFilterInput>;
  expiryDate?: InputMaybe<DateTimeOperationFilterInput>;
  memberPrice?: InputMaybe<FloatOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nonMemberPrice?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<EventFilterInput>>;
  participants?: InputMaybe<ListFilterInputTypeOfEventParticipantFilterInput>;
  postId?: InputMaybe<LongOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  videos?: InputMaybe<ListFilterInputTypeOfVideoFilterInput>;
};

export type EventParticipant = {
  __typename?: 'EventParticipant';
  cancelled: Scalars['Boolean']['output'];
  certificateIssued?: Maybe<Scalars['DateTime']['output']>;
  createdDate: Scalars['DateTime']['output'];
  emailAddress: Scalars['String']['output'];
  event: Event;
  eventId: Scalars['UUID']['output'];
  eventParticipantId: Scalars['UUID']['output'];
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['UUID']['output']>;
  purchaseQuantity: Scalars['Int']['output'];
  purchaseResult: Scalars['String']['output'];
  surveyOpened?: Maybe<Scalars['DateTime']['output']>;
};

export type EventParticipantFilterInput = {
  and?: InputMaybe<Array<EventParticipantFilterInput>>;
  cancelled?: InputMaybe<BooleanOperationFilterInput>;
  certificateIssued?: InputMaybe<DateTimeOperationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<UuidOperationFilterInput>;
  eventParticipantId?: InputMaybe<UuidOperationFilterInput>;
  member?: InputMaybe<MemberFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<EventParticipantFilterInput>>;
  purchaseQuantity?: InputMaybe<IntOperationFilterInput>;
  purchaseResult?: InputMaybe<StringOperationFilterInput>;
  surveyOpened?: InputMaybe<DateTimeOperationFilterInput>;
};

/** A connection to a list of items. */
export type EventsByMemberIdConnection = {
  __typename?: 'EventsByMemberIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EventsByMemberIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Event>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EventsByMemberIdEdge = {
  __typename?: 'EventsByMemberIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Event;
};

/** A connection to a list of items. */
export type EventsConnection = {
  __typename?: 'EventsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EventsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Event>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Event;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFilterInputTypeOfAccreditationApplicationFilterInput = {
  all?: InputMaybe<AccreditationApplicationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccreditationApplicationFilterInput>;
  some?: InputMaybe<AccreditationApplicationFilterInput>;
};

export type ListFilterInputTypeOfAccreditationFilterInput = {
  all?: InputMaybe<AccreditationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccreditationFilterInput>;
  some?: InputMaybe<AccreditationFilterInput>;
};

export type ListFilterInputTypeOfAddressFilterInput = {
  all?: InputMaybe<AddressFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AddressFilterInput>;
  some?: InputMaybe<AddressFilterInput>;
};

export type ListFilterInputTypeOfApplicationReviewFilterInput = {
  all?: InputMaybe<ApplicationReviewFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ApplicationReviewFilterInput>;
  some?: InputMaybe<ApplicationReviewFilterInput>;
};

export type ListFilterInputTypeOfBroadcastRecipientFilterInput = {
  all?: InputMaybe<BroadcastRecipientFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<BroadcastRecipientFilterInput>;
  some?: InputMaybe<BroadcastRecipientFilterInput>;
};

export type ListFilterInputTypeOfDocumentFilterInput = {
  all?: InputMaybe<DocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DocumentFilterInput>;
  some?: InputMaybe<DocumentFilterInput>;
};

export type ListFilterInputTypeOfEventParticipantFilterInput = {
  all?: InputMaybe<EventParticipantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EventParticipantFilterInput>;
  some?: InputMaybe<EventParticipantFilterInput>;
};

export type ListFilterInputTypeOfMemberCommunicationPreferencesFilterInput = {
  all?: InputMaybe<MemberCommunicationPreferencesFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MemberCommunicationPreferencesFilterInput>;
  some?: InputMaybe<MemberCommunicationPreferencesFilterInput>;
};

export type ListFilterInputTypeOfMemberFilterInput = {
  all?: InputMaybe<MemberFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MemberFilterInput>;
  some?: InputMaybe<MemberFilterInput>;
};

export type ListFilterInputTypeOfMembershipFilterInput = {
  all?: InputMaybe<MembershipFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MembershipFilterInput>;
  some?: InputMaybe<MembershipFilterInput>;
};

export type ListFilterInputTypeOfNoteFilterInput = {
  all?: InputMaybe<NoteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NoteFilterInput>;
  some?: InputMaybe<NoteFilterInput>;
};

export type ListFilterInputTypeOfProfessionFilterInput = {
  all?: InputMaybe<ProfessionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProfessionFilterInput>;
  some?: InputMaybe<ProfessionFilterInput>;
};

export type ListFilterInputTypeOfProfessionalRegistrationFilterInput = {
  all?: InputMaybe<ProfessionalRegistrationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProfessionalRegistrationFilterInput>;
  some?: InputMaybe<ProfessionalRegistrationFilterInput>;
};

export type ListFilterInputTypeOfSegmentAndFilterInput = {
  all?: InputMaybe<SegmentAndFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SegmentAndFilterInput>;
  some?: InputMaybe<SegmentAndFilterInput>;
};

export type ListFilterInputTypeOfSegmentOrFilterInput = {
  all?: InputMaybe<SegmentOrFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SegmentOrFilterInput>;
  some?: InputMaybe<SegmentOrFilterInput>;
};

export type ListFilterInputTypeOfTelephoneFilterInput = {
  all?: InputMaybe<TelephoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TelephoneFilterInput>;
  some?: InputMaybe<TelephoneFilterInput>;
};

export type ListFilterInputTypeOfTransactionFilterInput = {
  all?: InputMaybe<TransactionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TransactionFilterInput>;
  some?: InputMaybe<TransactionFilterInput>;
};

export type ListFilterInputTypeOfVideoFilterInput = {
  all?: InputMaybe<VideoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<VideoFilterInput>;
  some?: InputMaybe<VideoFilterInput>;
};

export enum LockOwner {
  Applicant = 'APPLICANT',
  Emdr = 'EMDR',
  Supervisor = 'SUPERVISOR'
}

export type LockOwnerOperationFilterInput = {
  eq?: InputMaybe<LockOwner>;
  in?: InputMaybe<Array<LockOwner>>;
  neq?: InputMaybe<LockOwner>;
  nin?: InputMaybe<Array<LockOwner>>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type Member = {
  __typename?: 'Member';
  accreditationApplications: Array<AccreditationApplication>;
  accreditations: Array<Accreditation>;
  addresses: Array<Address>;
  advertisingOptIn: Scalars['Boolean']['output'];
  avatar?: Maybe<MemberAvatar>;
  broadcasts: Array<BroadcastRecipient>;
  childTrainingLevel?: Maybe<Scalars['String']['output']>;
  childTrainingLevelDateAchieved?: Maybe<Scalars['DateTime']['output']>;
  communicationPreferences: Array<MemberCommunicationPreferences>;
  customerId?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateUpdated: Scalars['DateTime']['output'];
  declarationAgreed: Scalars['Boolean']['output'];
  documents: Array<Document>;
  email?: Maybe<Scalars['String']['output']>;
  emdrTrainingLevel: EmdrTrainingLevel;
  emdrTrainingLevelDateAchieved?: Maybe<Scalars['DateTime']['output']>;
  emdrTrainingLevelId?: Maybe<Scalars['UUID']['output']>;
  events: Array<EventParticipant>;
  firstname: Scalars['String']['output'];
  hasActiveMembership: Scalars['Boolean']['output'];
  languages?: Maybe<Scalars['String']['output']>;
  lastname: Scalars['String']['output'];
  memberId: Scalars['UUID']['output'];
  membershipNumber: Scalars['Long']['output'];
  memberships: Array<Membership>;
  newsOptIn: Scalars['Boolean']['output'];
  notes: Array<Note>;
  organisation?: Maybe<Scalars['String']['output']>;
  professionalRegistrations: Array<ProfessionalRegistration>;
  professions: Array<Profession>;
  qualifications?: Maybe<Scalars['String']['output']>;
  region: Region;
  regionId?: Maybe<Scalars['UUID']['output']>;
  telephones: Array<Telephone>;
  title?: Maybe<Scalars['String']['output']>;
  transactions: Array<Transaction>;
  userId?: Maybe<Scalars['UUID']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type MemberAccreditation = {
  __typename?: 'MemberAccreditation';
  accreditationType?: Maybe<AccreditationType>;
  active: Scalars['Boolean']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type MemberAccreditationApplication = {
  __typename?: 'MemberAccreditationApplication';
  accreditationType?: Maybe<AccreditationType>;
  applicationStatus: ApplicationStatus;
  id?: Maybe<Scalars['UUID']['output']>;
  jsonData?: Maybe<Scalars['String']['output']>;
};

export type MemberAvatar = {
  __typename?: 'MemberAvatar';
  data: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  member: Member;
  memberId: Scalars['UUID']['output'];
};

export type MemberAvatarFilterInput = {
  and?: InputMaybe<Array<MemberAvatarFilterInput>>;
  data?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  member?: InputMaybe<MemberFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MemberAvatarFilterInput>>;
};

export type MemberCommunicationPreferenceDto = {
  __typename?: 'MemberCommunicationPreferenceDTO';
  enabled: Scalars['Boolean']['output'];
  memberId: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type MemberCommunicationPreferenceDtoInput = {
  enabled: Scalars['Boolean']['input'];
  memberId: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MemberCommunicationPreferences = {
  __typename?: 'MemberCommunicationPreferences';
  communicationType: Scalars['String']['output'];
  createdBy: Scalars['UUID']['output'];
  createdDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  member: Member;
  memberId: Scalars['UUID']['output'];
  updatedBy?: Maybe<Scalars['UUID']['output']>;
  updatedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type MemberCommunicationPreferencesFilterInput = {
  and?: InputMaybe<Array<MemberCommunicationPreferencesFilterInput>>;
  communicationType?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  member?: InputMaybe<MemberFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MemberCommunicationPreferencesFilterInput>>;
  updatedBy?: InputMaybe<UuidOperationFilterInput>;
  updatedDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type MemberDto = {
  __typename?: 'MemberDTO';
  accreditations?: Maybe<Array<Accreditation>>;
  addresses?: Maybe<Array<Address>>;
  advertisingOptIn: Scalars['Boolean']['output'];
  avatar?: Maybe<MemberAvatar>;
  childTrainingLevel?: Maybe<Scalars['String']['output']>;
  childTrainingLevelDateAchieved?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateUpdated: Scalars['DateTime']['output'];
  declarationAgreed: Scalars['Boolean']['output'];
  documents?: Maybe<Array<Document>>;
  email?: Maybe<Scalars['String']['output']>;
  emdrTrainingLevel?: Maybe<EmdrTrainingLevel>;
  emdrTrainingLevelDateAchieved?: Maybe<Scalars['DateTime']['output']>;
  emdrTrainingLevelId?: Maybe<Scalars['UUID']['output']>;
  events?: Maybe<Array<EventParticipant>>;
  firstname?: Maybe<Scalars['String']['output']>;
  hasActiveMembership: Scalars['Boolean']['output'];
  languages?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['UUID']['output'];
  membershipNumber: Scalars['Long']['output'];
  memberships?: Maybe<Array<Membership>>;
  newsOptIn: Scalars['Boolean']['output'];
  organisation?: Maybe<Scalars['String']['output']>;
  professionalRegistrations?: Maybe<Array<ProfessionalRegistration>>;
  professions?: Maybe<Array<Profession>>;
  qualifications?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['UUID']['output']>;
  telephones?: Maybe<Array<Telephone>>;
  title?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type MemberEmdrTrainingLevelInput = {
  emdrTrainingLevelId: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MemberFilterInput = {
  accreditationApplications?: InputMaybe<ListFilterInputTypeOfAccreditationApplicationFilterInput>;
  accreditations?: InputMaybe<ListFilterInputTypeOfAccreditationFilterInput>;
  addresses?: InputMaybe<ListFilterInputTypeOfAddressFilterInput>;
  advertisingOptIn?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<MemberFilterInput>>;
  avatar?: InputMaybe<MemberAvatarFilterInput>;
  broadcasts?: InputMaybe<ListFilterInputTypeOfBroadcastRecipientFilterInput>;
  childTrainingLevel?: InputMaybe<StringOperationFilterInput>;
  childTrainingLevelDateAchieved?: InputMaybe<DateTimeOperationFilterInput>;
  communicationPreferences?: InputMaybe<ListFilterInputTypeOfMemberCommunicationPreferencesFilterInput>;
  customerId?: InputMaybe<StringOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  declarationAgreed?: InputMaybe<BooleanOperationFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emdrTrainingLevel?: InputMaybe<EmdrTrainingLevelFilterInput>;
  emdrTrainingLevelDateAchieved?: InputMaybe<DateTimeOperationFilterInput>;
  emdrTrainingLevelId?: InputMaybe<UuidOperationFilterInput>;
  events?: InputMaybe<ListFilterInputTypeOfEventParticipantFilterInput>;
  firstname?: InputMaybe<StringOperationFilterInput>;
  hasActiveMembership?: InputMaybe<BooleanOperationFilterInput>;
  languages?: InputMaybe<StringOperationFilterInput>;
  lastname?: InputMaybe<StringOperationFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  membershipNumber?: InputMaybe<LongOperationFilterInput>;
  memberships?: InputMaybe<ListFilterInputTypeOfMembershipFilterInput>;
  newsOptIn?: InputMaybe<BooleanOperationFilterInput>;
  notes?: InputMaybe<ListFilterInputTypeOfNoteFilterInput>;
  or?: InputMaybe<Array<MemberFilterInput>>;
  organisation?: InputMaybe<StringOperationFilterInput>;
  professionalRegistrations?: InputMaybe<ListFilterInputTypeOfProfessionalRegistrationFilterInput>;
  professions?: InputMaybe<ListFilterInputTypeOfProfessionFilterInput>;
  qualifications?: InputMaybe<StringOperationFilterInput>;
  region?: InputMaybe<RegionFilterInput>;
  regionId?: InputMaybe<UuidOperationFilterInput>;
  telephones?: InputMaybe<ListFilterInputTypeOfTelephoneFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  transactions?: InputMaybe<ListFilterInputTypeOfTransactionFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
  website?: InputMaybe<StringOperationFilterInput>;
};

export type MemberItem = {
  __typename?: 'MemberItem';
  email?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type MemberList = {
  __typename?: 'MemberList';
  members: Array<MemberDto>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type MemberProfessionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  professionId: Scalars['UUID']['input'];
};

export type MemberProfessionalRegistrationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  professionalRegistrationId: Scalars['UUID']['input'];
};

export type MemberProfile = {
  __typename?: 'MemberProfile';
  accreditationApplications?: Maybe<Array<Maybe<MemberAccreditationApplication>>>;
  activeAccreditations?: Maybe<Array<Maybe<MemberAccreditation>>>;
  address?: Maybe<MemberProfileAddress>;
  avatar?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emdrChildLevel?: Maybe<Scalars['String']['output']>;
  emdrChildLevelDate?: Maybe<Scalars['String']['output']>;
  emdrLevel?: Maybe<Scalars['String']['output']>;
  emdrLevelDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  membershipIsExpired?: Maybe<Scalars['Boolean']['output']>;
  membershipNumber?: Maybe<Scalars['Long']['output']>;
  membershipRenews?: Maybe<Scalars['DateTime']['output']>;
  membershipType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organisation?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  profession?: Maybe<Scalars['String']['output']>;
  professionalRegistration?: Maybe<Scalars['String']['output']>;
  qualifications?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type MemberProfileAddress = {
  __typename?: 'MemberProfileAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionId: Scalars['UUID']['output'];
};

export type MemberRegionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  regionId: Scalars['UUID']['input'];
};

export type Membership = {
  __typename?: 'Membership';
  billingRequestJson: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  mandateId: Scalars['String']['output'];
  memberId: Scalars['UUID']['output'];
  membershipId: Scalars['UUID']['output'];
  membershipNumber: Scalars['Long']['output'];
  membershipType: MembershipType;
  membershipTypeId: Scalars['UUID']['output'];
  startDate: Scalars['DateTime']['output'];
  subscriptionId: Scalars['String']['output'];
};

export type MembershipFilterInput = {
  and?: InputMaybe<Array<MembershipFilterInput>>;
  billingRequestJson?: InputMaybe<StringOperationFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  mandateId?: InputMaybe<StringOperationFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  membershipId?: InputMaybe<UuidOperationFilterInput>;
  membershipNumber?: InputMaybe<LongOperationFilterInput>;
  membershipType?: InputMaybe<MembershipTypeFilterInput>;
  membershipTypeId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MembershipFilterInput>>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  subscriptionId?: InputMaybe<StringOperationFilterInput>;
};

export type MembershipType = {
  __typename?: 'MembershipType';
  description: Scalars['String']['output'];
  initialPrice?: Maybe<Scalars['Float']['output']>;
  membershipTypeId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  sortKey: Scalars['Int']['output'];
  yearlyPrice?: Maybe<Scalars['Float']['output']>;
};

export type MembershipTypeFilterInput = {
  and?: InputMaybe<Array<MembershipTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  initialPrice?: InputMaybe<FloatOperationFilterInput>;
  membershipTypeId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MembershipTypeFilterInput>>;
  sortKey?: InputMaybe<IntOperationFilterInput>;
  yearlyPrice?: InputMaybe<FloatOperationFilterInput>;
};

export type MembershipTypeItem = {
  __typename?: 'MembershipTypeItem';
  description?: Maybe<Scalars['String']['output']>;
  membershipTypeId: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Metrics = {
  __typename?: 'Metrics';
  blocks: Scalars['Int']['output'];
  bounceDrops: Scalars['Int']['output'];
  bounces: Scalars['Int']['output'];
  clicks: Scalars['Int']['output'];
  deferred: Scalars['Int']['output'];
  delivered: Scalars['Int']['output'];
  invalidEmails: Scalars['Int']['output'];
  opens: Scalars['Int']['output'];
  processed: Scalars['Int']['output'];
  requests: Scalars['Int']['output'];
  spamReportDrops: Scalars['Int']['output'];
  spamReports: Scalars['Int']['output'];
  uniqueClicks: Scalars['Int']['output'];
  uniqueOpens: Scalars['Int']['output'];
  unsubscribeDrops: Scalars['Int']['output'];
  unsubscribes: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accreditationApplicationBulkStatusUpdate: Array<AccreditationApplicationItem>;
  accreditationApplicationUpdate: AccreditationApplication;
  accreditationCreate: Accreditation;
  accreditationTypeCreate: AccreditationType;
  accreditationTypeDelete: AccreditationType;
  accreditationTypeUpdate: AccreditationType;
  accreditationUpdate: Accreditation;
  broadcastCreate: Broadcast;
  eventCreate: Event;
  eventParticipantCreate: EventParticipant;
  eventParticipantDelete: EventParticipant;
  eventUpdateCertificateTemplate: Event;
  eventUpdateExpiryDate: Event;
  eventUpdateLinks: Event;
  memberCommunicationPreferences: Array<MemberCommunicationPreferenceDto>;
  memberCreate: Member;
  memberUpdate: Member;
  memberUpdateDocumentApproval: Member;
  membershipCreate: Membership;
  membershipPayment: Scalars['Boolean']['output'];
  membershipUpdate: Membership;
  noteCreate: Note;
  noteDelete: Note;
  noteUpdate: Note;
  segmentCreate: Segment;
  segmentDelete: Segment;
  segmentUpdate: Segment;
};


export type MutationAccreditationApplicationBulkStatusUpdateArgs = {
  applications?: InputMaybe<BulkUpdateAccreditationApplicationStatusCommandInput>;
};


export type MutationAccreditationApplicationUpdateArgs = {
  application?: InputMaybe<UpdateAccreditationApplicationCommandInput>;
};


export type MutationAccreditationCreateArgs = {
  accreditation?: InputMaybe<CreateAccreditationCommandInput>;
};


export type MutationAccreditationTypeCreateArgs = {
  accreditation?: InputMaybe<CreateAccreditationTypeCommandInput>;
};


export type MutationAccreditationTypeDeleteArgs = {
  accreditationType?: InputMaybe<DeleteAccreditationTypeCommandInput>;
};


export type MutationAccreditationTypeUpdateArgs = {
  accreditationType?: InputMaybe<UpdateAccreditationTypeCommandInput>;
};


export type MutationAccreditationUpdateArgs = {
  accreditation?: InputMaybe<UpdateAccreditationCommandInput>;
};


export type MutationBroadcastCreateArgs = {
  broadcast?: InputMaybe<CreateBroadcastCommandInput>;
};


export type MutationEventCreateArgs = {
  evt?: InputMaybe<CreateEventCommandInput>;
};


export type MutationEventParticipantCreateArgs = {
  eventParticipant?: InputMaybe<CreateEventParticipantCommandInput>;
};


export type MutationEventParticipantDeleteArgs = {
  eventParticipant?: InputMaybe<DeleteEventParticipantCommandInput>;
};


export type MutationEventUpdateCertificateTemplateArgs = {
  eventCertificateTemplate?: InputMaybe<UpdateEventCertificateTemplateCommandInput>;
};


export type MutationEventUpdateExpiryDateArgs = {
  evt?: InputMaybe<UpdateEventExpiryDateCommandInput>;
};


export type MutationEventUpdateLinksArgs = {
  evt?: InputMaybe<UpdateEventLinksCommandInput>;
};


export type MutationMemberCommunicationPreferencesArgs = {
  command?: InputMaybe<UpdateMemberCommunicationPreferencesCommandInput>;
};


export type MutationMemberCreateArgs = {
  member?: InputMaybe<CreateMemberCommandInput>;
};


export type MutationMemberUpdateArgs = {
  member?: InputMaybe<UpdateMemberCommandInput>;
};


export type MutationMemberUpdateDocumentApprovalArgs = {
  memberDocument?: InputMaybe<UpdateMemberDocumentCommandInput>;
};


export type MutationMembershipCreateArgs = {
  membership?: InputMaybe<CreateMembershipCommandInput>;
};


export type MutationMembershipPaymentArgs = {
  notification?: InputMaybe<PaymentTakenNotificationInput>;
};


export type MutationMembershipUpdateArgs = {
  membership?: InputMaybe<UpdateMembershipCommandInput>;
};


export type MutationNoteCreateArgs = {
  note?: InputMaybe<CreateNoteCommandInput>;
};


export type MutationNoteDeleteArgs = {
  note?: InputMaybe<DeleteNoteCommandInput>;
};


export type MutationNoteUpdateArgs = {
  note?: InputMaybe<UpdateNoteCommandInput>;
};


export type MutationSegmentCreateArgs = {
  segment?: InputMaybe<CreateSegmentCommandInput>;
};


export type MutationSegmentDeleteArgs = {
  segment?: InputMaybe<DeleteSegmentCommandInput>;
};


export type MutationSegmentUpdateArgs = {
  segment?: InputMaybe<UpdateSegmentCommandInput>;
};

export type Note = {
  __typename?: 'Note';
  createdDate: Scalars['DateTime']['output'];
  member?: Maybe<Member>;
  memberId: Scalars['UUID']['output'];
  noteId: Scalars['UUID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type NoteFilterInput = {
  and?: InputMaybe<Array<NoteFilterInput>>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  member?: InputMaybe<MemberFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  noteId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<NoteFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type NotesByMemberIdConnection = {
  __typename?: 'NotesByMemberIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<NotesByMemberIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Note>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NotesByMemberIdEdge = {
  __typename?: 'NotesByMemberIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Note;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaymentTakenNotificationInput = {
  amount: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['UUID']['input'];
  productServiceRef?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type Profession = {
  __typename?: 'Profession';
  members: Array<Member>;
  name: Scalars['String']['output'];
  professionId: Scalars['UUID']['output'];
  sortKey: Scalars['Int']['output'];
};

export type ProfessionFilterInput = {
  and?: InputMaybe<Array<ProfessionFilterInput>>;
  members?: InputMaybe<ListFilterInputTypeOfMemberFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProfessionFilterInput>>;
  professionId?: InputMaybe<UuidOperationFilterInput>;
  sortKey?: InputMaybe<IntOperationFilterInput>;
};

export type ProfessionItem = {
  __typename?: 'ProfessionItem';
  name?: Maybe<Scalars['String']['output']>;
  professionId: Scalars['UUID']['output'];
};

export type ProfessionalRegistration = {
  __typename?: 'ProfessionalRegistration';
  members: Array<Member>;
  name: Scalars['String']['output'];
  professionalRegistrationId: Scalars['UUID']['output'];
  sortKey: Scalars['Int']['output'];
};

export type ProfessionalRegistrationFilterInput = {
  and?: InputMaybe<Array<ProfessionalRegistrationFilterInput>>;
  members?: InputMaybe<ListFilterInputTypeOfMemberFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProfessionalRegistrationFilterInput>>;
  professionalRegistrationId?: InputMaybe<UuidOperationFilterInput>;
  sortKey?: InputMaybe<IntOperationFilterInput>;
};

export type ProfessionalRegistrationItem = {
  __typename?: 'ProfessionalRegistrationItem';
  name?: Maybe<Scalars['String']['output']>;
  professionalRegistrationId: Scalars['UUID']['output'];
};

export type Query = {
  __typename?: 'Query';
  accreditation: Accreditation;
  accreditationApplication: AccreditationApplication;
  accreditationApplicationsByStatus: Array<AccreditationApplicationItem>;
  accreditationApplicationsForEMDR: Array<AccreditationApplicationItem>;
  accreditationType: AccreditationType;
  accreditationTypes?: Maybe<AccreditationTypesConnection>;
  activeAccreditationApplications: Array<AccreditationApplicationItem>;
  broadcast?: Maybe<Broadcast>;
  broadcastStatistics: Array<BroadcastStatistic>;
  broadcasts?: Maybe<BroadcastsConnection>;
  communicationPreferences: Array<MemberCommunicationPreferenceDto>;
  countries: Array<CountryItem>;
  emdrTrainingLevels: Array<EmdrTrainingLevelItem>;
  event?: Maybe<Event>;
  eventCertificateTemplate: Scalars['String']['output'];
  events?: Maybe<EventsConnection>;
  eventsByMemberId?: Maybe<EventsByMemberIdConnection>;
  member?: Maybe<Member>;
  memberDocuments: Array<Document>;
  memberList: MemberList;
  membership: Membership;
  membershipTypes: Array<MembershipTypeItem>;
  notesByMemberId?: Maybe<NotesByMemberIdConnection>;
  professionalRegistrations: Array<ProfessionalRegistrationItem>;
  professions: Array<ProfessionItem>;
  profile: MemberProfile;
  regions: Array<RegionItem>;
  segment?: Maybe<SegmentUpdateModel>;
  segmentMembers: Array<Member>;
  segmentProperties: Array<SegmentProperty>;
  segments?: Maybe<SegmentsConnection>;
  username: Scalars['String']['output'];
};


export type QueryAccreditationArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryAccreditationApplicationArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryAccreditationApplicationsByStatusArgs = {
  status: ApplicationStatus;
};


export type QueryAccreditationTypeArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryAccreditationTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccreditationTypeFilterInput>;
};


export type QueryBroadcastArgs = {
  id: Scalars['String']['input'];
};


export type QueryBroadcastsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BroadcastFilterInput>;
};


export type QueryCommunicationPreferencesArgs = {
  memberId: Scalars['UUID']['input'];
};


export type QueryEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryEventCertificateTemplateArgs = {
  id: Scalars['String']['input'];
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryEventsByMemberIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventFilterInput>;
};


export type QueryMemberArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMemberDocumentsArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMemberListArgs = {
  keywords?: Scalars['String']['input'];
  orderBy?: Scalars['String']['input'];
  orderByDesc?: Scalars['Boolean']['input'];
  show?: Scalars['String']['input'];
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};


export type QueryMembershipArgs = {
  id: Scalars['String']['input'];
};


export type QueryNotesByMemberIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoteFilterInput>;
};


export type QuerySegmentArgs = {
  id: Scalars['String']['input'];
};


export type QuerySegmentMembersArgs = {
  id: Scalars['String']['input'];
};


export type QuerySegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SegmentUpdateModelFilterInput>;
};


export type QueryUsernameArgs = {
  userId: Scalars['String']['input'];
};

export type Region = {
  __typename?: 'Region';
  name: Scalars['String']['output'];
  regionId: Scalars['UUID']['output'];
  sortKey: Scalars['Int']['output'];
};

export type RegionFilterInput = {
  and?: InputMaybe<Array<RegionFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RegionFilterInput>>;
  regionId?: InputMaybe<UuidOperationFilterInput>;
  sortKey?: InputMaybe<IntOperationFilterInput>;
};

export type RegionItem = {
  __typename?: 'RegionItem';
  name?: Maybe<Scalars['String']['output']>;
  regionId: Scalars['UUID']['output'];
};

export enum ReviewStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  NeedsWork = 'NEEDS_WORK'
}

export type ReviewStatusOperationFilterInput = {
  eq?: InputMaybe<ReviewStatus>;
  in?: InputMaybe<Array<ReviewStatus>>;
  neq?: InputMaybe<ReviewStatus>;
  nin?: InputMaybe<Array<ReviewStatus>>;
};

export enum ReviewerRole {
  Emdr = 'EMDR',
  PrimarySupervisor = 'PRIMARY_SUPERVISOR',
  SecondarySupervisor = 'SECONDARY_SUPERVISOR'
}

export type ReviewerRoleOperationFilterInput = {
  eq?: InputMaybe<ReviewerRole>;
  in?: InputMaybe<Array<ReviewerRole>>;
  neq?: InputMaybe<ReviewerRole>;
  nin?: InputMaybe<Array<ReviewerRole>>;
};

export type Segment = {
  __typename?: 'Segment';
  conditionsJson: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  removedDate?: Maybe<Scalars['DateTime']['output']>;
  segmentId: Scalars['UUID']['output'];
  updatedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type SegmentAnd = {
  __typename?: 'SegmentAND';
  segmentOr: Array<SegmentOr>;
};

export type SegmentAndFilterInput = {
  and?: InputMaybe<Array<SegmentAndFilterInput>>;
  or?: InputMaybe<Array<SegmentAndFilterInput>>;
  segmentOr?: InputMaybe<ListFilterInputTypeOfSegmentOrFilterInput>;
};

export type SegmentAndInput = {
  segmentOr: Array<SegmentOrInput>;
};

export type SegmentConfiguration = {
  __typename?: 'SegmentConfiguration';
  segmentAnd: Array<SegmentAnd>;
};

export type SegmentConfigurationFilterInput = {
  and?: InputMaybe<Array<SegmentConfigurationFilterInput>>;
  or?: InputMaybe<Array<SegmentConfigurationFilterInput>>;
  segmentAnd?: InputMaybe<ListFilterInputTypeOfSegmentAndFilterInput>;
};

export type SegmentConfigurationInput = {
  segmentAnd: Array<SegmentAndInput>;
};

export type SegmentFilterInput = {
  and?: InputMaybe<Array<SegmentFilterInput>>;
  conditionsJson?: InputMaybe<StringOperationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SegmentFilterInput>>;
  removedDate?: InputMaybe<DateTimeOperationFilterInput>;
  segmentId?: InputMaybe<UuidOperationFilterInput>;
  updatedDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type SegmentOr = {
  __typename?: 'SegmentOR';
  id?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  propertyType: Scalars['String']['output'];
  propertyValue?: Maybe<Scalars['String']['output']>;
};

export type SegmentOrFilterInput = {
  and?: InputMaybe<Array<SegmentOrFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SegmentOrFilterInput>>;
  propertyName?: InputMaybe<StringOperationFilterInput>;
  propertyType?: InputMaybe<StringOperationFilterInput>;
  propertyValue?: InputMaybe<StringOperationFilterInput>;
};

export type SegmentOrInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  propertyName?: InputMaybe<Scalars['String']['input']>;
  propertyType: Scalars['String']['input'];
  propertyValue?: InputMaybe<Scalars['String']['input']>;
};

export type SegmentProperty = {
  __typename?: 'SegmentProperty';
  name: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type SegmentUpdateModel = {
  __typename?: 'SegmentUpdateModel';
  conditionsJson: Scalars['String']['output'];
  configuration?: Maybe<SegmentConfiguration>;
  createdDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  removedDate?: Maybe<Scalars['DateTime']['output']>;
  segmentId: Scalars['UUID']['output'];
  updatedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type SegmentUpdateModelFilterInput = {
  and?: InputMaybe<Array<SegmentUpdateModelFilterInput>>;
  conditionsJson?: InputMaybe<StringOperationFilterInput>;
  configuration?: InputMaybe<SegmentConfigurationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SegmentUpdateModelFilterInput>>;
  removedDate?: InputMaybe<DateTimeOperationFilterInput>;
  segmentId?: InputMaybe<UuidOperationFilterInput>;
  updatedDate?: InputMaybe<DateTimeOperationFilterInput>;
};

/** A connection to a list of items. */
export type SegmentsConnection = {
  __typename?: 'SegmentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SegmentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SegmentUpdateModel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SegmentsEdge = {
  __typename?: 'SegmentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SegmentUpdateModel;
};

export type Statistics = {
  __typename?: 'Statistics';
  metrics?: Maybe<Metrics>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Telephone = {
  __typename?: 'Telephone';
  default: Scalars['Boolean']['output'];
  memberId: Scalars['UUID']['output'];
  number: Scalars['String']['output'];
  telephoneId: Scalars['UUID']['output'];
};

export type TelephoneFilterInput = {
  and?: InputMaybe<Array<TelephoneFilterInput>>;
  default?: InputMaybe<BooleanOperationFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TelephoneFilterInput>>;
  telephoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type Transaction = {
  __typename?: 'Transaction';
  createdDate: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  jsonData?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['UUID']['output'];
  transactionId: Scalars['UUID']['output'];
};

export type TransactionFilterInput = {
  and?: InputMaybe<Array<TransactionFilterInput>>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  customerId?: InputMaybe<StringOperationFilterInput>;
  eventId?: InputMaybe<StringOperationFilterInput>;
  jsonData?: InputMaybe<StringOperationFilterInput>;
  memberId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<TransactionFilterInput>>;
  transactionId?: InputMaybe<UuidOperationFilterInput>;
};

export type UpdateAccreditationApplicationCommandInput = {
  accreditationApplicationId: Scalars['UUID']['input'];
  applicationStatus?: InputMaybe<ApplicationStatus>;
  hasMitigation?: InputMaybe<Scalars['Boolean']['input']>;
  jsonData?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateAccreditationCommandInput = {
  accreditationId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
};

export type UpdateAccreditationTypeCommandInput = {
  accreditationTypeId: Scalars['String']['input'];
  active: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  requirementsJson: Scalars['String']['input'];
  sortKey: Scalars['Int']['input'];
  supervisor: Scalars['Boolean']['input'];
};

export type UpdateEventCertificateTemplateCommandInput = {
  certificateTemplateHtml?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEventExpiryDateCommandInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventName?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateEventLinksCommandInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  href?: InputMaybe<Scalars['String']['input']>;
  surveyHref?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMemberCommandInput = {
  childTrainingLevel?: InputMaybe<Scalars['String']['input']>;
  childTrainingLevelDateAchieved?: InputMaybe<Scalars['DateTime']['input']>;
  emdrTrainingLevel?: InputMaybe<Scalars['String']['input']>;
  emdrTrainingLevelDateAchieved?: InputMaybe<Scalars['DateTime']['input']>;
  languages?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  professionalRegistrations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  professions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMemberCommunicationPreferencesCommandInput = {
  communicationPreferences?: InputMaybe<Array<InputMaybe<MemberCommunicationPreferenceDtoInput>>>;
  memberId: Scalars['UUID']['input'];
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateMemberDocumentCommandInput = {
  approved: Scalars['Boolean']['input'];
  cpd: Scalars['Int']['input'];
  documentId?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  rejected: Scalars['Boolean']['input'];
};

export type UpdateMembershipCommandInput = {
  endDate: Scalars['DateTime']['input'];
  membershipId?: InputMaybe<Scalars['String']['input']>;
  membershipTypeId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNoteCommandInput = {
  noteId?: InputMaybe<Scalars['UUID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSegmentCommandInput = {
  segmentConfiguration?: InputMaybe<SegmentConfigurationInput>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
};

export type UserAccountInput = {
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type Video = {
  __typename?: 'Video';
  description?: Maybe<Scalars['String']['output']>;
  event: Event;
  eventId: Scalars['UUID']['output'];
  filename: Scalars['String']['output'];
  name: Scalars['String']['output'];
  videoId: Scalars['UUID']['output'];
};

export type VideoFilterInput = {
  and?: InputMaybe<Array<VideoFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  event?: InputMaybe<EventFilterInput>;
  eventId?: InputMaybe<UuidOperationFilterInput>;
  filename?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VideoFilterInput>>;
  videoId?: InputMaybe<UuidOperationFilterInput>;
};

export type GetMembershipQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetMembershipQuery = { __typename?: 'Query', membership: { __typename?: 'Membership', membershipId: any, memberId: any, startDate: any, endDate: any, membershipNumber: any, membershipType: { __typename?: 'MembershipType', membershipTypeId: any, name: string, description: string, initialPrice?: number | null, yearlyPrice?: number | null } } };

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { __typename?: 'Query', profile: { __typename?: 'MemberProfile', id: any, membershipNumber?: any | null, name?: string | null, title?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, organisation?: string | null, website?: string | null, avatar?: string | null, address?: { __typename?: 'MemberProfileAddress', id?: any | null, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, country?: string | null, postcode?: string | null } | null } };


export const GetMembershipDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMembership"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membership"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipId"}},{"kind":"Field","name":{"kind":"Name","value":"memberId"}},{"kind":"Field","name":{"kind":"Name","value":"membershipType"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"membershipTypeId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"initialPrice"}},{"kind":"Field","name":{"kind":"Name","value":"yearlyPrice"}}]}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"membershipNumber"}}]}}]}}]} as unknown as DocumentNode<GetMembershipQuery, GetMembershipQueryVariables>;
export const GetProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"membershipNumber"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"organisation"}},{"kind":"Field","name":{"kind":"Name","value":"website"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"}},{"kind":"Field","name":{"kind":"Name","value":"address"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"addressLine1"}},{"kind":"Field","name":{"kind":"Name","value":"addressLine2"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"country"}},{"kind":"Field","name":{"kind":"Name","value":"postcode"}}]}}]}}]}}]} as unknown as DocumentNode<GetProfileQuery, GetProfileQueryVariables>;