import { gql } from '@apollo/client';
import {
  accreditationApplicationGQL,
  accreditationApplicationItemGQL,
  accreditationGQL,
  accreditationTypeGQL,
} from '../gql/accreditationGQL';

export const GET_ACCREDITATION = gql`
  query GetAccreditation($id:UUID!){
    accreditation(id:$id){
        ${accreditationGQL}
        
    }
  }
`;

export const GET_ACCREDITATION_TYPE = gql`
  query GetAccreditationType($id:UUID!){
    accreditationType(id:$id){
        ${accreditationTypeGQL}
        
    }
  }
`;

export const GET_ACCREDITATION_TYPES = gql`
  query GetAccreditationTypes($first:Int $after:String $where:AccreditationTypeFilterInput) {
    accreditationTypes(first:$first after:$after where:$where){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        nodes{
           ${accreditationTypeGQL}
        }
    }
}
`;

export const GET_ACTIVE_ACCREDITATION_APPLICATIONS = gql`
  query GetActiveAccreditationApplications {
    activeAccreditationApplications {
      ${accreditationApplicationItemGQL}
    }
  }
`;

export const GET_ACCREDITATION_APPLICATIONS = gql`
  query GetAccreditationApplications {
    accreditationApplicationsForEMDR {
      ${accreditationApplicationItemGQL}
    }
  }
`;

export const GET_ACCREDITATION_APPLICATION = gql`
  query GetAccreditationApplication($id:UUID!) {
    accreditationApplication(id:$id) {
      ${accreditationApplicationGQL}
    }
  }
`;
