import React, { useEffect } from 'react';
import Applications from './Applications';
import Accreditations from './Accreditations';
import { Tab, Tabs } from 'react-bootstrap';

const AccreditationsPage: React.FC = () => {
    const [activeTab, setActiveTab] = React.useState<string>();

    useEffect(() => {
        const existingActiveTab = localStorage.getItem('activeTab');
        if (existingActiveTab) {
            setActiveTab(existingActiveTab);
        }
    }, []);

    useEffect(() => {
        if (activeTab) {
            localStorage.setItem('activeTab', activeTab);
        }
    }, [activeTab]);

    return (
        <Tabs
            activeKey={activeTab}
            id="accreditations-tabs"
            justify
            onSelect={(k) => k && setActiveTab(k)}
        >
            <Tab eventKey="accreditations" title="Accreditations">
                <Accreditations />
            </Tab>
            <Tab eventKey="applications" title="Applications">
                <Applications />
            </Tab>
        </Tabs>
    );
};

export default AccreditationsPage;
