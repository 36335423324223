import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_ACCREDITATION_TYPE } from '../../graphql/accreditation/queries';
import {
    AccreditationType,
    UpdateAccreditationTypeCommandInput,
} from '../../graphql/__generated__/graphql';
import { UPDATE_ACCREDITATION_TYPE } from '../../graphql/accreditation/mutations';

export const useAccreditation = ({ ...props }) => {
    const { accreditationTypeId } = props;
    const [accreditationType, setAccreditationType] = useState<AccreditationType>();
    const [updating, setUpdating] = useState(false);

    const { data, error, loading, refetch } = useQuery(GET_ACCREDITATION_TYPE, {
        variables: { id: accreditationTypeId },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (!loading && !error) {
            setAccreditationType(data.accreditationType);
        }
    }, [data]);

    const [accreditationTypeUpdate] = useMutation(UPDATE_ACCREDITATION_TYPE);

    const handleChange = (name: string, value: any) => {
        if (!accreditationType) return;
        const safeValue =
            name === 'price' ? parseFloat(value) : name === 'sortKey' ? parseInt(value) : value;
        setAccreditationType({
            ...accreditationType,
            [name]: safeValue,
        } as AccreditationType);
    };

    const handleUpdateAccreditationType = () => {
        return new Promise((resolve, reject) => {
            setUpdating(true);
            const updateCommand: UpdateAccreditationTypeCommandInput = {
                accreditationTypeId: accreditationType?.accreditationTypeId,
                name: accreditationType?.name || '',
                active: accreditationType?.active === true,
                supervisor: accreditationType?.supervisor === true,
                description: accreditationType?.description || '',
                price: accreditationType?.price || 0,
                requirementsJson: accreditationType?.requirementsJson || '',
                sortKey: accreditationType?.sortKey || 0,
            };

            resolve(
                accreditationTypeUpdate({
                    variables: {
                        accreditationType: updateCommand,
                    },
                })
                    .then((response) => {
                        setAccreditationType(response.data.accreditationTypeUpdate);
                    })
                    .catch((reason) => {
                        reject(reason);
                    })
                    .finally(() => setUpdating(false))
            );
        });
    };

    const handleReloadAccreditation = () => {
        refetch({ id: accreditationTypeId }).then((resp) => {
            //console.log("response", resp)
        });
    };

    return {
        models: {
            accreditation: accreditationType,
            loading,
            updating,
        },
        operations: {
            handleReloadAccreditation,
            handleChange,
            handleUpdateAccreditationType,
        },
    };
};
