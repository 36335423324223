import { useState } from 'react';

export const useParticipantModal = () => {
    const [show, setShow] = useState(false);

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    return {
        models: { show },
        operations: { handleOpen, handleClose },
    };
};
