import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormControl,
    FormSelect,
    InputGroup,
    Row,
    Stack,
} from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAccreditation } from './useAccreditation';
import Requirements from './Requirements';
import { LoadingSpinner } from '../LoadingSpinner';
import { useNotificationContext } from '../../contexts/NotificationContext';

const AccreditationDetail: React.FC = () => {
    const { accreditationTypeId } = useParams();
    const { models, operations } = useAccreditation({ accreditationTypeId });
    const { accreditation, updating, loading } = models;
    const { handleChange, handleUpdateAccreditationType } = operations;

    const navigate = useNavigate();
    const location = useLocation();
    const notificationContext = useNotificationContext();
    const searchParams = new URLSearchParams(location.search);

    const showRawJson = searchParams.get('showRaw') === 'true';

    const [active, setActive] = useState(false);
    const [supervisor, setSupervisor] = useState(false);
    const [jsonHasErrors, setJsonHasErrors] = React.useState(false);

    useEffect(() => {
        if (accreditation) {
            setActive(accreditation.active);
            setSupervisor(accreditation.supervisor);

            try {
                JSON.parse(accreditation.requirementsJson || '{}');
                setJsonHasErrors(false);
            } catch (e) {
                setJsonHasErrors(true);
            }
        }
    }, [accreditation]);

    useEffect(() => {
        handleChange('active', active);
    }, [active]);

    useEffect(() => {
        handleChange('supervisor', supervisor);
    }, [supervisor]);

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        handleChange(e.target.name, e.target.value);

    const handleRequirementsJsonChange = (updatedRequirementsJson: string) => {
        handleChange('requirementsJson', updatedRequirementsJson);
    };

    const performUpdate = () => {
        handleUpdateAccreditationType()
            .then(() => {
                console.log('OK');
                notificationContext.notify('info', 'Successfully updated accreditation');
            })
            .catch(() => {
                console.log('BAD');
                notificationContext.notify('danger', 'An error occurred');
            });
    };

    if (loading || !accreditation) return <LoadingSpinner />;

    return (
        <Card bg="light" border="light">
            <CardBody style={{ textAlign: 'left' }}>
                <Row>
                    <Col xs="auto">
                        <Button
                            variant="outline-primary"
                            onClick={() => navigate('/accreditations')}
                        >
                            <i className="bi bi-chevron-left"></i>
                        </Button>
                    </Col>
                    <Col>
                        <h3 className="">{accreditation.name}</h3>
                    </Col>
                    <Col xs={'auto'}>
                        <Form.Group as={Stack} direction="horizontal" gap={2}>
                            <FormSelect
                                value={supervisor?.toString()}
                                onChange={(e) => {
                                    setSupervisor(e.target.value === 'true');
                                }}
                            >
                                <option value="true">Holders can be supervisors</option>
                                <option value="false">Holders cannot be supervisors</option>
                            </FormSelect>
                            <Form.Check // prettier-ignore
                                type="switch"
                                checked={supervisor}
                                onChange={() => setSupervisor(!supervisor)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={'auto'}>
                        <Form.Group as={Stack} direction="horizontal" gap={2}>
                            <FormSelect
                                value={active.toString()}
                                onChange={(e) => {
                                    setActive(e.target.value === 'true');
                                }}
                            >
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </FormSelect>
                            <Form.Check // prettier-ignore
                                type="switch"
                                checked={active}
                                onChange={() => setActive(!active)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Form
                        onSubmit={() => {
                            console.log('submit');
                        }}
                    >
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formAccreditationName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={accreditation.name || ''}
                                        onChange={handleFieldChange}
                                        placeholder="Enter accreditation name"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="2">
                                <Form.Group className="mb-3" controlId="formAccreditationPrice">
                                    <Form.Label>Price</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>&pound;</InputGroup.Text>
                                        <Form.Control
                                            type="number"
                                            name="price"
                                            value={accreditation.price || ''}
                                            onChange={handleFieldChange}
                                            placeholder="0.00"
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs={'1'}>
                                <Form.Group className="mb-3" controlId="formAccreditationSortKey">
                                    <Form.Label>Priority</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="number"
                                            name="sortKey"
                                            value={accreditation.sortKey || 0}
                                            onChange={handleFieldChange}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formAccreditationDescription"
                                >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        as={'textarea'}
                                        value={accreditation.description || ''}
                                        onChange={handleFieldChange}
                                        placeholder="Enter accreditation description"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Button
                                    className={'float-end'}
                                    variant="primary"
                                    type="button"
                                    disabled={updating || jsonHasErrors}
                                    onClick={() => performUpdate()}
                                >
                                    {updating ? 'Saving...' : 'Save'}
                                </Button>
                            </Col>
                        </Row>
                        {showRawJson && (
                            <Row className={'mb-3'}>
                                <Col>
                                    {accreditation.requirementsJson && (
                                        <Alert variant="danger">
                                            Only edit this if you know what you're doing
                                        </Alert>
                                    )}
                                    <FormControl
                                        isInvalid={jsonHasErrors}
                                        type="text"
                                        name="requirementsJson"
                                        as={'textarea'}
                                        rows={20}
                                        value={accreditation.requirementsJson || ''}
                                        onChange={handleFieldChange}
                                        placeholder="Enter accreditation type requirements Json"
                                    />
                                    <FormControl.Feedback type={'invalid'}>
                                        JSON is invalid
                                    </FormControl.Feedback>
                                </Col>
                            </Row>
                        )}

                        {!showRawJson && accreditation.requirementsJson && (
                            <Requirements
                                requirementsJson={accreditation.requirementsJson}
                                onChange={handleRequirementsJsonChange}
                            />
                        )}

                        <Row className={'mt-2'}>
                            <Col>
                                <Button
                                    className={'float-end'}
                                    variant="primary"
                                    type="button"
                                    disabled={updating || jsonHasErrors}
                                    onClick={() => performUpdate()}
                                >
                                    {updating ? 'Saving...' : 'Save'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </CardBody>
        </Card>
    );
};

export default AccreditationDetail;
