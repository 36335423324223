import { useEffect, useState } from 'react';
import useVideos from '../../../hooks/useVideos';
import { UploadVideoModalProps } from './UploadVideoModalProps';

export const useUploadVideo = ({ ...props }) => {
    const { uploadFile, error, progress, totalSizeMb, uploadedSizeMb } = useVideos();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [expiryDate, setExpiryDate] = useState<string>();
    const [cpd, setCPD] = useState<number>(0);
    const [name, setName] = useState<string>();
    const [filename, setFilename] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>(selectedFile !== null);
    const [videoSrc, setVideoSrc] = useState<string>();

    const [inProgress, setInProgress] = useState(false);

    const { eventId, videoId, onUpdate } = { ...props } as UploadVideoModalProps;

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setIsValid(false);
        onUpdate();
        setSelectedFile(null);
        setExpiryDate(undefined);
        setCPD(0);
        setName(undefined);
        setDescription(undefined);
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleSave = () => {
        setInProgress(true);

        handleUpload().then(() => {
            console.log("I'm closing");

            handleClose();
            setInProgress(false);
        });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setName(files[0].name);
            setFilename(files[0].name);

            console.log('file', files[0].name);

            setSelectedFile(files[0]);
            setIsValid(true);
        }
    };

    const handleUpload = () => {
        return new Promise((resolve) => {
            if (selectedFile) {
                uploadFile({
                    name: name ?? '',
                    description: description ?? '',
                    eventId: eventId ?? '',
                    file: selectedFile,
                    fileName: filename ?? '',
                }).then(() => {
                    setSelectedFile(null);
                    console.log("I'm done");
                    resolve(true);
                });
            }
        });
    };

    const checkVideoAvailability = async () => {
        try {
            let url = process.env.REACT_APP_VIDEOAPI_ENDPOINT + '/' + videoId;
            // Specify the Range header to fetch only the first 10 kilobytes
            const options = {
                headers: {
                    Range: 'bytes=0-10239',
                },
            };
            const response = await fetch(url, options);

            // The server should respond with 206 Partial Content if it supports range requests
            if (response.status === 206 || response.status === 200)
                // Including 200 OK if the server doesn't support range but the resource is available
                setVideoSrc(url);
            else setVideoSrc(undefined);
        } catch (error) {
            console.error('Error checking video availability:', error);
            setVideoSrc(undefined);
        }
    };

    useEffect(() => {
        if (videoId) checkVideoAvailability();
    }, [props]);

    return {
        models: {
            name,
            description,
            expiryDate,
            cpd,
            show,
            error,
            isValid,
            videoSrc,
            inProgress,
            progress,
            uploadedSizeMb,
            totalSizeMb,
        },
        operations: {
            handleShow,
            handleSave,
            handleClose,
            setName,
            setDescription,
            setExpiryDate,
            setCPD,
            handleFileChange,
        },
    };
};
