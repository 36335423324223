import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import UpdateMembershipModal from '../Modals/UpdateMembershipModal';
import { IMembershipCard } from './IMembershipCard';
import { useMembership } from './useMembership';

const MembershipCard: React.FC<IMembershipCard> = ({ membershipId }) => {
    const { models, operations } = useMembership({ membershipId });
    const { membership } = models;
    const { handleUpdateMembership, isExpired } = operations;

    return (
        <Card key={membership?.membershipId} style={{ opacity: isExpired() ? 0.6 : 1 }}>
            <Card.Body>
                <div>
                    <h5>
                        {isExpired() ? (
                            <Badge pill bg="danger" className="p-0 me-2">
                                <i className="bi bi-x"></i>
                            </Badge>
                        ) : (
                            <Badge pill bg="success" className="p-0 me-2">
                                <i className="bi bi-check"></i>
                            </Badge>
                        )}
                        <strong>{membership?.membershipNumber.toString().padStart(9, '0')}</strong>
                    </h5>
                    <small>{membership?.membershipType?.name}</small>
                    <p className="mb-2">
                        <Badge bg={isExpired() ? 'danger' : 'primary'}>
                            {isExpired() ? 'Expired' : 'Expires'}{' '}
                            {new Date(membership?.endDate).toLocaleDateString('en-GB')}
                        </Badge>
                    </p>

                    <UpdateMembershipModal
                        membership={membership}
                        onUpdate={handleUpdateMembership}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

export default MembershipCard;
