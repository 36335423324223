import React from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { IParticipantModalProps } from './IParticipantModalProps';
import { useParticipantModal } from './useParticipantModal';

export const ParticipantModal: React.FC<IParticipantModalProps> = ({ event }) => {
    const { models, operations } = useParticipantModal();
    const { show } = models;
    const { handleOpen, handleClose } = operations;

    return (
        <>
            <Button variant={'light'} onClick={() => handleOpen()}>
                <i className="bi bi-people mx-1"></i>
                {event.participants?.length ?? 0}
            </Button>

            <Modal show={show} onHide={handleClose} data-testid="member-editablename-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Event Participants</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h5>
                        Members ( {event.participants?.filter((x) => x?.memberId !== null).length} )
                    </h5>

                    {event.participants?.filter((x) => x?.memberId !== null).length === 0 && (
                        <small>No members participating.</small>
                    )}

                    <ul>
                        {event.participants
                            ?.filter((x) => x?.memberId !== null)
                            .map((p) => {
                                return <li key={p?.eventParticipantId}>{p?.emailAddress}</li>;
                            })}
                    </ul>

                    <h5>
                        Guests ( {event.participants?.filter((x) => x?.memberId === null).length} )
                    </h5>

                    {event.participants?.filter((x) => x?.memberId === null).length === 0 && (
                        <small>No guests participating.</small>
                    )}

                    <ul>
                        {event.participants
                            ?.filter((x) => x?.memberId === null)
                            .map((p) => {
                                return <li key={p?.eventParticipantId}>{p?.emailAddress}</li>;
                            })}
                    </ul>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <ButtonGroup>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ParticipantModal;
