import { PropsWithChildren, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useUserContext } from './contexts/UserContext';

export const PrivateRoute: React.FC<PropsWithChildren> = ({ children }) => {
    const auth = useAuth();
    const user = useUserContext();

    useEffect(() => {
        if (auth.isAuthenticated === false && !auth.isLoading) {
            auth.signinRedirect();
        }
    });

    return user.loggedIn ? (
        <>{children}</>
    ) : (
        <Spinner role="status">
            <span className="visually-hidden">Loading authentication...</span>
        </Spinner>
    );
};
