import React from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { CreateAccreditationModalProps } from './CreateAccreditationModalProps';
import { useCreateAccreditation } from './useCreateAccreditation';

const CreateAccreditationModal: React.FC<CreateAccreditationModalProps> = ({ ...props }) => {
    const { models, operations } = useCreateAccreditation({ ...props });
    const { name, show } = models;
    const { handleShow, handleClose, handleSave, setName } = operations;

    return (
        <>
            <Button variant="outline-primary" onClick={handleShow}>
                <i className="bi bi-plus me-1"></i>
                New Accreditation
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new accreditation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>

                    <Alert variant="info" className="my-2">
                        You will be able to configure and enable the accreditation after you create
                        it.
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={name.length === 0}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateAccreditationModal;
