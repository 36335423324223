import { ApplicationStatus } from '../graphql/__generated__/graphql';

export const formatToISODate = (dateString: string) => {
    const [day, month, year] = dateString.split('/');
    if (month && year) {
        return `${year}-${month}-${day}`;
    }

    const newDate = new Date(dateString);
    return newDate.toLocaleDateString();
};

export const formatToLocalDateTime = (dateString: string) => {
    const newDate = new Date(dateString);
    return `${newDate.toLocaleTimeString()} ${newDate.toLocaleDateString()}`;
};

export const formatApplicationStatus = (status: ApplicationStatus) => {
    switch (status) {
        case ApplicationStatus.Approved:
            return 'Approved';
        case ApplicationStatus.NeedsWork:
            return 'Returned to applicant';
        case ApplicationStatus.InProgress:
            return 'In progress';
        case ApplicationStatus.ReadyToSubmit:
            return 'Ready to submit';
        case ApplicationStatus.ReadyForReview:
            return 'Ready for review';
        default:
            return '-';
    }
};
