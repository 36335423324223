import { memberGQL } from './memberGQL';

export const broadcastGQL = `
    broadcastId
    subject
    body
    type
    recipients {
        sent
        failed
        readCount
        emailAddress
        member {
            ${memberGQL}
        }
    }
    createdDate
`;

export const broadcastItemGQL = `
    broadcastId
    segmentId
    eventId
    subject
    body
    type
    recipients {
        memberId
        emailAddress
        sent
        failed
        readCount
    }
    createdDate
    sentDate
`;
