import React from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { CreateSegmentModalProps } from './CreateSegmentModalProps';
import { useCreateSegment } from './useCreateSegment';

const CreateSegmentModal: React.FC<CreateSegmentModalProps> = ({ ...props }) => {
    const { models, operations } = useCreateSegment({ ...props });
    const { name, show } = models;
    const { handleShow, handleClose, handleSave, setName } = operations;

    return (
        <>
            <Button variant="outline-primary" onClick={handleShow}>
                <i className="bi bi-plus me-1"></i>
                New Segment
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new segment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Text>
                            Give the segment a name to describe the members it will contain
                        </Form.Text>
                    </Form.Group>

                    <Alert variant="info" className="my-2">
                        Segments are dynamically filtered groups that can be used for communication
                        with multiple members via <b>Broadcasts</b>. You will be able to adjust
                        filters and test the segment after you create it.
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={name.length === 0}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateSegmentModal;
