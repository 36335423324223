import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_NOTES_BY_MEMBER_ID } from '../../graphql/note/queries';
import {
    Note,
    NoteFilterInput,
    StringOperationFilterInput,
} from '../../graphql/__generated__/graphql';
import { MemberNoteProps } from './MemberNoteProps';

export const useMemberNotes = ({ ...props }) => {
    var { member } = { ...props } as MemberNoteProps;

    const [notes, setNotes] = useState<Note[]>();
    const [page, setPage] = useState(1);
    const [cursor, setCursor] = useState<string>();
    const [keywords, setKeywords] = useState<string>();
    const [prev, setPrev] = useState(false);

    const variables = {
        id: member.memberId,
        after: prev === true ? undefined : (cursor ?? undefined),
        before: prev === false ? undefined : (cursor ?? undefined),
        where: {
            name: keywords ? ({ contains: keywords } as StringOperationFilterInput) : undefined,
        } as NoteFilterInput,
    };

    const { data, error, loading, refetch } = useQuery(GET_NOTES_BY_MEMBER_ID, { variables });

    const handleReloadMemberNotes = () => {
        refetch(variables);
    };

    useEffect(() => {
        if (!loading && !error) {
            setNotes(data.notesByMemberId.nodes as Note[]);
        }
    }, [data, error, loading]);

    return {
        models: {
            loading,
            notes,
            keywords,
            member,
        },
        operations: {
            setKeywords,
            handleReloadMemberNotes,
        },
    };
};
