import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

interface ChildLevelSelectProps {
    name: string;
    value: string;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => void;
}

const ChildLevelSelect: React.FC<ChildLevelSelectProps> = ({ name, value, onChange }) => {
    const levelOptions = ['Part 1', 'Part 2', 'Part 3', 'Part 4', 'MSc'];

    return (
        <Form.Select as="select" name={name} value={value} onChange={(e) => onChange(e)}>
            <option value="">Not Applicable</option>
            {levelOptions.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </Form.Select>
    );
};

export default ChildLevelSelect;
