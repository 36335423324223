import { useQuery } from '@apollo/client';
import React from 'react';
import { Form } from 'react-bootstrap';
import { GET_MEMBERSHIPTYPES } from '../../../graphql/membership';
import { MembershipTypeSelectProps } from './MembershipTypeSelectProps';
import { useMembershipTypes } from './useMembershipTypes';

const MembershipTypeSelect: React.FC<MembershipTypeSelectProps> = ({ name, value, onChange }) => {
    const { data } = useQuery(GET_MEMBERSHIPTYPES);
    const { models } = useMembershipTypes({ data });
    const { membershipTypes } = models;

    return (
        <Form.Control as="select" name={name} value={value} onChange={(e) => onChange(e)} required>
            {membershipTypes ? (
                <>
                    <option value="">Select membership type</option>
                    {membershipTypes.map((option, index) => (
                        <option key={index} value={option.membershipTypeId}>
                            {option.name}
                        </option>
                    ))}
                </>
            ) : (
                <option value=""></option>
            )}
        </Form.Control>
    );
};

export default MembershipTypeSelect;
