import { Container } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import AccreditationDetail from './components/Accreditation';
import Accreditations from './components/Accreditations';
import AuthorisedLayout from './components/AuthorisedLayout';
import Broadcasts from './components/Broadcasts';
import Dashboard from './components/Dashboard';
import Events from './components/Events';
import Home from './components/Home';
import MemberDetail from './components/Member';
import Members from './components/Members';
import SegmentDetail from './components/Segment';
import Segments from './components/Segments';
import { PrivateRoute } from './PrivateRoute';
import AccreditationApplication from './components/AccreditationApplication';

function App() {
    return (
        <div className="App">
            <Container className="p-4">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <Dashboard />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/members"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <Members />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/member/:memberId"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <MemberDetail />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/accreditations/applications/:applicationId"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <AccreditationApplication />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/accreditations"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <Accreditations />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/accreditation/:accreditationTypeId"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <AccreditationDetail />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/events"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <Events />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/segments"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <Segments />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/segment/:segmentId"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <SegmentDetail />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/broadcasts"
                            element={
                                <PrivateRoute>
                                    <AuthorisedLayout>
                                        <Broadcasts />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </Container>
        </div>
    );
}

export default App;
