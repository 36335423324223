import React from 'react';
import { Spinner } from 'react-bootstrap';

export const LoadingSpinner: React.FC = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Spinner role="status" />{' '}
        </div>
    );
};
