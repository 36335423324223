import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { FeedbackLevel } from '../../interfaces/FeedbackLevel';

export interface ToastMessage {
    level?: FeedbackLevel;
    message?: string[] | string;
    id: number;
}

const ToastNotification: React.FC<ToastMessage> = ({ level, message, id }) => {
    const [messages, setMessages] = useState<ToastMessage[]>([]);

    // Add the new message to the stack whenever the newMessage prop changes
    useEffect(() => {
        setMessages((prevMessages) => {
            if (prevMessages.find((m) => m.message === message)) {
                return prevMessages;
            }

            return [...prevMessages, { id, message, level }];
        });
    }, [id, message]);

    // Remove a message from the stack
    const removeMessage = (id: number) => {
        setMessages(messages.filter((message) => message.id !== id));
    };

    return (
        <ToastContainer position="bottom-end" className="pt-6 pe-4">
            {messages.map((message) => (
                <Toast
                    key={message.id}
                    onClose={() => removeMessage(message.id)}
                    onExited={() => removeMessage(message.id)}
                    autohide
                    delay={4000}
                    className={`mb-2 border-${message.level}`}
                >
                    <Toast.Body>
                        {!Array.isArray(message.message) || message.message.length === 1 ? (
                            message.message
                        ) : (
                            <ul>
                                {message.message.map((m) => (
                                    <li>{m}</li>
                                ))}
                            </ul>
                        )}
                    </Toast.Body>
                </Toast>
            ))}
        </ToastContainer>
    );
};

export default ToastNotification;
