import React from 'react';
import { Alert, Button, Card, Modal } from 'react-bootstrap';
import { BroadcastRecipientsModalProps } from './BroadcastRecipientsModalProps';
import { useBroadcastRecipients } from './useBroadcastRecipients';

const BroadcastRecipientsModal: React.FC<BroadcastRecipientsModalProps> = ({ ...props }) => {
    const { models, operations } = useBroadcastRecipients({ ...props });
    const { emailAddresses, show } = models;
    const { handleShow, handleClose } = operations;

    return (
        <>
            <Button
                style={{
                    minWidth: '4em',
                }}
                variant="outline-primary"
                size="sm"
                onClick={handleShow}
            >
                {props.broadcast.recipients.length}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Recipients</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!props.broadcast.sentDate && (
                        <Alert variant="info">
                            This is a draft broadcast;{' '}
                            <strong>recipients have not recieved this message</strong>. This
                            recipient list will be recalculated when the broadcast is sent.
                        </Alert>
                    )}

                    <Card className="p-2" style={{ maxHeight: '50vh', overflow: 'auto' }}>
                        {emailAddresses.sort().map((em) => {
                            return <p key={em}> {em}</p>;
                        })}
                    </Card>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BroadcastRecipientsModal;
