import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Col,
    FormControl,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CreateSegmentModal from '../Modals/CreateSegmentModal';
import { useSegments } from '../../hooks/useSegments';

const Segments: React.FC = () => {
    const { models, operations } = useSegments();
    const { loading, segments, keywords } = models;
    const { setKeywords, nextPage, previousPage, handleCreateSegment } = operations;

    const navigate = useNavigate();

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>
                <Row>
                    <Col style={{ textAlign: 'left' }}>
                        <h3>Segments</h3>
                    </Col>
                    <Col xs={'auto'}>
                        <FormControl
                            placeholder={'search...'}
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className="mb-2"
                        />
                    </Col>
                    <Col xs={'auto'}>
                        <CreateSegmentModal onCreate={handleCreateSegment} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>Name</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {segments &&
                                        segments.map((segment) => {
                                            return (
                                                <tr key={segment.segmentId}>
                                                    <td style={{ textAlign: 'left' }}>
                                                        {segment.name}
                                                    </td>

                                                    <td>
                                                        <Button
                                                            size="sm"
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                navigate(
                                                                    '/segment/' + segment.segmentId
                                                                )
                                                            }
                                                        >
                                                            <i className="bi bi-chevron-right"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {segments?.length === 0 && (
                                        <tr>
                                            <td colSpan={2}>
                                                <small>
                                                    {`There are no segments to display. `}
                                                    <span>
                                                        {keywords?.length === 0
                                                            ? `Please click 'New Segment' to create one.`
                                                            : `Please try changing your search criteria.`}
                                                    </span>
                                                </small>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>

                <ButtonGroup className="m-2">
                    <Button disabled={!previousPage} onClick={previousPage}>
                        Back
                    </Button>
                    <Button disabled={!nextPage} onClick={nextPage}>
                        Next
                    </Button>
                </ButtonGroup>
            </CardBody>
        </Card>
    );
};

export default Segments;
