import React from 'react';
import { Card } from 'react-bootstrap';
import MemberEvents from '../MemberEvents';
import { MemberEventProps } from '../MemberEvents/MemberEventProps';
import { useMemberEvents } from '../MemberEvents/useMemberEvents';
import MemberSectionContainer from '../MemberSectionContainer';
import CreateMemberEventModal from '../Modals/CreateMemberEventModal';

const MemberEventsSection: React.FC<MemberEventProps> = (props) => {
    const { models, operations } = useMemberEvents({ ...props });
    const { member } = models;
    const { handleReloadMemberEvents } = operations;

    return (
        <MemberSectionContainer
            title="Events"
            action={
                <CreateMemberEventModal
                    member={member}
                    onCreate={(name) => {
                        return new Promise((resolve, reject) => {
                            resolve(handleReloadMemberEvents());
                        });
                    }}
                />
            }
        >
            <Card
                style={{ maxHeight: '500px', overflowY: 'scroll', scrollbarWidth: 'none' }}
                className="mb-2"
            >
                <Card.Body>
                    <MemberEvents {...props} />
                </Card.Body>
            </Card>
        </MemberSectionContainer>
    );
};

export default MemberEventsSection;
