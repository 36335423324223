import { Card, CardBody, Col, FloatingLabel, Form, FormControl, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { IAppRequirement } from '../../interfaces/IApplicationRequirements';

interface Props {
    requirement: IAppRequirement;
    handleChange: (req: IAppRequirement) => void;
}

const DocumentRequirementPicker: React.FC<Props> = ({ requirement, handleChange }) => {
    const [documentRequirement, setDocumentRequirement] =
        React.useState<IAppRequirement>(requirement);

    const handleReqChange = (fieldName: string, fieldValue: any) => {
        setDocumentRequirement((prevState) => {
            return {
                ...prevState,
                [fieldName]: fieldValue,
            };
        });
    };

    useEffect(() => {
        documentRequirement && handleChange(documentRequirement);
    }, [documentRequirement]);

    return (
        <Card className={'mb-1'}>
            <CardBody>
                <Card.Title>{documentRequirement.title}</Card.Title>
                <Row>
                    <Col xs={4}>
                        <FloatingLabel label={'Document title'}>
                            <FormControl
                                className={'h-auto'}
                                name={'title'}
                                type={'text'}
                                value={documentRequirement.title}
                                onChange={(e) => handleReqChange('title', e.target.value)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <Form.Check
                            className={'mt-2'}
                            name={'required'}
                            checked={documentRequirement.required}
                            onChange={(e) => handleReqChange('required', e.currentTarget.checked)}
                            label={'Required document?'}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default DocumentRequirementPicker;
