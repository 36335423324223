import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { GET_ACCREDITATION } from '../../graphql/accreditation/queries';
import { UPDATE_ACCREDITATION } from '../../graphql/accreditation/mutations';
import {
    Accreditation,
    UpdateAccreditationCommandInput,
} from '../../graphql/__generated__/graphql';
import UpdateAccreditationModal from '../Modals/UpdateAccreditationModal';

export const useAccreditationCard = ({ ...props }) => {
    const { accreditationId } = props;

    const { data, error, loading, refetch } = useQuery(GET_ACCREDITATION, {
        variables: { id: accreditationId },
    });

    const [updateAccreditation] = useMutation(UPDATE_ACCREDITATION);

    const [accreditation, setAccreditation] = useState<Accreditation>();

    const handleUpdateAccreditation = (updatedAccreditation: Accreditation) => {
        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                updateAccreditation({
                    variables: {
                        accreditation: {
                            accreditationId: updatedAccreditation.accreditationId,
                            endDate: updatedAccreditation.endDate,
                        } as UpdateAccreditationCommandInput,
                    },
                }).then(() => {
                    refetch({ id: updatedAccreditation.accreditationId });
                    console.log(data);
                })
            );
        });
    };

    const isExpired = (): boolean => {
        if (accreditation && accreditation.endDate) {
            const currentDate = new Date();
            const endDate = new Date(accreditation.endDate);
            return currentDate > endDate;
        }
        return false;
    };

    useEffect(() => {
        console.log('data', data);

        if (!loading && !error) {
            setAccreditation(data.accreditation);
        }
    });

    return {
        models: {
            accreditation,
        },
        operations: {
            handleUpdateAccreditation,
            isExpired,
        },
    };
};

interface IAccreditationCard {
    accreditationId: number;
}

const AccreditationCard: React.FC<IAccreditationCard> = ({ accreditationId }) => {
    const { models, operations } = useAccreditationCard({ accreditationId });
    const { accreditation } = models;
    const { handleUpdateAccreditation, isExpired } = operations;

    return (
        <Card key={accreditation?.accreditationId} style={{ opacity: isExpired() ? 0.6 : 1 }}>
            <Card.Body>
                <div>
                    <h5>
                        {isExpired() ? (
                            <Badge pill bg="danger" className="p-0 me-2">
                                <i className="bi bi-x"></i>
                            </Badge>
                        ) : (
                            <Badge pill bg="success" className="p-0 me-2">
                                <i className="bi bi-check"></i>
                            </Badge>
                        )}
                        <strong>{accreditation?.accreditationType?.name}</strong>
                    </h5>
                    <small>{isExpired() ? 'Not Accredited' : 'Accredited'}</small>
                    <p className="mb-2">
                        <Badge bg={isExpired() ? 'danger' : 'primary'}>
                            {isExpired() ? 'Expired' : 'Expires'}{' '}
                            {new Date(accreditation?.endDate).toLocaleDateString('en-GB')}
                        </Badge>
                    </p>

                    <UpdateAccreditationModal
                        accreditation={accreditation}
                        onUpdate={handleUpdateAccreditation}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

export default AccreditationCard;
