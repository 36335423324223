import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { CREATE_EVENT_PARTICIPANT } from '../../../graphql/event/mutations';
import { CreateEventParticipantCommandInput } from '../../../graphql/__generated__/graphql';
import { useEvents } from '../../../hooks/useEvents';
import { CreateMemberEventModalProps } from './CreateMemberEventModalProps';

export const useCreateMemberEvent = ({ ...props }) => {
    var { onCreate, member } = { ...props } as CreateMemberEventModalProps;

    const [createEventParticipant] = useMutation(CREATE_EVENT_PARTICIPANT);

    const { models } = useEvents();
    const { events } = models;

    const [show, setShow] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [eventId, setEventId] = useState<string>('');

    const handleSave = () => {
        setInProgress(true);

        return new Promise((resolve, reject) => {
            resolve(
                createEventParticipant({
                    variables: {
                        eventParticipant: {
                            eventId: eventId,
                            emailAddress: member.email,
                            memberId: member.memberId,
                            quantity: 1,
                            purchaseResult: '',
                        } as CreateEventParticipantCommandInput,
                    },
                }).then((response) => {
                    console.log('create event participant response', response);

                    handleClose();
                    setEventId('');
                    setInProgress(false);
                    onCreate('').then(() => {});
                })
            );
        });
    };

    const handleClose = () => {
        setShow(false);
        setInProgress(false);
    };

    const handleShow = () => setShow(true);

    return {
        models: {
            inProgress,
            show,
            eventId,
            events,
        },
        operations: {
            handleShow,
            handleClose,
            handleSave,
            setEventId,
        },
    };
};
