import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { UPDATE_MEMBER_DOCUMENT_APPROVAL } from '../graphql/member/mutations';
import { AccreditationApplication, UpdateMemberDocumentCommandInput } from '../graphql/__generated__/graphql';
import { ApprovalRequest } from '../interfaces/ApprovalRequest';
import { DocumentUploadRequest } from '../interfaces/DocumentUploadRequest';
import { UploadResponse } from '../interfaces/UploadResponse';

const DOCUMENT_API = process.env.REACT_APP_DOCUMENTAPI_ENDPOINT;

const useDocuments = () => {
    const auth = useAuth();
    const [error, setError] = useState<string | null>(null);
    const [updateDocumentApproval] = useMutation(UPDATE_MEMBER_DOCUMENT_APPROVAL);

    const handleUpdateDocumentApproval = (
        memberId: string,
        documentId: string,
        approve: boolean,
        cpd: number
    ) => {
        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                updateDocumentApproval({
                    variables: {
                        memberDocument: {
                            approved: approve === true,
                            rejected: approve === false,
                            cpd: cpd,
                            documentId,
                            memberId,
                        } as UpdateMemberDocumentCommandInput,
                    },
                })
            );
        });
    };

    const uploadFile = async (request: DocumentUploadRequest): Promise<UploadResponse | null> => {
        try {
            const formData = new FormData();
            formData.append('file', request.file);
            formData.append('filename', request.fileName);
            formData.append('name', request.name);
            formData.append('description', request.description);
            formData.append('cpd', request.cpd?.toString() ?? '0');
            formData.append('expires', request.expires?.toISOString() ?? '');
            formData.append('memberId', request.memberId ?? '');
            formData.append('eventId', request.eventId ?? '');

            const response = await fetch(`${DOCUMENT_API}/Upload`, {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    Authorization: 'Bearer ' + auth.user?.access_token,
                }),
            });

            if (!response.ok) {
                throw new Error('File upload failed.');
            }

            const data: UploadResponse = await response.json();
            return data;
        } catch (error) {
            console.log('Error uploading file.', error);

            setError('Error uploading file.');
            return null;
        }
    };

    const getFile = async (endpoint: string): Promise<Response> => {
        const response = await fetch(endpoint, {
            headers: new Headers({
                Authorization: 'Bearer ' + auth.user?.access_token,
            }),
        });

        if (!response.ok) {
            throw new Error('File download failed.');
        }

        return response;
    }

    const downloadFile = async (blob: Blob, filename: string): Promise<void> => {
        try {
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = filename ?? 'download-file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError('Error downloading file.');
        }
    }

    const downloadApplicationTemplate = async (application: AccreditationApplication) => {
        const endpoint = process.env.REACT_APP_PDFAPI_ENDPOINT + '/' + application.accreditationApplicationId;
        const response = await getFile(endpoint);
        return response.arrayBuffer();
    }

    const downloadDocument = async (documentId: string, eventId?: string): Promise<void> => {
        const endpoint = eventId
            ? `${DOCUMENT_API}/Event/${eventId}/Download/${documentId}`
            : `${DOCUMENT_API}/Member/Download/${documentId}`;

        const response = await getFile(endpoint);

        const filename = extractFilename(response);

        const blob = await response.blob();

        return downloadFile(blob, filename ?? "download");
    };

    const previewFile = async (eventId: string, html: string): Promise<void> => {
        try {
            const endpoint = `${DOCUMENT_API}/Event/${eventId}/Download/Preview`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + auth.user?.access_token,
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({ html }),
            });

            if (!response.ok) {
                throw new Error('File preview failed.');
            }

            let reg = /filename=(.*);/ig;
            let s = response.headers.get("Content-Disposition");
            let filename = s?.match(reg)?.[0].replace("filename=", "")
                .replaceAll('"', "")
                .replaceAll(";", "");

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = filename ?? "preview-file";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError('Error generating preview file.');
        }
    };

    const extractFilename = (response: Response) => {
        let reg = /filename=(.*);/gi;
        let s = response.headers.get('Content-Disposition');
        return s
            ?.match(reg)?.[0]
            .replace('filename=', '')
            .replaceAll('"', '')
            .replaceAll(';', '');
    }

    const removeFile = async (documentId: string, eventId: string): Promise<void> => {
        const response = await fetch(`${DOCUMENT_API}/Event/${eventId}/Remove/${documentId}`, {
            method: 'DELETE',
            headers: new Headers({
                Authorization: 'Bearer ' + auth.user?.access_token,
            }),
        });

        if (!response.ok) {
            throw new Error('File remove failed.');
        }
    };

    const approve = async (request: ApprovalRequest): Promise<unknown> => {
        return handleUpdateDocumentApproval(
            request.memberId,
            request.documentId,
            true,
            request.cpd
        );
    };
    const reject = async (request: ApprovalRequest): Promise<unknown> => {
        return handleUpdateDocumentApproval(
            request.memberId,
            request.documentId,
            false,
            request.cpd
        );
    };

    return {
        uploadFile,
        downloadFile,
        downloadDocument,
        downloadApplicationTemplate,
        extractFilename,
        getFile,
        previewFile,
        removeFile,
        approve,
        reject,
        error,
    };
};

export default useDocuments;
