import React from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import MembershipTypeSelect from '../../Form/MembershipTypeSelect/MembershipTypeSelect';
import { UpdateMembershipModalProps } from './UpdateMembershipModalProps';
import { useUpdateMembership } from './useUpdateMembership';

const UpdateMembershipModal: React.FC<UpdateMembershipModalProps> = ({ ...props }) => {
    const { membership } = { ...props } as UpdateMembershipModalProps;
    const { models, operations } = useUpdateMembership({ ...props });
    const { membershipTypeId, endDate, show, loading } = models;
    const { handleShow, handleClose, handleSave, setMembershipTypeId, setEndDate } = operations;

    return membership ? (
        <>
            <Button variant="outline-primary" onClick={handleShow} size="sm">
                <i className="bi bi-pencil me-1"></i>
                Change
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{membership.membershipType.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Expiry Date</Form.Label>
                        <Form.Control
                            type="date"
                            required
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group style={{ marginTop: '1em' }}>
                        <Form.Label>Membership Type</Form.Label>
                        <MembershipTypeSelect
                            name="membershipType"
                            value={membershipTypeId}
                            onChange={(e) => {
                                setMembershipTypeId(e.target.value);
                            }}
                        />
                    </Form.Group>

                    <Alert variant="info" style={{ marginTop: '1em' }}>
                        Please note, changes to a membership will modify the date and amount of the
                        member's payments.
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={loading}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    ) : (
        <Button variant="outline-primary" disabled>
            Change
        </Button>
    );
};

export default UpdateMembershipModal;
