import React from 'react';
import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SegmentPreviewModalProps } from './SegmentPreviewModalProps';
import { useSegmentPreview } from './useSegmentPreview';

const SegmentPreviewModal: React.FC<SegmentPreviewModalProps> = ({ ...props }) => {
    const { segmentId, name } = { ...props } as SegmentPreviewModalProps;
    const { models, operations } = useSegmentPreview({ ...props });
    const { show, members } = models;
    const { handleShow, handleClose, data, error, resultsLoading } = operations;

    const navigate = useNavigate();

    return (
        <>
            <Button variant="outline-secondary" onClick={handleShow}>
                <i className="bi bi-play me-1"></i>Preview
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview {name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                        {error !== undefined && <p>{error.message}</p>}
                        {resultsLoading === true ? (
                            <Spinner />
                        ) : (
                            <Table>
                                <tbody>
                                    {members &&
                                        members.map((member) => {
                                            return (
                                                <tr key={member.memberId}>
                                                    <td>{member.firstname}</td>
                                                    <td>{member.lastname}</td>
                                                    <td>
                                                        <Button
                                                            size="sm"
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                navigate(
                                                                    '/member/' + member.memberId
                                                                )
                                                            }
                                                        >
                                                            <i className="bi bi-chevron-right"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        )}

                        {resultsLoading === false && members.length === 0 && (
                            <p>No members meet the segment's conditions</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Showing {members && members.length} results</p>
                    <Button variant="secondary" onClick={handleClose}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SegmentPreviewModal;
