import { useEffect, useState } from 'react';
import { ProfessionItem } from '../../../graphql/__generated__/graphql';

export const useCoreProfessions = ({ data }: any) => {
    const [professions, setProfessions] = useState<ProfessionItem[]>();

    useEffect(() => {
        if (data?.professions && !professions) {
            handleLoadProfessions();
        }
    }, [data]);

    const handleLoadProfessions = async (): Promise<void> => {
        try {
            setProfessions(
                data.professions.map((x: ProfessionItem) => {
                    return {
                        professionId: x.professionId,
                        name: x.name,
                    };
                }) as ProfessionItem[]
            );
        } catch (error) {
            console.error('Error processing professions:', error);
        }
    };
    return {
        models: { professions },
        operations: { handleLoadProfessions },
    };
};
