import { useEffect, useState } from 'react';
import { RegionItem } from '../../../graphql/__generated__/graphql';

export const useRegions = ({ data }: any) => {
    const [regions, setRegions] = useState<RegionItem[]>();

    useEffect(() => {
        if (data?.regions && !regions) handleLoadRegions();
    });

    const handleLoadRegions = () => {
        try {
            setRegions(
                data.regions.map((x: RegionItem) => {
                    return {
                        regionId: x.regionId,
                        name: x.name,
                    };
                }) as RegionItem[]
            );
        } catch (error) {
            console.error('Error processing regions:', error);
        }
    };

    return {
        models: { regions },
    };
};
