import { useEffect, useState } from 'react';
import { BroadcastRecipientsModalProps } from './BroadcastRecipientsModalProps';

export const useBroadcastRecipients = ({ ...props }) => {
    var { broadcast } = { ...props } as BroadcastRecipientsModalProps;

    const [show, setShow] = useState(false);

    const [emailAddresses, setEmailAddresses] = useState<string[]>([]);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => setShow(true);

    useEffect(() => {
        if (broadcast.recipients)
            setEmailAddresses(broadcast.recipients.map((x) => x.emailAddress));
    }, [broadcast]);

    return {
        models: {
            emailAddresses,
            show,
        },
        operations: {
            handleShow,
            handleClose,
        },
    };
};
