import {PDFArray, PDFCheckBox, PDFDocument, PDFForm, PDFName, PDFTextField, TextAlignment} from "pdf-lib";
import {
  AccreditationApplication,
  ApplicationReview,
  Maybe,
  ReviewerRole,
  Scalars
} from "../graphql/__generated__/graphql";
import _ from "lodash";
import useDocuments from "./useDocuments";
import {useNotificationContext} from "../contexts/NotificationContext";

const pdfMappings = {
  applicant: {
    name: "Name",
    address1: "Address 1",
    address2: "Address 2",
    address3: "Address 3",
    email: "Email",
    qualifications: "fill_10",
    coreProfessions: "Core Profession",
    mobileTel: "Mobile Tel",
    daytimeTel: "Daytime Tel",
    eveningTel: "Evening Tel",
  },
  clinicalContacts: {
    initials: "CLIENT INITIALS  SUPERVISOR INITIALS",
    startDate: "START DATE OF EMDR",
    presentingProblem: "PRESENTING PROBLEM PLEASE NOTE CLIENT AGE",
    numberOfSessions: "NUMBER OF SESSIONS OF 8 PHASES OF EMDR",
    treatmentSetting: "SETTING WHERE TREATMENT TOOK PLACE"
  },
  evaluation: {
    partA: "Supervisee demonstrates a grounded understanding of the theoretical basis of EMDR and the Adaptive Information Processing AIP Model and is able to convey this effectively to clients in providing a treatment overview",
    partB: {
      historyTaking: "fill_2",
      preparation: "Obtains informed consent from clients Tests Bilateral Stimulation BLS with clients Teaches and checks clients ability to selfregulate including use of safesecure place and resourceinstallation Makes clients aware of the Stop signal Demonstrates effective ability to address client concerns fears queries or anxieties Using effective metaphors The supervisee is able to establish an effective therapeutic relationship consistent with National or Professional standards and Code of Conduct 2 PREPARATION",
      assessment: "fill_2_2",
      desensitisation: "Reminds clients to just notice whatever comes up during processing while encouraging client not to disregard any information that might be generated Explains that changes during processing can relate to images sounds cognitions emotions and physical sensations Demonstrates competency in the provision of Bilateral Stimulation BLS emphasising the importance of eye movements Uses appropriate postset interventions and shows evidence of staying out of the way as much as possible Reassures client verbally  nonverbally during each set Maintains momentum throughout the desensitisation stage with minimal intervention where possible Returns to target when appropriate When processing becomes blocked uses appropriate interventions including alteration in Bilateral Simulation andor the use of Cognitive Interweaves Please specify examples of effective Cognitive Interweaves used during the Desensitisation Phase when processing has become blocked Effectively manages heightened levels of client affect using both accelerating and deaccelerating interventions During the Desensitisation Phase the supervisee processes the dysfunctional material stored in all channels associated with the target event and any ancillary channels 4 DESENSITISATION",
      installation: "fill_1",
      bodyScan: "The supervisee enables clients to hold both the memoryevent and the Positive Cognition in mind while mentally scanning their entire body to identify any lingering tension tightness or unusual sensation and applies Bilateral Stimulation BLS The supervisee is prepared for further material to surface and to respond accordingly by either returning to the most appropriate phase of the EMDR Protocol or using the Incomplete Session During the Body Scan Phase the supervisee considers the link between the clients original memoryevent and the discernible physical resonance that this may generate 6 BODY SCAN",
      closure: "Allows time for closure Uses the debrief Effectively uses the Incomplete Session Uses appropriate containment exercises and safety assessment Encourages clients to maintain a log between sessions The Supervisee should consistently close a session with proper instruction leaving the client in a positive frame of mind and able to return safely home 7 CLOSURE",
      reevaluation: "fill_1_2",
    },
    partC: "Supervisee demonstrates an understanding of PTSD and traumatology Supervisee demonstrates an understanding of the use of EMDR either as part of a comprehensive therapy intervention or as a means of symptom reduction Supervisee demonstrates experience in applying the standard EMDR protocol and procedures to special situations and clinical problems including recent events phobias excessive grief and somatic disorders",
    partD: {
      hoursIndividual: "Face to face individual  Specify hours",
      hoursGroup: "Face to face group  Specify hours",
      hoursTelephone: "TelephoneSkype  Specify hours",
      hoursEmail: "Email  Specify hours",
      hoursOther: "Other  Specify hours",
      reasons: "Face to face individual  Specify hours Face to face group  Specify hours TelephoneSkype  Specify hours Email  Specify hours Other  Specify hours2 Please specify your reasons for recommending your supervisees accreditation as an EMDR Europe Practitioner"
    }
  },
  experience: {
    yearsExperience: "Training At least one year is required post completion of EMDR Standard Training",
    sessionsConducted: "SupervisorPlease provide details using the record form below under Section III",
    clientsTreated: "supervised the listed clients Please provide details using the record form below under Section III",
    hoursOfSupervision: "discussed solely at the training",
    supervisorWitnessed: "toggle_10",
  },
  supervisor: {
    name: "Supervisor Name",
    email: "Email Address",
    startDate: "Start date",
    endDate: "End date",
    ongoing: "Ongoing",
    firstAccreditation: "Date of 1st accreditation",
    mostRecentAccreditation: "Date of most recent accreditation",
  }
}

const usePdfApplicationGenerator = (): any => {

  let pdfForm: PDFForm;
  let application: AccreditationApplication;
  let primarySupervisorReview: ApplicationReview;
  let applicationJson: NodeJS.Dict<any>;

  const { downloadApplicationTemplate, downloadFile, extractFilename, getFile } = useDocuments();
  const { error, success } = useNotificationContext()

  const _isMobileTel = (num: string) => {
    return num.startsWith("07") || num.startsWith("+447") || num.startsWith("447") || num.startsWith("+4407");
  }

  const _formatDate = (date: Date | string) => {
    const dateOk = date instanceof Date ? date : new Date(date);
    return date ? dateOk.toLocaleDateString() : '-';
  }

  const _populateApplicantDetails = () => {
    const member = application.member;

    if (!member) {
      console.error("Something went horribly wrong...");
      return;
    }

    const address = _.first(member.addresses)

    if (!address) {
      console.error("Something went horribly wrong...");
      return;
    }

    const {
      name,
      address1,
      address2,
      address3,
      coreProfessions,
      daytimeTel,
      eveningTel,
      mobileTel,
      email,
      qualifications
    } = pdfMappings.applicant;

    _updateTextField(name, member.firstname + " " + member.lastname);
    _updateTextField(address1, address.addressLine1 + ", " + address.addressLine2);
    _updateTextField(address2, "" + (address.city ?? address.town));
    _updateTextField(address3, address.postcode);
    _updateTextField(email, member.email);
    _updateTextField(qualifications, member.qualifications);
    _updateTextField(coreProfessions, member.professions.map(p => p.name).join(", "));
    
    const mobileTelVal = _.find(member.telephones, t => _isMobileTel(t.number));
    const otherTelVal = _.find(member.telephones, t => t !== mobileTelVal);
    _updateTextField(mobileTel, mobileTelVal?.number);
    _updateTextField(daytimeTel, otherTelVal?.number);
    _updateTextField(eveningTel, "-");
  }

  const _populateSupervisorDetails = () => {

    const primarySupervisorMember = primarySupervisorReview?.reviewer;

    if (!primarySupervisorMember) {
      console.error("Something went horribly wrong");
      return;
    }

    const supervisors: any[] = applicationJson["supervisors"];
    const primarySupervisor = supervisors.find(s => s.primary);

    const {
      name,
      mostRecentAccreditation,
      firstAccreditation,
      ongoing,
      email,
      endDate,
      startDate
    } = pdfMappings.supervisor;

    const supervisorAccreditations = _.sortBy(primarySupervisorMember.accreditations.map(a => a.startDate), "accreditationStartedDate");

    _updateTextField(name, primarySupervisorMember.firstname + " " + primarySupervisorMember.lastname);
    _updateTextField(email, primarySupervisorMember.email);
    _updateTextField(startDate, _formatDate(primarySupervisor.startDate));
    _updateTextField(endDate, _formatDate(primarySupervisor.endDate));
    _updateTextField(ongoing, primarySupervisor.ongoing ? "Yes" : "No");
    _updateTextField(mostRecentAccreditation, _formatDate(_.first(supervisorAccreditations)));
    _updateTextField(firstAccreditation, _formatDate(_.last(supervisorAccreditations)));
  }

  const _populateCriteriaDetails = () => {
    for (let i = 1; i <= 14; i++) {
      const iString = `${i}`
      const result = _updateCheckboxField(iString);
      if (!result) {
        const field = pdfForm.getFields().find(f => f.getName().startsWith(iString))
        if (field) _updateCheckboxField(field.getName());
      }
    }


    const experiences: any[] = applicationJson.experiences;
    const {
      yearsExperience, clientsTreated, hoursOfSupervision, sessionsConducted, supervisorWitnessed
    } = pdfMappings.experience;

    for (const exp of experiences) {
      const { name, value } = exp;

      switch (name) {
        case "years-experience":
          _updateTextField(yearsExperience, value);
          continue;
        case "sessions-conducted":
          _updateTextField(sessionsConducted, value);
          continue;
        case "clients-treated":
          _updateTextField(clientsTreated, value);
          continue;
        case "hours-of-supervision":
          _updateTextField(hoursOfSupervision, value);
          continue;
        case "supervisor-witnessed":
          _updateCheckboxField(supervisorWitnessed, value);
      }
    }
  }

  const _populateClientDetails = () => {
    const clients: any[] = applicationJson["clinicalContacts"];

    const {
      numberOfSessions, treatmentSetting, presentingProblem, startDate, initials
    } = pdfMappings.clinicalContacts;

    let index = 1;

    for (const client of clients) {

      const startDateStr = _formatDate(client["startDate"]);
      if (index === 1) {
        _updateTextField(startDate + "1", startDateStr);
      } else if (index === 2) {
        _updateTextField("a2", startDateStr);
      } else if (index === 3) {
        _updateTextField("a1", startDateStr);
      } else {
        _updateTextField("a" + (index - 1), startDateStr);
      }

      _updateTextField(numberOfSessions + index, client["numberOfSessions"]);
      _updateTextField(treatmentSetting + index, client["treatmentSetting"]);
      _updateTextField(presentingProblem + index, client["presentingProblem"]);

      const temporarySolution = client.initials + "             " + client.supervisor?.name.split(" ").map((i: string) => i[0]).join("");
      _updateTextField(initials + index, temporarySolution);
      // updateTextField(initials + index, client.supervisor?.name.split(" ").map((i: string) => i[0]).join(""));

      index++;
    }
  }

  const _populateEvaluationDetails = () => {

    const reviewContent = JSON.parse(primarySupervisorReview.jsonData || "{}");

    if (!reviewContent || Object.keys(reviewContent).length === 0) {
      console.error("No review content");
      return;
    }

    const {
      partA,
      partB,
      partC,
      partD
    } = pdfMappings.evaluation;

    // Part A
    _updateTextField(partA, reviewContent.partA.value);

    // Part B
    for (const { name, value } of reviewContent.partB as any[]) {
      switch (name) {
        case "historyTaking":
          _updateTextField(partB.historyTaking, value.value);
          continue;
        case "preparation":
          _updateTextField(partB.preparation, value.value);
          continue;
        case "assessment":
          _updateTextField(partB.assessment, value.value);
          continue;
        case "desensitisation":
          _updateTextField(partB.desensitisation, value.value);
          continue;
        case "installation":
          _updateTextField(partB.installation, value.value);
          continue;
        case "bodyScan":
          _updateTextField(partB.bodyScan, value.value);
          continue;
        case "closure":
          _updateTextField(partB.closure, value.value);
          continue;
        case "reevaluation":
          _updateTextField(partB.reevaluation, value.value);
      }
    }

    // Part C
    _updateTextField(partC, reviewContent.partC.value);

    // Part D
    const {
      hoursIndividual, hoursGroup, hoursOther, hoursTelephone, hoursEmail, reasons
    } = partD

    const partValues = reviewContent.partD.value;

    _updateTextField(hoursEmail, partValues.hoursEmail);
    _updateTextField(hoursTelephone, partValues.hoursTelephone);
    _updateTextField(hoursIndividual, partValues.hoursIndividual);
    _updateTextField(hoursOther, partValues.hoursOther);
    _updateTextField(hoursGroup, partValues.hoursGroup);
    _updateTextField(reasons, partValues.reasons);

  }

  const _addPdfAttachment = async (pdfDoc: PDFDocument, dataBuffer: ArrayBuffer) => {
    // If it's a PDF
    const attachmentPdf = await PDFDocument.load(dataBuffer);
    const pagesToCopy: number[] = _.range(0, attachmentPdf.getPages().length);
    const copiedPages = await pdfDoc.copyPages(attachmentPdf, pagesToCopy);
    for (const copiedPage of copiedPages) {
      pdfDoc.addPage(copiedPage);
    }
  }

  const _addImageAttachment = async (pdfDoc: PDFDocument, dataBuffer: ArrayBuffer, fileExt?: string) => {

    const _embedImage = async () => {
      switch (fileExt) {
        case "png": return pdfDoc.embedPng(dataBuffer);
        case "jpg":
        case "jpeg": return pdfDoc.embedJpg(dataBuffer);
        default: throw new Error("Can only attach png and jpg images to application");
      }
    }

    const img = await _embedImage();
    const newPage = pdfDoc.addPage();
    const { width, height } = img.scaleToFit(newPage.getWidth(), newPage.getHeight());

    newPage.drawImage(img, {
      height,
      width
    });
  }

  const _addAttachments = async (pdfDoc: PDFDocument) => {
    const documents: any[] = applicationJson.documents;

    return new Promise<void>(async resolve => {
      let hasErrors = false;
      for (const document of documents) {
        try {
          const response = await getFile(`${process.env.REACT_APP_DOCUMENTAPI_ENDPOINT}/${document.value}`);
          const filename = extractFilename(response) || "";
          const extension = _.last(filename.split("."));
          const dataBuffer = await response.arrayBuffer();
           try {
             if (extension === "pdf") {
               await _addPdfAttachment(pdfDoc, dataBuffer);
             } else {
               await _addImageAttachment(pdfDoc, dataBuffer, extension);
             }

           } catch (e) {
             console.error("Failed to attach supporting document with ID " + document.value + " to application.", e)
             error("Failed to attach supporting document " + document.name + " to application: " + e);
             hasErrors = true;
           }
        } catch (err) {
          console.error("Failed to download supporting document with ID " + document.value, err);
          error("Failed to get supporting document " + document.name + " for application");
          hasErrors = true;
        }
      }

      if (hasErrors) {
        success("Application PDF generated and downloaded however it is missing some attachments - please see logs for details");
      } else {
        success("Application PDF successfully generated and downloaded.")
      }

      resolve();
    });
  }

  const _updateTextField = (fieldName: string, value?: Maybe<Scalars["String"]["output"]>) => {
    const pdfField = pdfForm.getFieldMaybe(fieldName);
    if (!pdfField) return;
    const textField = pdfField as PDFTextField;
    textField.setAlignment(TextAlignment.Left);
    const rect = textField.acroField.dict.lookup(PDFName.of("Rect"), PDFArray); //determine the y-axis adjustment of this
    textField.enableMultiline();
    textField.setText(value ?? '-');
    textField.enableReadOnly();
  }

  const _updateCheckboxField = (fieldName: string, value: boolean = true) => {
    const pdfField = pdfForm.getFieldMaybe(fieldName);
    if (!pdfField) return false;

    const checkboxField = pdfField as PDFCheckBox;
    value ? checkboxField.check() : checkboxField.uncheck();
    checkboxField.enableReadOnly();
    return true;
  }

  const generatePdf = async (accreditationApplication: AccreditationApplication) => {
    const templateBytes = await downloadApplicationTemplate(accreditationApplication);
    const pdfDoc = await PDFDocument.load(templateBytes);

    pdfForm = pdfDoc.getForm();
    application = accreditationApplication;
    applicationJson = JSON.parse(application.jsonData || "{}");
    const review = application.reviews?.find(r => r?.reviewerRole === ReviewerRole.PrimarySupervisor);

    if (!review) {
      error("Unable to generate application without a primary supervisor evaluation");
      return;
    }
    primarySupervisorReview = review;

    _populateApplicantDetails();
    _populateSupervisorDetails();
    _populateCriteriaDetails();
    _populateClientDetails();
    _populateEvaluationDetails();
    await _addAttachments(pdfDoc);

    pdfForm.flatten();
    const filename = application.member?.firstname + "-" + application.member?.lastname + "-application.pdf";
    const pdfArray = await pdfDoc.save();
    const blob = new Blob([pdfArray], { type: 'application/pdf' });
    return downloadFile(blob, filename);
  }

  return {
    generatePdf
  };
}

export default usePdfApplicationGenerator;