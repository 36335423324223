import React, { PropsWithChildren, useContext } from 'react';
import ToastNotification, { ToastMessage } from '../components/ToastNotification';
import { FeedbackLevel } from '../interfaces/FeedbackLevel';

export interface INotificationContext {
    toastMessage?: ToastMessage;
    success: (message: string | string[]) => void;
    error: (message: string | string[]) => void;
    notify: (level: FeedbackLevel, message: string | string[]) => void;
}

export const NotificationContext = React.createContext<INotificationContext | undefined>(undefined);
NotificationContext.displayName = 'NotificationContext';

export function useNotificationContext() {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotificationContext must be used within NotificationContextProvider');
    }
    return context;
}

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [toastMessage, setToastMessage] = React.useState<ToastMessage>();

    return (
        <NotificationContext.Provider
            value={{
                toastMessage,
                success: (message: string | string[]) => {
                    setToastMessage({
                        message,
                        level: 'info',
                        id: new Date().getTime(),
                    });
                },
                error: (message: string | string[]) => {
                    setToastMessage({
                        message,
                        level: 'danger',
                        id: new Date().getTime(),
                    });
                },
                notify: (level: FeedbackLevel, message: string | string[]) => {
                    setToastMessage({
                        message,
                        level,
                        id: new Date().getTime(),
                    });
                },
            }}
        >
            {children}
            {toastMessage && <ToastNotification {...toastMessage} />}
        </NotificationContext.Provider>
    );
};
