import { useMutation, useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DELETE_SEGMENT, UPDATE_SEGMENT } from '../../graphql/segment/mutations';
import { GET_SEGMENT, GET_SEGMENT_PROPERTIES } from '../../graphql/segment/queries';
import {
    DeleteSegmentCommandInput,
    SegmentAndInput,
    SegmentConfiguration,
    SegmentConfigurationInput,
    SegmentOrInput,
    SegmentProperty,
    SegmentUpdateModel,
    UpdateSegmentCommandInput,
} from '../../graphql/__generated__/graphql';

export const useSegment = ({ ...props }) => {
    const navigate = useNavigate();
    const { segmentId } = props;

    const [segment, setSegment] = useState<SegmentUpdateModel>();
    const [properties, setProperties] = useState<SegmentProperty[] | undefined>();

    const [membershipTypeId, setMembershipTypeId] = useState<string>();

    const { data, error, loading } = useQuery(GET_SEGMENT, {
        variables: { id: segmentId },
        fetchPolicy: 'network-only',
    });

    const { data: segmentPropertiesData } = useQuery<{ segmentProperties: SegmentProperty[] }>(
        GET_SEGMENT_PROPERTIES
    );

    const [deleteSegment] = useMutation(DELETE_SEGMENT);
    const [updateSegment] = useMutation(UPDATE_SEGMENT);

    const getNewSegmentCondition = () => {
        return {
            id: new Date().getTime().toString(),
            propertyType: '',
            propertyName: '',
            propertyValue: '',
        } as SegmentOrInput;
    };
    const getConfigurationInput = (configuration: SegmentConfiguration) => {
        return {
            segmentAnd: configuration.segmentAnd.map(
                (and) =>
                    ({
                        segmentOr: and.segmentOr.map(
                            (or) =>
                                ({
                                    id: or.id,
                                    propertyType: or.propertyType,
                                    propertyName: or.propertyName,
                                    propertyValue: or.propertyValue,
                                }) as SegmentOrInput
                        ),
                    }) as SegmentAndInput
            ),
        } as SegmentConfigurationInput;
    };

    const handleDeleteSegment = () => {
        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                deleteSegment({
                    variables: {
                        segment: {
                            segmentId,
                        } as DeleteSegmentCommandInput,
                    },
                }).then(() => {
                    navigate('/segments');
                })
            );
        });
    };

    const handleDeleteSegmentOr = (id?: string) => {
        let s = cloneDeep(segment);
        let configuration = cloneDeep(s?.configuration);

        if (configuration && s) {
            configuration.segmentAnd.forEach((and, i) => {
                and.segmentOr = and.segmentOr.filter((x) => x.id !== id);

                // If empty, remove the entire segmentAnd
                if (and.segmentOr.length === 0) {
                    configuration?.segmentAnd.splice(i, 1);
                }
            });

            s.configuration = configuration;
            setSegment(s);

            handleUpdateSegment(getConfigurationInput(configuration));
        }
    };

    const handleUpdateSegmentOr = (id: string, property: string, value: string) => {
        let s = cloneDeep(segment);
        let configuration: SegmentConfigurationInput = cloneDeep(
            s?.configuration
        ) as SegmentConfigurationInput;

        if (configuration && s) {
            configuration.segmentAnd.forEach((and) => {
                and.segmentOr.forEach((or) => {
                    if (or.id === id) {
                        (or as any)[property] = value;
                    }
                });
            });

            s.configuration = configuration;
            setSegment(s);

            handleUpdateSegment(getConfigurationInput(configuration));
        }
    };

    const handleCreateSegmentAnd = () => {
        let s = cloneDeep(segment);
        let configuration = cloneDeep(s?.configuration);

        if (configuration && s) {
            configuration.segmentAnd.push({
                segmentOr: [getNewSegmentCondition()],
            } as SegmentAndInput);

            s.configuration = configuration;
            setSegment(s);

            handleUpdateSegment(getConfigurationInput(configuration));
        }
    };

    const handleCreateSegmentOr = (afterId?: string) => {
        let s = cloneDeep(segment);
        let configuration = cloneDeep(s?.configuration);

        if (configuration && s) {
            configuration.segmentAnd.forEach((and) => {
                and.segmentOr.forEach((or) => {
                    if (or.id === afterId) {
                        and.segmentOr.push(getNewSegmentCondition());
                    }
                });
            });

            s.configuration = configuration;
            setSegment(s);

            handleUpdateSegment(getConfigurationInput(configuration));
        }
    };

    const handleUpdateSegment = (config: SegmentConfigurationInput) => {
        return new Promise((resolve, reject) => {
            if (error) reject(error);

            resolve(
                updateSegment({
                    variables: {
                        segment: {
                            segmentId,
                            segmentConfiguration: config,
                        } as UpdateSegmentCommandInput,
                    },
                })
            );
        });
    };

    useEffect(() => {
        if (!loading && !error) {
            setSegment(data.segment);
        }
    }, [data]);

    useEffect(() => {
        if (segmentPropertiesData && segmentPropertiesData.segmentProperties)
            setProperties(segmentPropertiesData.segmentProperties);
    }, [segmentPropertiesData]);

    return {
        models: {
            segment,
            membershipTypeId,
            properties,
        },
        operations: {
            setMembershipTypeId,
            handleDeleteSegment,
            handleDeleteSegmentOr,
            handleUpdateSegmentOr,
            handleCreateSegmentAnd,
            handleCreateSegmentOr,
        },
    };
};
