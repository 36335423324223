import React from 'react';
import { Button, Card, CardBody, Col, Container, Image, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AccreditationCard from '../AccreditationCard';
import AccreditationTypeSelect from '../Form/AccreditationTypeSelect/AccreditationTypeSelect';
import CommunicationPreferences from '../MemberCommunicationPreferences';
import MemberDocumentsSection from '../MemberDocumentsSection';
import MemberSectionContainer from '../MemberSectionContainer';
import MembershipCard from '../MembershipCard';
import MemberContactSection from './MemberContactSection';
import MemberDetailsSection from './MemberDetailsSection';
import MemberEventsSection from './MemberEventsSection';
import MemberNotesSection from './MemberNotesSection';
import { useMember } from './useMember';
import UsernameBadge from './UsernameBadge';

const MemberDetail: React.FC = () => {
    const { memberId } = useParams();
    const { models, operations } = useMember({ memberId });
    const { member, accreditationTypeId, inProgress } = models;
    const { handleReloadMember, handleCreateAccreditation, setAccreditationTypeId } = operations;

    const navigate = useNavigate();

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody style={{ textAlign: 'left' }}>
                <Row className=" ">
                    <Col xs="auto">
                        <Button variant="outline-primary" onClick={() => navigate('/members')}>
                            <i className="bi bi-chevron-left"></i>
                        </Button>
                    </Col>

                    {member && (
                        <>
                            {member.avatar && (
                                <Col xs={'auto'}>
                                    <Image
                                        width={40}
                                        height={40}
                                        src={member.avatar.data ?? ''}
                                        roundedCircle
                                        style={{ objectFit: 'cover' }}
                                    />
                                </Col>
                            )}
                            <Col>
                                <h3 className="">
                                    {member.firstname} {member.lastname}
                                </h3>
                                <UsernameBadge userId={member.userId} />
                            </Col>
                            <Col
                                xs="auto"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    gap: '2em',
                                }}
                            >
                                <p>
                                    <CommunicationPreferences memberId={member.memberId} />
                                </p>
                                <p>
                                    <Button
                                        variant="outline-primary"
                                        as={'a'}
                                        href={'mailto:' + member.email}
                                        title={'Send an email to ' + member.email}
                                    >
                                        <i className="bi bi-envelope"></i>
                                    </Button>
                                    {member.telephones &&
                                        member.telephones.map((telephone) => {
                                            return (
                                                <Button
                                                    variant="outline-primary"
                                                    className="ms-2"
                                                    as={'a'}
                                                    href={'tel:' + telephone.number}
                                                    title={'Call ' + telephone.number}
                                                >
                                                    <i className="bi bi-telephone"></i>
                                                </Button>
                                            );
                                        })}
                                </p>
                            </Col>
                        </>
                    )}
                </Row>

                {member && (
                    <Container>
                        <Row className="mt-4 g-4">
                            <Col lg={4}>
                                <MemberContactSection member={member} />
                                <MemberDetailsSection member={member} />
                                <MemberNotesSection member={member} onUpdate={handleReloadMember} />
                            </Col>
                            <Col lg={8}>
                                <Row>
                                    <Col>
                                        <MemberSectionContainer title="Membership">
                                            {inProgress ? (
                                                <Spinner />
                                            ) : (
                                                <Row>
                                                    {member.memberships &&
                                                    member.memberships.length > 0 ? (
                                                        member.memberships.map(
                                                            (membership, index) => (
                                                                <Col
                                                                    lg={12}
                                                                    className="mb-2"
                                                                    key={membership?.membershipId}
                                                                >
                                                                    <MembershipCard
                                                                        membershipId={
                                                                            membership?.membershipId
                                                                        }
                                                                    />
                                                                </Col>
                                                            )
                                                        )
                                                    ) : (
                                                        <Col lg={12}>
                                                            <p>No Membership.</p>
                                                        </Col>
                                                    )}
                                                </Row>
                                            )}
                                        </MemberSectionContainer>
                                    </Col>
                                    <Col>
                                        <MemberSectionContainer title="Accreditation">
                                            {inProgress ? (
                                                <Spinner />
                                            ) : (
                                                <Row>
                                                    {member.accreditations &&
                                                    member.accreditations.length > 0 ? (
                                                        member.accreditations.map(
                                                            (accreditation, index) => (
                                                                <Col
                                                                    lg={12}
                                                                    className="mb-2"
                                                                    key={
                                                                        accreditation?.accreditationId
                                                                    }
                                                                >
                                                                    <AccreditationCard
                                                                        accreditationId={
                                                                            accreditation?.accreditationId
                                                                        }
                                                                    />
                                                                </Col>
                                                            )
                                                        )
                                                    ) : (
                                                        <Col lg={12}>
                                                            <p>
                                                                This member currently has no
                                                                accreditation history.
                                                            </p>
                                                        </Col>
                                                    )}

                                                    <Col lg={12}>
                                                        <Card
                                                            style={{
                                                                border: 'none',
                                                                background: 'transparent',
                                                            }}
                                                        >
                                                            <Card.Body>
                                                                <Row>
                                                                    <AccreditationTypeSelect
                                                                        name="accreditationType"
                                                                        value={
                                                                            accreditationTypeId ??
                                                                            ''
                                                                        }
                                                                        onChange={(e) => {
                                                                            setAccreditationTypeId(
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Col
                                                                        xs={'auto'}
                                                                        className="p-0 mt-2"
                                                                    >
                                                                        <Button
                                                                            size="sm"
                                                                            variant="outline-primary"
                                                                            disabled={
                                                                                !accreditationTypeId
                                                                            }
                                                                            onClick={
                                                                                handleCreateAccreditation
                                                                            }
                                                                        >
                                                                            <i className="bi bi-plus me-1"></i>
                                                                            Add Accreditation
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}
                                        </MemberSectionContainer>
                                    </Col>
                                </Row>

                                <MemberDocumentsSection
                                    member={member}
                                    onUpdate={handleReloadMember}
                                />

                                <MemberEventsSection
                                    member={member}
                                    onUpdate={handleReloadMember}
                                />
                            </Col>
                        </Row>
                    </Container>
                )}
            </CardBody>
        </Card>
    );
};

export default MemberDetail;
